import React, { useState, useRef, useEffect, useContext } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import socket from "../../../utils/socket";
import { GlobalContext } from '../../../context/GlobalContext'
import { Header } from "../../../components/Shared/Header";
import { DataGridSimple } from "../../../components/Shared/DataGridSimple";
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { Button } from "../../../components/Shared/Button";
import { TbListDetails } from 'react-icons/tb';
import { FaCloudDownloadAlt } from "react-icons/fa";

import "./Billings.css";
import { DownloadInvoice } from './DownloadInvoice';
import { Popup } from '../../../components/Shared/Popup';
const MySwal = withReactContent(Swal);



function Invoices() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
  const [error, setError] = useState(false);
  const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
  const [selectedTask, setSelectedTask] = useState(null);
  const [isPopupRateOpen, setIsPopupRateOpen] = useState(false);
  const history = useHistory();
  const [rows2, setRows2] = useState();
  const url = `${API_BASE_URL}/payments/ByUser/${emailGlobal}`;

  const consumeFetch = async (url, options) => {
    try {
      const originalFetch = fetch;
      const fetchWithMiddleware = ApiMiddleware(originalFetch);
      const { response, token } = await fetchWithMiddleware(url, options);
      // Se detecta token nuevo
      if (token) {
        setToken(token)
      }
      return await response;
    } catch (error) {
      if (error.message === "RefreshToken Vencido") {
        console.log(error.message, error)
        setToken("");
        setEmailGlobal("");
        setIdUserGlobal("");
        setUserName("");
        history.push('/');
      }
    }

  }

  const fetchData = async () => {
    consumeFetch(url, {
      headers: {
        Authorization: `${token}`
      }
    }
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        const newRows = data.payments.map((element, index) => {
          var dateLimit = element.created_at ? new Date(element.created_at) : new Date();
          var userTimezoneOffset = dateLimit.getTimezoneOffset() * 60000;
          dateLimit = new Date(dateLimit.getTime() + userTimezoneOffset);
          const month = dateLimit.getMonth() + 1;
          let obj = {
            id: index,
            amount: element.amount,
            stripeId: element.stripeId,
            created_at: `${month.toString().padStart(2, '0')}-${dateLimit.getDate().toString().padStart(2, '0')}-${dateLimit.getFullYear()}`,
            status: element.status.status_name
          };
          return obj;
        });
        setRows2(newRows);
      })
      .catch(error => {
        console.error(error)
        setError(true);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);


  const renderDownloadButton = (params) => {
    return (
      <Button
        buttonClassName="create_Chat_Button"
        onClick={() => {
          openPopupRate(params.row);
        }}
        icon={<FaCloudDownloadAlt />}
      >
      </Button>

    )
  }

  function openPopupRate(task_id) {
    setSelectedTask(task_id);
    setIsPopupRateOpen(true);
    setActiveBlur(true);
    console.log(selectedTask)

  }

  function closePopupRate() {
    setIsPopupRateOpen(false);
    setActiveBlur(false);

  }

  const columns = [
    { field: 'stripeId', headerName: 'Invoice ID', alignItems: 'center', width: 150 },
    { field: 'created_at', headerName: 'Date', alignItems: 'center', width: 150 },
    { field: 'amount', headerName: 'Amount', alignItems: 'center', width: 130 },
    { field: 'status', headerName: 'Payment Status', alignItems: 'center', width: 130 },
    { field: 'download', headerName: 'Download', alignItems: 'center', width: 150, renderCell: renderDownloadButton, show: true },
  ];

  return (
    <React.Fragment>
      <div className="billing-table">
        {rows2 && <DataGridSimple pRows={rows2} pColumns={columns} />}
      </div>
      <Popup isOpen={isPopupRateOpen} onClose={closePopupRate} modalClass="popup-info-task">
        {selectedTask && (
          <DownloadInvoice selectedTask={selectedTask} closePopup={closePopupRate} />
        )}
      </Popup>
    </React.Fragment>
  );
}

export { Invoices };
