import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { MessageList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import './message_list_window.css';
import { Button } from '../../../Shared/Button';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { IoMdSend } from 'react-icons/io';
import { IoMdAttach } from 'react-icons/io';
import OutlinedInput from '@mui/material/OutlinedInput';
import socket from "../../../../utils/socket.js";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import ApiMiddleware from "../../../Shared/ApiMiddleware";
import FileInputButton from '../../../Shared/FileInputButton';
import noMessagesImg from '../../../../assets/images/no-messages.png'
import { Tune } from '@mui/icons-material';
const MySwal = withReactContent(Swal);

function MessageListWindow({ taskId, receiverId, chatState, user1Id, user2Id, chatKind }) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const [error, setError] = useState(false);
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, userName, setUserName, setShowBadge } = useContext(GlobalContext)
    const history = useHistory();
    const [dataMessages, setDataMessages] = useState();
    const [messageExists, setMessageExists] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const url = chatKind == 'Client' ? `${API_BASE_URL}/messages/messagesByTask/${taskId}` :
        chatKind == 'Admin' ? `${API_BASE_URL}/admin_chat/messagesByAdminChat?user_id=${user2Id}` : `${API_BASE_URL}/support_chat/messagesBySupportChat?user1_id=${user1Id}&user2_id=${user2Id}`;
    const bottomRef = useRef(null);


    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }


    const fetchData = async () => {
        consumeFetch(url, {
            headers: {
                Authorization: `${token}`
            }
        }
        )
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.messages.length != 0) {
                    const newRows = data.messages.map((element, index) => {
                        let obj = null;
                        if (element.url_attached) {
                            obj = {
                                position: element.from === idUserGlobal ? "right" : "left",
                                type: "file",
                                title: element.from_username,
                                text: element.text,
                                data: {
                                    uri: element.url_attached,
                                    status: {
                                        click: false,
                                        loading: 0,
                                    },
                                }
                            }
                        } else {
                            obj = {
                                position: element.from === idUserGlobal ? "right" : "left",
                                type: "text",
                                title: element.from_username,
                                text: element.text,
                            };
                        }
                        return obj;
                    });
                    setTimeout(() => { bottomRef.current?.scrollIntoView({ behavior: 'smooth' }); }, 100)
                    setDataMessages(newRows);
                    setMessageExists(true);

                } else {
                    setDataMessages(null);
                    setMessageExists(false);

                }
            })
            .catch(error => {
                console.error(error)
                setError(true);
            });
    }

    useEffect(() => {

        if (taskId != undefined || chatKind != 'Client') {
            fetchData();
        }

        socket.on("private message", ({ content, from, to }) => {
            fetchData();
        });

        socket.on("Admin Message list", ({ content, from, to }) => {
            fetchData();
        });

        socket.on("Support Message list", ({ content, from, to }) => {
            fetchData();
        });

        return () => {
            socket.off("private message");
        }
    }, [taskId, user1Id]);

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const downloadFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Assisttu.postman_collection (1)';
        link.click();
    }

    const handleSubmitMessage = (event) => {
            event.preventDefault();
            let data = chatKind == 'Client' ? {
                text: inputValue,
                sender: idUserGlobal,
                receiver: receiverId,
                task_id: taskId
            } :
                {
                    text: inputValue,
                    sender: idUserGlobal,
                    receiver: idUserGlobal == user1Id ? user2Id : user1Id,
                };

            let url_put = chatKind == 'Client' ? `${API_BASE_URL}/messages/` : chatKind == 'Admin' ? `${API_BASE_URL}/admin_chat/` : `${API_BASE_URL}/support_chat/`;
            consumeFetch(url_put, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify(data)
            }).then((res) => {
                if (res.ok) {
                    fetchData();
                } else {
                    console.log("No fue posible registrar el mensaje");
                }
                setInputValue("");
            }).catch((err) => {
                console.log(err);
            });
    };

    return (
        <React.Fragment>

            <div className="ContainerChatWindow">

                <div className="messageListSupportWindow">
                    {messageExists ?
                        <>
                            <MessageList
                                className='message-list'
                                lockable={true}
                                toBottomHeight={'100%'}
                                dataSource={dataMessages}
                                onClick={(event, message) => {
                                    console.log(event)
                                    // Handle click events on messages here
                                    if (event.type === 'file') {
                                        const url = event.data.uri;
                                        window.open(url)
                                    }
                                }}
                            />
                            <div ref={bottomRef} />
                        </>
                        :
                        <img src={noMessagesImg} width='100%'></img>
                    }
                </div>
                <div className="inputChat">
                    {chatState === "open" || chatKind != 'Client' ?
                        <form className="formChat" onSubmit={handleSubmitMessage}>
                            <OutlinedInput className="inputChat" type="text" placeholder="Type your message" value={inputValue} onChange={handleChange} />
                            <Button buttonClassName="Message_Button" type="submit" icon={<IoMdSend />}  inactive={inputValue == '' ? true : false}/>
                            <FileInputButton buttonClassName="Message_Label" icon={<IoMdAttach />}
                                token={token} consumeFetch={consumeFetch} fetchData={fetchData} task_id={taskId}
                                sender={idUserGlobal} receiver={receiverId} chatKind={chatKind} user1Id={user1Id} user2Id={user2Id}>

                            </FileInputButton>

                        </form>
                        :
                        <p className="closed_Message">The Task is closed. You can´t send messages</p>
                    }
                </div>
            </div>

        </React.Fragment>
    );
}

export { MessageListWindow };