import * as muiMaterial from '@mui/material';
import * as muiDataGrid from '@mui/x-data-grid';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useContext, useRef, useState, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { Delete, Create, AddCircle, Repeat, Margin } from '@mui/icons-material';
import { Button } from "../../components/Shared/Button";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import socket from '../../utils/socket';
import { AiOutlinePlusSquare } from 'react-icons/ai';

import { Counter } from "../../components/Shared/Counter";
import { CustomizedSnackbar } from './Snackbar';
import { ModalDialog } from './ModalForm';
import ApiMiddleware from './ApiMiddleware';
import dayjs from 'dayjs';
import './DataGrid.css';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const pageSizeOptions = [5, 10, 20];
const MySwal = withReactContent(Swal);

function DataGrid(props) {
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const { pRows, pColumns, pTittle, setActiveRowCount, decrementActiveRowCount, dateVencPlan, openPopupRepeat, handleClickInProgressFilter, inProgressRowCount, handleClickUnassignedFilter, activeRowCount, handleClickDoneFilter, doneRowCount } = props;
    const location = useLocation();
    const product = location.state?.product || {};
    const edit = location.state?.edit || {};
    let dateFromCalendar = location.state?.date || undefined; // Accessing the state

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const SnackbarRef = useRef();
    const ModalDialogRef = useRef();
    const [Rows, setRows] = useState(pRows);
    const [Columns] = useState(pColumns);
    const [selectedId, setSelectedId] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [allValuesVault, setAllValuesVault] = useState([]);
    const [allValuesRecurrences, setAllValuesRecurrences] = useState([]);
    const [openEditFromHome, setOpenEditFromHome] = useState(false);


    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect(() => {
        async function getAllValuesVault() {
            const dataReturnSecrets = await fetchData("GET", `${API_BASE_URL}/vault/getSecretsByUser/${emailGlobal}`, null);
            setAllValuesVault(dataReturnSecrets.data.map(item => { return { "id": item?.id, "name_secret": item.name_secret } }));
        }

        getAllValuesVault();

        async function getAllValuesRecurrences() {
            const dataReturnRec = await fetchData("GET", `${API_BASE_URL}/recurrences/`, null);
            setAllValuesRecurrences(dataReturnRec.data.map(item => ({
                description: item.description,
                key: item.key
            }
            )));
        }
        getAllValuesRecurrences();

    }, []);

    useEffect(() => {
        if (product === true && allValuesRecurrences && allValuesVault) {
            createButtonClick();
        }

    }, [allValuesRecurrences, allValuesVault]);

    useEffect(() => {
        console.log(openEditFromHome)

        if (Object.keys(edit).length != 0 && allValuesRecurrences.length != 0 && allValuesVault.length != 0 && Rows.length > 0 && openEditFromHome === false) {

            setTimeout(() => {
                console.log(edit)
                handleRowSelection([edit]);
            }, 1000);
            setTimeout(() => {
                editButtonClick();
            }, 1200);
        }

    }, [allValuesRecurrences, allValuesVault, Rows, selectedId]);



    const handleRowSelection = (selection) => {
        console.log(selection)

        if (selection.length > 1) {
            const selectionSet = new Set(selectedId);
            const result = selection.filter((s) => !selectionSet.has(s));
            setSelectedId(result);
            getModelObject(result)
        } else {
            console.log(selection)

            setSelectedId(selection);
            getModelObject(selection);
            console.log(selectedId)
        }

    }

    useEffect(() => {
        // Action to perform after the state has been updated
        const sortingArr = ["In Progress", "Unassigned", "Done"];
        const sortArray = pRows.sort((a, b) => {
            // First, compare by taskTypeName
            const sortByTaskType = sortingArr.indexOf(a.statusName) - sortingArr.indexOf(b.statusName);
        
            // If taskTypeName is the same, compare by another property (e.g., taskId)
            if (sortByTaskType === 0) {
                return a.dateLimit - b.dateLimit; // Change this to the second property you want to sort by
            }
        
            return sortByTaskType;
        });
        setRows(sortArray); // Updated rows
    }, [pRows]);

    const getModelObject = (pId) => {
        setSelectedModel(Rows.filter(x => x?.id === pId[0])[0]);
    }

    const openModal = (pAction) => {
        ModalDialogRef.current.setTitle(pAction + pTittle);
        ModalDialogRef.current.handleOpen();
    };

    const createButtonClick = async () => {
        // const dataReturned = await fetchData("GET", `${API_BASE_URL}/task_type/`, null);
        // const valuesTaskType = dataReturned.data.map(item => item.taskTypeName)

        const dataReturnedPlans = await fetchData("GET", `${API_BASE_URL}/plans/plansByUser/${idUserGlobal}`, null);
        let maxValueHoursDailyLimit = 0;
        let maxValueTasksDailyLimit = 0;
        let valuesPlans = dataReturnedPlans.map((item, index) => {
            if(item.plan.planType === "Virtual Assistant"){
                if(item.plan.planName.indexOf("Task Package") !== -1){
                    if(item.plan.dailyHourLimit > maxValueTasksDailyLimit) maxValueTasksDailyLimit = item.plan.dailyHourLimit;
                    return "per task";
                }else{
                    maxValueHoursDailyLimit = maxValueHoursDailyLimit + item.plan.dailyHourLimit;
                    return "per hours";
                }
            }
        });
        for (var i = valuesPlans.length - 1; i >= 0; i--) {
            if (valuesPlans.indexOf(valuesPlans[i]) !== i) valuesPlans.splice(i, 1);
        }
        let valuesPlansToSend = valuesPlans.map(item => {
            if(item === "per hours") return {name: item, limit: maxValueHoursDailyLimit}
            if(item === "per task") return {name: item, limit: maxValueTasksDailyLimit}
        })

        const modifiedColumns = Columns.map(column => {
            if (column.field === 'id' || column.field === 'assistantEmail' || column.field === 'statusName' || column.field === 'task_id' || column.field === 'calification' || column.field === 'task_id_detail') {
                // Omitir la propiedad 'description'
                return null;
            }
            // if (column.field === 'taskTypeName') {
            //     column.values = valuesTaskType;
            //     return column;
            // }
            if (column.field === 'recurrence') {
                column.values = allValuesRecurrences;
                return column;
            }
            if (column.field === 'type_creation') {
                column.values = valuesPlansToSend;
                return column;
            }
            if (column.field === 'valuesVault') {
                column.values = allValuesVault.map(item => item.name_secret);
                return column;
            }
            // Conservar las demás propiedades
            return { ...column };
        }).filter(Boolean); // Filtrar los elementos nulos
        const initialModel = modifiedColumns.map(column => {
            if (column.field === 'dateLimit') {
                if (dateFromCalendar) {
                    let date = new Date(dateFromCalendar);
                    dateFromCalendar = undefined;
                    return { ...column, value: dayjs(date) };
                }
            }
            // if (column.field === 'taskTypeName' || column.field === 'valuesVault') {
            if (column.field === 'valuesVault' || column.field === 'recurrence' || column.field === 'type_creation') {
                // Incluir el campo 'values' en el modelo
                return { ...column, value: '', values: column.values };
            }
            if (column.field === 'saveTemplate') return { ...column, value: false }
            // Conservar las demás propiedades
            return { ...column, value: '' };
        });
        ModalDialogRef.current.setModel(initialModel);
        ModalDialogRef.current.setTypeAction('create');
        openModal("Create ");
    };

    const editButtonClick = () => {
        console.log(selectedId)
        if (notSelectedRow()) return;
        if (inDoneTask()) return;
        setOpenEditFromHome(true);

        const modifiedColumns = Columns.map(column => {
            // if (column.field === 'assistantEmail' || column.field === 'statusName' || column.field === 'taskTypeName' || column.field === 'task_id' || column.field === 'calification' || column.field === 'task_id_detail') {
            if (column.field === 'assistantEmail' || column.field === 'statusName' || column.field === 'task_id' || column.field === 'calification' || column.field === 'task_id_detail' || column.field === 'saveTemplate') {
                // Omitir la propiedad
                return null;
            }
            if (column.field === 'recurrence') {
                column.values = allValuesRecurrences;
                return column;
            }
            if (column.field === 'valuesVault') {
                column.values = allValuesVault.map(item => item.name_secret);
                return column;
            }
            // Conservar las demás propiedades
            return { ...column };
        }).filter(Boolean); // Filtrar los elementos nulos


        SnackbarRef.current.handleSnackbarOpen('Edit Row', 'info');

        ModalDialogRef.current.setTypeAction('edit');

        ModalDialogRef.current.setModel(modifiedColumns.map(column => {
            if (column.field === 'dateLimit') {
                let date = new Date(selectedModel[column.field]);
                return { ...column, value: dayjs(date) };
            }
            if (column.field === 'hours') {
                let values = selectedModel[column.field]?.split(":");
                return { ...column, value: Number(values[0]) };
            }
            if (column.field === 'recurrence') {
                let rec = selectedModel.recurrent_Id ? selectedModel.recurrent_Id.description : null;
                return { ...column, value: rec };
            }

            return { ...column, value: selectedModel[column.field] };
        }));

        let valuesSecrets = [];
        if (selectedModel["valuesVault"].length !== 0) {
            valuesSecrets = Array.isArray(selectedModel["valuesVault"]) ? selectedModel["valuesVault"] : selectedModel["valuesVault"]?.split(',');
        }
        ModalDialogRef.current.setSelectSecrets(valuesSecrets);

        openModal("Edit ");
    };

    const deleteButtonClick = async () => {
        if (notSelectedRow()) return;
        if (inProgressTask()) return;
        if (inDoneTask()) return;
        setActiveBlur(true);
        /* eslint-disable no-restricted-globals */
        MySwal.fire({
            title: 'Are you sure you want to delete this Task?',
            width: 430,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes, Delete.',
            denyButtonText: `Cancel`,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const jsonObject = JSON.parse(JSON.stringify(selectedModel));
                const dataReturned = await fetchData("DELETE", `${API_BASE_URL}/tasks/${jsonObject.id}`, null);
                if (!dataReturned) {
                    SnackbarRef.current.handleSnackbarOpen('Delete task was not possible, please contact technical support.', 'error');
                    return;
                }
                let tempRows = [...Rows];
                tempRows.splice(tempRows.indexOf(selectedModel), 1);
                setRows(tempRows);
                decrementActiveRowCount();
                /* eslint-enable no-restricted-globals */
                SnackbarRef.current.handleSnackbarOpen('Task Deleted', 'success');
            } else if (result.isDenied) {
                return
            }
        }).finally(() => { setActiveBlur(false) });

        // if (!confirm("Are you sure you want to delete this item?")) return;

        // SnackbarRef.current.handleSnackbarOpen('Delete Row', 'error');

    };

    const fetchData = async (type, endpoint, body) => {
        let jsonObject;
        if (body) {
            jsonObject = JSON.parse(JSON.stringify(body));
        }
        if (type === "POST") {
            jsonObject.email = emailGlobal
            // jsonObject.task_type = jsonObject.taskTypeName;
            jsonObject.task_title = jsonObject.taskTitle;
        }
        if (type === "PUT") {
            jsonObject.email = emailGlobal
            jsonObject.task_title = jsonObject.taskTitle
        }
        try {
            const response = await consumeFetch(endpoint, {
                method: type,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                ...(jsonObject && { body: JSON.stringify(jsonObject) }) // Incluir el cuerpo solo si jsonObject tiene un valor asignado
            });
            if (response.ok) {
                const data = await response.json();
                return data; // Devolver el objeto retornado del servicio REST
            } else {
                const data = await response.json();
                if (data.message === "Hour Limit exceed the limit of the user plan.") {
                    setActiveBlur(true);
                    MySwal.fire({
                        title: `Ups, insufficient hours !! `,
                        text: 'Hour Limit exceed the limit of the user plan.',
                        width: 430,
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'Want to buy more ?',
                        backdrop: true,
                        customClass: {
                            popup: 'popup-sweet',
                            title: 'title-sweet title-exceed-time',
                            htmlContainer: 'text-sweet',
                            confirmButton: 'confirm-button-sweet',
                            denyButton: 'deny-button-sweet',
                        }
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            history.push('/user/Plans');
                        }
                    }).finally(() => { setActiveBlur(false) });
                } else if (data.message === "User does not have a plan") {
                    setActiveBlur(true);
                    MySwal.fire({
                        title: `Ups, You have no active plan !! `,
                        text: "User does not have a plan, so we can't create a task",
                        width: 430,
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'Want to buy one ?',
                        backdrop: true,
                        customClass: {
                            popup: 'popup-sweet',
                            title: 'title-sweet title-exceed-time',
                            htmlContainer: 'text-sweet',
                            confirmButton: 'confirm-button-sweet',
                            denyButton: 'deny-button-sweet',
                        }
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            history.push('/user/Plans');
                        }
                    }).finally(() => { setActiveBlur(false) });
                } else {
                    SnackbarRef.current.handleSnackbarOpen(data.message, 'error');
                }
                return null;
            }
        } catch (error) {
            throw new Error('Error en la solicitud');
        }
    };

    const handleAddItem = async (pModel) => {
        if (pModel.taskTitle.trim() === '') {
            return
        }
        if (pModel.description.trim() === '') {
            return
        }
        pModel.saveTemplate = (pModel.saveTemplate === "true");
        pModel.hours = `${Math.round(pModel.hours)}:00`;

        const desiredWords = pModel.valuesVault && pModel.valuesVault?.split(",");

        pModel.valuesVault = desiredWords && allValuesVault.filter(item => desiredWords.includes(item.name_secret));
        let dataReturned;
        try {
            if (pModel.recurrence && pModel.recurrence != undefined && pModel.recurrence != null) {
                dataReturned = await fetchData("POST", `${API_BASE_URL}/tasks/recurrenTask`, pModel);
            } else {
                dataReturned = await fetchData("POST", `${API_BASE_URL}/tasks/`, pModel);
            }
            if (dataReturned !== null) {
                const mappedModel = {
                    valuesVault: pModel.valuesVault !== "" ? pModel.valuesVault : [],
                    assistantEmail: "Unassigned",
                    // taskTypeName: dataReturned.taskType_Id ? dataReturned.taskType_Id.task_type_name : "",
                    statusName: dataReturned.status_Id.status_name,
                    task_id: dataReturned?.id,
                    dateLimit: dataReturned.date_limit,
                    ...dataReturned
                };

                SnackbarRef.current.handleSnackbarOpen('Item saved successfully', 'success');

                let tempRows = [...Rows];
                tempRows.push(mappedModel);
                setRows(tempRows);
                setActiveRowCount();
            }
        } catch (error) {
            console.error(error);
        }

    };

    const handleUpdateItem = async (pModel) => {
        if (pModel.taskTitle.trim() === '') {
            return
        }
        if (pModel.description.trim() === '') {
            return
        }
        pModel.hours = `${pModel.hours}:00`;

        const valuesVault = pModel.valuesVault;
        const valuesArray = valuesVault?.split(",");
        const convertedArray = valuesVault.length > 0 ? valuesArray.map((value) => ({ name_secret: value })) : [];
        pModel.valuesVault = convertedArray;

        const dataReturned = await fetchData("PUT", `${API_BASE_URL}/tasks/updateTask/${pModel?.id}`, pModel);
        if (dataReturned !== null) {

            const mappedModel = {
                valuesVault: dataReturned.data.taskSecrets.length > 0 ? dataReturned.data.taskSecrets.map(function (taskSecret) {
                    var secret = taskSecret.secret;
                    if (secret && secret.name_secret) {
                        return secret.name_secret;
                    }
                    return null;
                }).join(",") : "",

                assistantEmail: dataReturned.data.assistantId ? dataReturned.data.assistantId.email : "Unassigned",
                // taskTypeName: dataReturned.data.taskType_Id ? dataReturned.data.taskType_Id.task_type_name : "",
                statusName: dataReturned.data.status_Id.status_name,
                task_id: dataReturned.data?.id,
                dateLimit: dataReturned.data.date_limit,
                ...dataReturned.data
            };
            SnackbarRef.current.handleSnackbarOpen('Item saved successfully', 'success');
            let tempRows = [...Rows];
            tempRows[tempRows.indexOf(selectedModel)] = mappedModel;
            setSelectedModel(mappedModel);
            setRows(tempRows);
        }
    };

    const notSelectedRow = () => {
        console.log(selectedId)
        if (selectedId?.length === 0 || selectedId === undefined) {
            SnackbarRef.current.handleSnackbarOpen('Error: You must select a record to continue.', 'error');
            return true;
        }
        return false;
    }

    const inProgressTask = () => {

        let isValid = true;
        selectedId.forEach((el) => {
            const status = Rows.filter(x => x?.id === el)[0]?.statusName;
            if (status == "In Progress") {
                isValid = false;
            }
        });

        if (isValid == false) {
            SnackbarRef.current.handleSnackbarOpen('Error: Task is already in progress', 'error');
            return true;
        }
        return false;
    }

    const inDoneTask = () => {
        let isValid = true;
        selectedId.forEach((el) => {
            const status = Rows.filter(x => x?.id === el)[0]?.statusName;
            if (status == "Done") {
                isValid = false;
            }
        });

        if (isValid == false) {
            SnackbarRef.current.handleSnackbarOpen('Error: Task is already done', 'error');
            return true;
        }
        return false;
    }

    return (
        <muiMaterial.Box
            sx={{
                width: '100%',
                height: '10',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 2,
            }} >

            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className='head-counter'>
                <Link
                    to={{
                        pathname: '/user/Tasks',
                        state: {
                            product: true,
                        },
                    }}
                >
                    <div className='new-task-box' onClick={createButtonClick}>
                        <div className="new-task-content" >
                            <span>Add Task</span>
                        </div>
                        <div className="new-task-icon">
                            <AiOutlinePlusSquare />
                        </div>
                    </div>
                </Link>
                <div className="counter-container-home counter-active" onClick={handleClickInProgressFilter}>
                    <Counter count={inProgressRowCount} counterClassName='counter-home' />
                    <p className="text-counter-home">In Progress</p>
                </div>

                <div className="counter-container-home counter-in-progress" onClick={handleClickUnassignedFilter}>
                    <Counter count={activeRowCount} counterClassName='counter-home' />
                    <p className="text-counter-home">Unassigned </p>
                </div>

                <div className="counter-container-home counter-done" onClick={handleClickDoneFilter}>
                    <Counter count={doneRowCount} counterClassName='counter-home' />
                    <p className="text-counter-home">Done</p>
                </div>
            </div>
            <div className="text-counter">
                <p className="helper-counter">You can filter the results by clicking the different options above.</p>
            </div>
            <div className="task-option-buttons">
                <Button buttonClassName="button-task-view new-task" icon={<Repeat />} childrenClassName="" onClick={openPopupRepeat} children="Repeat task" />
                <div className="right-task-option-buttons">
                    <Button buttonClassName="button-task-view new-task" icon={<Delete />} childrenClassName="" onClick={deleteButtonClick} children="Remove" />
                    <Button buttonClassName="button-task-view new-task" icon={<Create />} childrenClassName="" onClick={editButtonClick} children="Edit" />
                </div>
            </div>

            <ModalDialog
                open={ModalDialogRef.open}
                handleClose={ModalDialogRef.handleClose}
                ref={ModalDialogRef}
                onAddItem={handleAddItem}
                onUpdateItem={handleUpdateItem}
                dateVencPlan={dateVencPlan}
            />
            <div className='grid'>
                <muiDataGrid.DataGrid
                    rows={Rows}
                    disableColumnMenu
                    columns={Columns.map((column, index) => ({
                        ...column,
                        align: index === column.length - 1 ? 'center' : 'right',
                        headerAlign: 'center',
                    })).filter((column) => column.show == true)}
                    pageSize={10} rowsPerPageOptions={pageSizeOptions}
                    sx={{
                        background: 'white',
                        overflowX: 'auto',
                    }}

                    autoHeight
                    autoWidth
                    checkboxSelection
                    selectionModel={selectedId}
                    hideFooterSelectedRowCount
                    onSelectionModelChange={(selection) => { handleRowSelection(selection) }}
                    initialState={{ pinnedColumns: { right: ['actions'] } }}
                />
            </div>

        </muiMaterial.Box>
    );
}


export { DataGrid };
