import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { GlobalContext } from '../../../context/GlobalContext';
import { ContentHeader } from '../../../components/Shared/ContentHeader'
import { TableTasksToApprove } from './TableTasksToApprove';
import { SearchComponent } from '../../../components/Shared/SearchComponent';
import './tasks.css';

function ViewTasksToApprove() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const history = useHistory();
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName } = useContext(GlobalContext)

    const [tasks, setTasks] = useState([])
    const [search, setSearch] = useState("")

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)

                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                setActivePage("Home");
                history.push('/');
            }
        }
    }

    const URL = `${API_BASE_URL}/tasks/tasksToApproveByAdmin/${idUserGlobal}`;

    const showDataTasks = async () => {
        const response = await consumeFetch(URL, {
            headers: {
                Authorization: `${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }
        const data = await response.json()
        setTasks(data.data)
    }

    const searcher = (e) => {
        setSearch(e.target.value)   
    }

    const results = !search ? tasks : tasks.filter((dato)=> dato.taskTitle.toLowerCase().includes(search.toLocaleLowerCase()))


    useEffect(() => {
        showDataTasks()
    }, [])

    return <div className="data-tasks-container">
        <ContentHeader text=" Tasks To Approve" />
        <SearchComponent search={search} searcher={searcher}/>
        <TableTasksToApprove data={results} fetchData={showDataTasks} />
    </div>
}

export { ViewTasksToApprove }