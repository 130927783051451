import React, { useContext } from "react";
import { Button } from "../../../components/Shared/Button";
import "./ContractCard.css";
import { AiFillCheckCircle } from "react-icons/ai";
import { GlobalContext } from "../../../context/GlobalContext";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function ContractCardHours(props) {
    const { planesUsuario } = useContext(GlobalContext);
    const { cardTitle, subdescription, featuresList, handleSubmit, bgColor, description, boldDescription, subDescription } = props;
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [isChecked, setIsChecked] = React.useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    
    return (
        <React.Fragment>
            <div className="full-card-pricing" style={{ background: bgColor }}>
                <div className="full-price-info">
                    <div className="plan-header-container">
                        <span className="card-contract-title">{cardTitle}</span>
                        <div className="feature-text">{description}</div>
                    </div>
                    <div className="features-list-container">
                        <div className="features-list">
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Administrative.</div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Marketing.</div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Sales.</div>
                            </div>
                        </div>
                        <div className="features-list">
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Customer Support.</div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Finance Work.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="feature-text" >{subDescription}</div>
                <div className="feature-text">
                    <label>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        I agree to the {cardTitle} <a href="https://assisttu.com/"><u>Terms and Conditions</u></a>.
                    </label>
                </div>
                <div className="button-subscribe-container" style={{marginLeft: '15%'}}>
                    <div className="package-row">
                        <div className="price-subdescription-package">
                            <Button
                                type="submit"
                                onClick={event => handleSubmit(event, age*16, "Hours Package", "Virtual Assistant", age)}
                                buttonClassName="button-subscribe-hour"
                                children={`$${age * 16}`}
                                inactive={!(isChecked && age>0 && planesUsuario.length>0)}
                            />
                            
                            <div className="select-plan-hours">
                                <span className="text-select-plan-hours">How many hours do you want to buy?</span>
                                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                    <InputLabel id="demo-simple-select-label">Choose hours amount</InputLabel>

                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="Choose hours amount"
                                        defaultValue={1}
                                        onChange={handleChange}
                                        displayEmpty
                                    >
                                        <MenuItem value={1}>1 hour</MenuItem>
                                        <MenuItem value={2}>2 hours</MenuItem>
                                        <MenuItem value={3}>3 hours</MenuItem>
                                        <MenuItem value={4}>4 hours</MenuItem>
                                        <MenuItem value={5}>5 hours</MenuItem>
                                        <MenuItem value={6}>6 hours</MenuItem>
                                        <MenuItem value={7}>7 hours</MenuItem>
                                        <MenuItem value={8}>8 hours</MenuItem>
                                        <MenuItem value={9}>9 hours</MenuItem>
                                        <MenuItem value={10}>10 hours</MenuItem>
                                        <MenuItem value={11}>11 hours</MenuItem>
                                        <MenuItem value={12}>12 hours</MenuItem>
                                        <MenuItem value={13}>13 hours</MenuItem>
                                        <MenuItem value={14}>14 hours</MenuItem>
                                        <MenuItem value={15}>15 hours</MenuItem>
                                        <MenuItem value={16}>16 hours</MenuItem>
                                        <MenuItem value={17}>17 hours</MenuItem>
                                        <MenuItem value={18}>18 hours</MenuItem>
                                        <MenuItem value={19}>19 hours</MenuItem>
                                        <MenuItem value={20}>20 hours</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>

                </div>
                {boldDescription?.map((item, index) => {
                    return (
                        <div className="feature-text-bold" >{item}</div>
                    );
                })}

            </div>
        </React.Fragment>
    );
}

export { ContractCardHours };
