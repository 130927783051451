import React, { useState } from "react";
import { ContentHeader } from "../../../components/Shared/ContentHeader";
import { Summary } from "./Summary";
import { Invoices } from "./Invoices";

import "./Billings.css";

function Billings() {
    const [optionSelected, setOptionSelected] = useState(0);

    return (
        <React.Fragment>
            <div className="billing-content">
                <div className="billing-header">
                    <ContentHeader text="Membership Overview" />
                </div>
                <div className="billing-header-options">
                    <div onClick={() => setOptionSelected(0)}>
                        <span className={`billing-option ${optionSelected === 0 ? "selected-option" : ""}`}>Membership</span>
                    </div>
                    <div onClick={() => setOptionSelected(1)}>
                        <span className={`billing-option ${optionSelected === 1 ? "selected-option" : ""}`}>Invoices</span>
                    </div>
                </div>
                <div className='billing-data-container'>
                    <div className={`billing-data ${optionSelected === 0 ? "width-suscription" : "width-invoices"}`}>
                        {optionSelected === 0 ? <Summary /> : <Invoices />}
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export { Billings };
