import React, { useState, useEffect, useContext } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { ContentHeader } from "../../../components/Shared/ContentHeader";
import { Button } from "../../../components/Shared/Button";
import { Counter } from "../../../components/Shared/Counter";
import { DataGrid } from "../../../components/Shared/DataGrid";
import { AiOutlinePlus, AiFillStar, AiFillMessage } from 'react-icons/ai';
import { TbListDetails } from 'react-icons/tb';
import "./Tasks.css";
import { Popup } from "../../../components/Shared/Popup";
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { PopupRateTask } from "./PopupRateTask";
import { PopupRepeatTask } from "./PopupRepeatTask";
import { PopupInfoTask } from "./PopupInfoTask";
import { RowingSharp } from "@mui/icons-material";
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { Link } from "react-router-dom/cjs/react-router-dom";

const MySwal = withReactContent(Swal);



function Tasks() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [rows, setRows] = useState([]);
    const [activeRowCount, setActiveRowCount] = useState('0');
    const [inProgressRowCount, setInProgressRowCount] = useState('0');
    const [doneRowCount, setDoneRowCount] = useState('0');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupDetailOpen, setIsPopupDetailOpen] = useState(false);
    const [isPopupRateOpen, setIsPopupRateOpen] = useState(false);
    const [isPopupRepeatOpen, setIsPopupRepeatOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [surveyData, setSurveyData] = useState(null);
    const [dateVencPlan, setDateVencPlan] = useState();

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)

                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const incrementActiveRowCount = () => {
        setActiveRowCount(prevCount => prevCount + 1);
    };
    const decrementActiveRowCount = () => {
        setActiveRowCount(prevCount => prevCount - 1);
    };

    const handleCreateChat = (task, assistant, status) => {
        if (assistant !== "" && assistant !== undefined && assistant !== null && assistant !== "Unassigned" && status !== "Done") {
            let data = {
                task_id: task
            }
            consumeFetch(`${API_BASE_URL}/messages/createChat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify(data)
            })
                .then((res) => {
                    if (res.ok) {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'Chat Created!',
                            title: 'Task chat has been created. You can see it on messages view.',
                            text: 'success',
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => { setActiveBlur(false) });
                    } else if (res.status === 400) {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'info',
                            title: 'Chat Already Exists',
                            text: 'The chat already exists, see it on messages list.',
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => { setActiveBlur(false) });
                    } else {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'We can´t create the chat. Contact support.',
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => { setActiveBlur(false) });
                    }
                }).catch((err) => {
                    console.log(err);
                });
        } else if (status === "Done") {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This task is already done, so we can´t create a chat.',
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => { setActiveBlur(false) });
        } else {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This task doesn´t have an assistant asigned, so we can´t create a chat.',
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => { setActiveBlur(false) });
        }
    }

    const renderChatButton = (params) => {
        return (
            <Button
                buttonClassName="create_Chat_Button"
                onClick={() => {
                    handleCreateChat(params.row.task_id, params.row.assistantEmail, params.row.statusName);
                }}
                icon={<AiFillMessage />}
            >
                Create Chat
            </Button>
        )
    }


    const renderDetailButton = (params) => {
        return (
            <Button
                buttonClassName="create_Chat_Button"
                onClick={() => {
                    openPopupDetail(params.row);
                }}
                icon={<TbListDetails />}
            >
            </Button>

        )
    }

    const handleClickUnassignedFilter = async () => {
        await fetchData('Unassigned');
    }

    const handleClickInProgressFilter = async () => {
        await fetchData('In Progress');
    }

    const handleClickDoneFilter = async () => {
        await fetchData('Done');
    }

    const renderTaskButton = (params) => {
        return (
            <Button
                buttonClassName="create_Task_Button"
                onClick={() => {
                    if (params.row.statusName === "Done" && params.row.calification === null) {
                        openPopupRate(params.row.task_id);
                    } else if (params.row.calification !== null) {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'info',
                            title: 'Oops ...',
                            text: "You have already rated this task",
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => { setActiveBlur(false) });
                    } else {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'info',
                            title: 'Oops ...',
                            text: "You can't rate the task because it hasn't been finished yet.",
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => { setActiveBlur(false) });
                    }
                }}
                icon={<AiFillStar />}
            >
                Rate Task
            </Button>
        )
    }

    function openPopupRate(task_id) {
        setSelectedTask(task_id);
        setIsPopupRateOpen(true);
        setActiveBlur(true)
    }

    function closePopupRate() {
        setIsPopupRateOpen(false);
        setActiveBlur(false);

    }

    function openPopupRepeat() {
        setIsPopupRepeatOpen(true);
        setActiveBlur(true)
    }

    function closePopupRepeat() {
        setIsPopupRepeatOpen(false);
        setActiveBlur(false);
    }

    function openPopupDetail(task) {
        setSelectedTask(task);
        setIsPopupDetailOpen(true);
        setActiveBlur(true)
    }

    function closePopupDetail() {
        setIsPopupDetailOpen(false);
        setActiveBlur(false);

    }

    const fetchData = async (filter) => {
        const params = new URLSearchParams();
        let endpoint = `${API_BASE_URL}/tasks/tasksByClient`;
        params.append('email', emailGlobal);
        const url = `${endpoint}?${params}`;
        try {
            const response = await consumeFetch(url, {
                headers: {
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            const data = await response.json();
            // fecha vencimiento plan
            setDateVencPlan(data.fechaVencimientoPlan);

            const newRows = data.data.map((item, index) => {

                const assistantEmail = item.assistantId ? item.assistantId.email : "Unassigned";
                return {
                    id: index,
                    // taskTypeName: item.taskType_Id ? item.taskType_Id.task_type_name : "",
                    statusName: item.status_Id.status_name,
                    assistantEmail: assistantEmail,
                    task_id: item.id,
                    dateLimit: item.date_limit,
                    ...item
                }
            });
            const updatedRows = await Promise.all(newRows.map(async (element) => {
                const urlGetSecrets = `${API_BASE_URL}/task_secrets/getByTaskId/${element.id}`;

                const responseSecrets = await consumeFetch(urlGetSecrets, {
                    headers: {
                        Authorization: `${token}`
                    }
                });
                const dataReturned = await responseSecrets.json();
                const valuesVault = dataReturned.data.map(item => item.secret.name_secret);
                return {
                    ...element,
                    valuesVault: valuesVault,
                };
            }));
            if (filter == null) {
                setRows(updatedRows);
                console.log(updatedRows);

            } else {
                const filterRows = updatedRows.filter(row => row.statusName === filter)
                console.log(filterRows)
                setRows(filterRows);

            }
            setActiveRowCount(updatedRows.filter(row => row.statusName === 'Unassigned').length);
            setInProgressRowCount(updatedRows.filter(row => row.statusName === 'In Progress').length);
            setDoneRowCount(updatedRows.filter(row => row.statusName === 'Done').length);
        } catch (error) {
            console.error(error);
            // Manejar el error
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedTask]);

    const columns = [
        { field: 'id', headerName: 'ID', alignItems: 'center', width: 150, show: false },
        { field: 'taskTitle', headerName: 'Task', alignItems: 'center', width: 150, show: true },
        { field: 'description', headerName: 'Description', alignItems: 'center', width: 150, show: true },
        { field: 'type_creation', headerName: 'Type Creation', alignItems: 'center', width: 200, show: false },
        { field: 'recurrence', headerName: 'Recurrence', alignItems: 'center', width: 150, show: false },
        { field: 'dateInitial', headerName: 'Initial Date', alignItems: 'center', width: 150, show: false },
        { field: 'dateLimit', headerName: 'Date Limit', alignItems: 'center', width: 150, show: false },
        // { field: 'taskTypeName', headerName: 'Task Type', alignItems: 'center', width: 150, show: false },
        { field: 'dailyLimitHours', headerName: 'Daily Limit', alignItems: 'center', width: 150, show: false },
        { field: 'hours', headerName: 'Hour Limit', alignItems: 'center', width: 150, show: false },
        { field: 'statusName', headerName: 'Status', alignItems: 'center', width: 135, show: true },
        { field: 'assistantEmail', headerName: 'Assistant Assigned', alignItems: 'center', width: 150, show: true },
        { field: 'valuesVault', headerName: 'Vault Passwords', alignItems: 'center', width: 200, show: false },
        { field: 'calification', headerName: 'Rate Task', alignItems: 'center', width: 150, renderCell: renderTaskButton, show: false },
        { field: 'task_id_detail', headerName: 'Details', alignItems: 'center', width: 150, renderCell: renderDetailButton, show: true },
        { field: 'saveTemplate', headerName: 'Save this task to repeat it again in different dates.', alignItems: 'center', width: 200, show: false },
    ];

    useEffect(() => {
        // Action to perform after the state has been updated
        console.log(rows); // Updated rows

    }, [rows]);

    function openPopup() {
        consumeFetch(`${API_BASE_URL}/surveys/questions/byTask/default`, {
            headers: {
                Authorization: `${token}`
            }
        })
            .then(response => response.json())
            .then(data => setSurveyData(data))
            .catch((err) => {
                console.error(err);
            });
        setIsPopupOpen(true);
        setActiveBlur(true);
    }

    function closePopup() {
        setIsPopupOpen(false);
        setActiveBlur(false)
    }
    return (
        <React.Fragment>
            <div className="tasks-header">
                <ContentHeader text="Tasks" />
            </div>
            <div className="tasks-info">
 
                <div className="tasks-view">
                    <div className="tasks-table">
                        {rows ? (<DataGrid activeRowCount={activeRowCount}
                            setActiveRowCount={incrementActiveRowCount}
                            decrementActiveRowCount={decrementActiveRowCount}
                            pRows={rows} pColumns={columns} pTittle="Task"
                            dateVencPlan={dateVencPlan} openPopupRepeat={openPopupRepeat} handleClickInProgressFilter={handleClickInProgressFilter} inProgressRowCount={inProgressRowCount} handleClickUnassignedFilter={handleClickUnassignedFilter} handleClickDoneFilter={handleClickDoneFilter} doneRowCount={doneRowCount}/>
                        ) : (
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={true}
                            // onClick={handleClose}
                            >
                                <CircularProgress style={{ height: "50px", width: "100px" }} color="inherit" />
                            </Backdrop>
                        )
                        }
                    </div>
                </div>
            </div>
            <Popup isOpen={isPopupRateOpen} onClose={closePopupRate} modalClass="popup-info-task">
                {selectedTask && (
                    <PopupRateTask selectedTask={selectedTask} closePopup={closePopupRate} />
                )}
            </Popup>
            <Popup isOpen={isPopupDetailOpen} onClose={closePopupDetail} modalClass="popup-info-task">
                {selectedTask && (
                    <PopupInfoTask
                        selectedTask={selectedTask} setSelectedTask={setSelectedTask} closePopupDetail={closePopupDetail} />
                )}
            </Popup>
            <Popup isOpen={isPopupRepeatOpen} onClose={closePopupRepeat} modalClass="popup-info-task">
                <PopupRepeatTask dateVencPlan={dateVencPlan} closePopup={closePopupRepeat} fetchDataTask={fetchData} />
            </Popup>
        </React.Fragment >
    );
}

export { Tasks };
