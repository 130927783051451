import * as muiMaterial from '@mui/material';
import * as muiDataGrid from '@mui/x-data-grid';
import { useRef, useState } from 'react';
import { CustomizedSnackbar } from './Snackbar';

const pageSizeOptions = [5, 10, 20];

function DataGridSimple(props) {

    const { pRows, pColumns, pTittle } = props;


    const SnackbarRef = useRef();
    const [Rows, setRows] = useState(pRows);
    const [Columns] = useState(pColumns);

    return (
        <muiMaterial.Box
            sx={{
                width: '100%',
                height: '10',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 2,
            }} >

            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />

            <muiDataGrid.DataGrid
                rows={Rows}
                columns={Columns.map((column, index) => ({
                    ...column,
                    align: index === column.length - 1 ? 'center' : 'right',
                    headerAlign: 'center',
                }))}
                disableColumnMenu={true}
                pageSize={10} rowsPerPageOptions={pageSizeOptions}
                sx={{
                    background: 'white',
                    overflowX: 'auto',
                }}

                autoHeight
                autoWidth
            />

        </muiMaterial.Box>
    );
}


export { DataGridSimple };