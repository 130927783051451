import React, { useContext, useState, useEffect } from 'react';
import './Navbar.css';
import { NavbarSearch } from './NavbarSearch';
import { NavbarLogo } from './NavbarLogo';
import { Chat } from './messages/chat';
import { Notifications } from './notifications';
import { Button } from '../Shared/Button';
import { RiNotification3Fill } from 'react-icons/ri';
import { SlEnvolope } from 'react-icons/sl';
import { ProfileButton } from './ProfileButton';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import socket from "../../utils/socket.js";
import { GlobalContext } from '../../context/GlobalContext';
import imgDefaultUser from '../../assets/images/default_user.png';
import avatar1 from '../../assets/images/avatars/avatar1.png';
import avatar2 from '../../assets/images/avatars/avatar2.png';
import avatar3 from '../../assets/images/avatars/avatar3.png';
import avatar4 from '../../assets/images/avatars/avatar4.png';
import avatar5 from '../../assets/images/avatars/avatar5.png';
import avatar6 from '../../assets/images/avatars/avatar6.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

function Navbar() {
    const { showBadge, setShowBadge, showNotificationsBadge, setShowNotificationsBadge, anchorEl, setAnchorEl, imgUsuario } = useContext(GlobalContext);

    const allAvatar = {
        "avatar1":avatar1,
        "avatar2":avatar2,
        "avatar3":avatar3,
        "avatar4":avatar4,
        "avatar5":avatar5,
        "avatar6":avatar6,
    }
    
    const [anchorElNotifications, setAnchorElNotifications] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setShowBadge(true);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
        setShowBadge(true);
    };

    const handleClickNotifications = (event) => {
        setAnchorElNotifications(event.currentTarget);
        setShowNotificationsBadge(true);
    };
    
    const handleCloseNotifications = () => {
        setAnchorElNotifications(null);
        setShowNotificationsBadge(true);
    };

    useEffect(() => {
        setAnchorEl(null)
        setShowBadge(true);
        socket.on("private message notification", ({ content, from, to }) => {
            setShowBadge(false);
        });

        socket.on("Admin Message notification", ({ content, from, to }) => {
            setShowBadge(false);
        });

        return ()=>{
            socket.off("private message notification");
            socket.off("Admin Message notification");
        }
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const bgMessages = open ? 'container-notifications' : undefined;
    const openNotifications = Boolean(anchorElNotifications);
    const idNotifications = openNotifications ? 'simple-popover' : undefined;
    const bgNotifications = openNotifications ? 'container-notifications' : undefined;
    return (
        <React.Fragment>
            <div className='navbar'>
                <div className='left-navbar'>
                    <Link to='/user/Home'>
                    <NavbarLogo />
                    </Link>
                </div>
                <div className='right-navbar'>
                    <div className={bgNotifications}>
                        <Badge variant="dot" invisible={showNotificationsBadge} sx={{"& .MuiBadge-badge": {color: "#000", backgroundColor: "#004752"}}}>
                            <RiNotification3Fill
                                color='#D8D8D8'
                                className='NavbarAlertIcon NavbarNotificationIcon'
                                onClick={handleClickNotifications} />
                        </Badge>
                        <Popover
                            className=''
                            id={idNotifications}
                            open={openNotifications}
                            anchorEl={anchorElNotifications}
                            anchorReference="anchorEl"
                            onClose={handleCloseNotifications}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        
                        >
                            <Notifications onClose={handleCloseNotifications}/>
                        </Popover>
                    </div>
                    <div className={bgMessages}>
                        <Badge variant="dot" invisible={showBadge} color="primary">
                            <SlEnvolope
                                className='NavbarAlertIcon'
                                color='#D8D8D8'
                                onClick={handleClick}
                                children='Messages'
                            />
                        </Badge>
                        <Popover
                            className=''
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            anchorReference="anchorEl"
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        
                        >
                            <Chat />
                        </Popover>
                    </div>
                    <div className="navbar-separator-container">
                        <span className="navbar-separator"></span>
                    </div>
                    <ProfileButton
                        profilePicture={` ${imgUsuario && imgUsuario.includes("s3.amazonaws.com") ? imgUsuario : allAvatar[imgUsuario] ? allAvatar[imgUsuario] :imgDefaultUser } `}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export { Navbar };
