import React, { useContext, useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { useHistory, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import socket from "../../utils/socket.js";

import { Sidebar } from '../sidebar';
import { Navbar } from '../Navbar';

import { generalMainOptions, clientOptionsVAssistant, clientOptionsVOffice, noPlanOptions } from '../../constants/clientMainPage';
import { assistantMainOptions, assistantOptionsVOffice } from '../../constants/assistantMainPage';
import { mobileOptions } from '../../constants/mobileOptions';
import { supportMainOptions } from '../../constants/supportMainPage';
import { adminMainOptions } from '../../constants/adminMainPage';
import { CustomizedSnackbar } from '../Shared/Snackbar';
import { Chat } from '../messages/window/chat-window/index.jsx';
import { Chat as ChatMobile } from '../Navbar/messages/chat/index.jsx';

import { GlobalContext } from '../../context/GlobalContext';
import { Profile } from '../../pages/clientContent/profile/Profile'
import { BiSupport, BiLogOut } from 'react-icons/bi';
import { AiFillProject } from 'react-icons/ai';

import './MainPage.css';

const INACTIVITY_TIMEOUT = 300000; // 5 minutos en milisegundos
const MySwal = withReactContent(Swal);

function MainPage() {

    const { userRole, setActivePage, setToken, setEmailGlobal, setIdUserGlobal, setUserName, setShowNotificationsBadge, activeBlur, setActiveBlur, planesUsuario } = useContext(GlobalContext)
    const [lastInteraction, setLastInteraction] = useState(Date.now());
    const [activePopUpLogOut, setActivePopUpLogOut] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const history = useHistory();
    const { component } = useParams();

    useEffect(()=>{
        setActiveBlur(false);
    },[])

    const supportOptions = screenWidth>600 ? [
        { name: 'PM', component: <Chat isAdmin={true}/>, icon: <AiFillProject /> },
        { name: 'Bruno (Support)', component: <Chat isSupport={true}/>, icon: <BiSupport /> },
        { name: 'Logout', component: '', icon: <BiLogOut /> },
    ] : 
    [
        { name: 'Bruno (Support)', component: <ChatMobile isSupport={true}/>, icon: <BiSupport /> },
        { name: 'Logout', component: '', icon: <BiLogOut /> },
    ];



    const mainOptions = () => {
        if (userRole === 'Client') {
            var returnOptions = [];
            if(planesUsuario.length === 0){
                returnOptions = [...returnOptions, ...noPlanOptions];
            }
            planesUsuario.map((plan) => {
                if (plan === "Virtual Assistant") {
                    returnOptions = [...returnOptions, ...clientOptionsVAssistant];
                }
                if (plan === "Virtual Office") {
                    returnOptions = [...returnOptions, ...clientOptionsVOffice];
                }
            })
            returnOptions = [...returnOptions, ...generalMainOptions]
            return returnOptions;
        }
        if (userRole === 'Assistant') {
            return assistantMainOptions;
        }
        if (userRole === 'Assistant Virtual Office') {
            return assistantOptionsVOffice;
        }
        if (userRole === 'Support') {
            return supportMainOptions;
        }
        if (userRole === 'Admin') {
            return adminMainOptions;
        }
    };

    const options = [...mainOptions(), ...supportOptions, ...mobileOptions];

    const SnackbarRef = useRef();

    useEffect(() => {
        socket.on("Notification", ({ content, to }) => {
            setShowNotificationsBadge(false);
        });
  
        const handleInteraction = () => {
            setLastInteraction(Date.now());
        };

        // Agrega eventos de interacción para detectar la actividad del usuario
        window.addEventListener('mousemove', handleInteraction);
        window.addEventListener('keydown', handleInteraction);

        // Elimina los eventos de interacción al desmontar el componente
        return () => {
            socket.off("Notification");
            window.removeEventListener('mousemove', handleInteraction);
            window.removeEventListener('keydown', handleInteraction);
        };
    }, []);

    useEffect(()=> {
        setActivePage(component)
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
          };
      
          // Attach the event listener to the window
          window.addEventListener('resize', handleResize);
      
          // Clean up the event listener when the component unmounts
          return () => {
            window.removeEventListener('resize', handleResize);
          };
    },[component])

    const logoutUser = () => {
        setActiveBlur(true);
        MySwal.fire({
            title: `Ups you exceeds ${~~(INACTIVITY_TIMEOUT / 60000)} minutes of inactivity`,
            text: 'Do you want to leave Assisttu?',
            width: 430,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: `No, I want to continue`,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet title-exceed-time',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Cookies.remove('refreshToken');
                Cookies.remove('rememberMe');
                socket.disconnect();
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
                setActivePopUpLogOut(false)
            } else if (result.isDenied) {
                setActivePopUpLogOut(false)
            }
        }).finally(() => {setActiveBlur(false)});
    };

    useEffect(() => {
        const checkInactivity = setInterval(() => {
            const currentTime = Date.now();
            // console.log("Se comprueba inactividad actual tiempo inactivo = ", currentTime - lastInteraction)
            if (currentTime - lastInteraction > INACTIVITY_TIMEOUT && !activePopUpLogOut) {
                setActivePopUpLogOut(true);
                logoutUser(currentTime - lastInteraction);
                clearInterval(checkInactivity);
            }
        }, 1000); // Comprobar la inactividad cada segundo

        return () => {
            clearInterval(checkInactivity);
        };
    }, [lastInteraction]);

    return (
        <div className="main-page">
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <Navbar />
            <div className="content-wrapper">
                <Sidebar
                    mainOptions={mainOptions()}
                    supportOptions={supportOptions}
                    middleOptions={mobileOptions}
                    onLogoutClick={() => console.log('loggedout')}
                />
                {options.map(
                    option => option.name === component
                        ? <div className={`main-content ${option.name} ${ activeBlur && "blur-class" }`} key={option.name}>{option.component}</div>
                        : null
                )
                }
            </div>
        </div>
    );
}

export { MainPage };