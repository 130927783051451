import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import socket from "../../utils/socket";
const MySwal = withReactContent(Swal);

const ApiMiddleware = (fetch) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";

    return async (url, options) => {

        const responseFetch = await fetch(url, options).then(response => {
            return response;
        });
        if (!responseFetch.ok) {
            if (responseFetch.status === 401) {
                const resp = await responseFetch.json();
                if (resp.error === 'JWT Expirado') {
                    console.log("JWT Expirado")
                    const refreshToken = Cookies.get('refreshToken');

                    const responseRefreshFetch = await fetch(`${API_BASE_URL}/users/refresh/getRefresh`, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `${refreshToken}`
                        },
                    })
                    const data = await responseRefreshFetch.json();
                    if (!responseRefreshFetch.ok) {
                        MySwal.fire({
                            title: `Session time expired!!`,
                            text: 'You must log in again',
                            width: 430,
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        })
                        Cookies.remove('refreshToken');
                        Cookies.remove('rememberMe');
                        socket.disconnect();
                        throw new Error("RefreshToken Vencido");
                    }
                    const newToken = data.token;
                    options.headers.Authorization = newToken;
                    return await fetch(url, options).then(response => {
                        // Retorno del nuevo token generado
                        const valorAdicional = newToken;

                        // Retornar tanto la respuesta de fetch como el valor de newToken
                        return { response: response, token: valorAdicional };

                    });
                }
            } else {
                return { response: responseFetch };
            }
        }

        return { response: responseFetch };
    };
};

export default ApiMiddleware;
