import React, { useState, useEffect, useContext } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { ContentHeader } from "../../../components/Shared/ContentHeader";
import { Button } from "../../../components/Shared/Button";
import { DataGridIncomings } from "./DataGridIncomings";
import {TbListDetails} from 'react-icons/tb';
import "./Incomings.css";
import { Popup } from "../../../components/Shared/Popup";
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { PopupInfoTask } from "../../clientContent/tasks/PopupInfoTask";
const MySwal = withReactContent(Swal);



function Incomings() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [rows, setRows] = useState();
    const [activeRowCount, setActiveRowCount] = useState('0');
    const [isPopupDetailOpen, setIsPopupDetailOpen] = useState(false);
    const [selectedIncoming, setSelectedIncoming] = useState(null);

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)

                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const incrementActiveRowCount = () => {
        setActiveRowCount(prevCount => prevCount + 1);
    };
    const decrementActiveRowCount = () => {
        setActiveRowCount(prevCount => prevCount - 1);
    };

    const handleCreateChat = (task, assistant, status) => {
        if (assistant !== "" && assistant !== undefined && assistant !== null && assistant !== "Unassigned" && status !=="Done") {
            let data = {
                task_id: task
            }
            consumeFetch(`${API_BASE_URL}/messages/createChat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify(data)
            })
                .then((res) => {
                    if (res.ok) {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'Chat Created!',
                            title: 'Task chat has been created. You can see it on messages view.',
                            text: 'success',
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => {setActiveBlur(false)});
                    } else if (res.status === 400) {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'info',
                            title: 'Chat Already Exists',
                            text: 'The chat already exists, see it on messages list.',
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => {setActiveBlur(false)});
                    } else {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'We can´t create the chat. Contact support.',
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => {setActiveBlur(false)});
                    }
                }).catch((err) => {
                    console.log(err);
                });
        } else if(status === "Done"){
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This task is already done, so we can´t create a chat.',
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => {setActiveBlur(false)});
        } else {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This task doesn´t have an assistant asigned, so we can´t create a chat.',
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => {setActiveBlur(false)});
        }
    }

    const renderDetailButton = (params) => {
        return (
            <Button
                buttonClassName="incoming_Details_Button"
                onClick={() => {
                    openPopupDetail(params.row);
                }}
                icon={<TbListDetails/>}
            >
            </Button>

        )
    }

    function openPopupDetail(incoming) {
        console.log(incoming)
        setSelectedIncoming(incoming);
        setIsPopupDetailOpen(true);
        setActiveBlur(true)
    }

    function closePopupDetail() {
        setIsPopupDetailOpen(false);
        setActiveBlur(false);
    }

    const fetchData = async () => {
        // const params = new URLSearchParams();
        // let endpoint = `${API_BASE_URL}/incomings/getOpenIncomings`;
        // params.append('user', 'assistant');
        // params.append('idUser', idUserGlobal);
        // const url = `${endpoint}?${params}`;
        let url = `${API_BASE_URL}/incomings/getAllOpen`;
        try {
            const response = await consumeFetch(url, {
                headers: {
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            const data = await response.json();
            console.log(data);
            const newRows = data.data.map((item, index) => {
                const fechaFormateadaStr = `${item.dateArrived} ${item.timeArrived}`;
                return {
                    id: item.id,
                    incoming_id: item.id,
                    from: item.sender,
                    to: item.client.email,
                    type_name: item.type.incoming_type_name,
                    weight: item.weightIncoming,
                    date: fechaFormateadaStr,
                    ...item
                }
            });

            setRows(newRows);
        } catch (error) {
            console.error(error);
            // Manejar el error
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    
    const columns = [
        { field: 'incoming_id', headerName: 'ID', alignItems: 'center', width: 150, show: true },
        { field: 'from', headerName: 'From', alignItems: 'center', width: 180, show: true },
        { field: 'to', headerName: 'To', alignItems: 'center', width: 200, show: true },
        { field: 'type_name', headerName: 'Type', alignItems: 'center', width: 110, show: true },
        { field: 'weight', headerName: 'Weight (Kg)', alignItems: 'center', width: 90, show: true },
        { field: 'date', headerName: 'Date', alignItems: 'center', width: 150, show: true },
        { field: 'time', headerName: 'Time', alignItems: 'center', width: 70, show: false },
        { field: 'foto', headerName: 'Photo', alignItems: 'center', width: 70, show: false },
        // { field: 'incoming_id_detail', headerName: 'Details', alignItems: 'center', width: 90, renderCell: renderDetailButton, show: true },
    ];

    // useEffect(() => {
    // }, [rows]);

    return (
        <React.Fragment>
            <div className="incomings-header">
                <ContentHeader text="Incomings" />
            </div>
            <div className="incomings-info">
                <div className="incomings-view">
                    <div className="incomings-table">
                        {rows ? (<DataGridIncomings activeRowCount={activeRowCount}
                            setActiveRowCount={incrementActiveRowCount}
                            decrementActiveRowCount={decrementActiveRowCount}
                            pRows={rows} pColumns={columns} pTitle="incomings" />
                        ) : (
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={true}
                            // onClick={handleClose}
                            >
                                <CircularProgress style={{ height: "50px", width: "100px" }} color="inherit" />
                            </Backdrop>
                        )
                        }
                    </div>
                </div>
            </div>
            <Popup isOpen={isPopupDetailOpen} onClose={closePopupDetail} modalClass="popup-info-task">
                {selectedIncoming && (
                    <PopupInfoTask
                        selectedTask={selectedIncoming} />
                )}
            </Popup>
        </React.Fragment >
    );
}

export { Incomings };
