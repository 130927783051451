
import {
    Dialog, Button, TextField,
    InputAdornment, FormControl
} from "@mui/material";
import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from 'react-phone-number-input'
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import '../../../components/Shared/SharedStyles.css';
import './Contract.css';

const ModalFormNeedHelp = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false)
    const [Model, setModel] = useState([]);
    const [Title, setTitle] = useState('');
    const [typeAction, setTypeAction] = useState('');
    const [emptyFields, setEmptyFields] = useState([]);
    const [phoneValue, setPhoneValue] = useState();
    const {
        control
    } = useForm();

    const ITEM_HEIGHT = 30;
    const ITEM_PADDING_TOP = 4;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const SnackbarRef = useRef();

    const rows = 1;
    const cols = Math.ceil(Model.length / rows);

    const fields = [];
    for (let i = 0; i < rows; i++) {
        fields.push(Model.slice(i * cols, i * cols + cols));
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const saveButtonClick = (e) => {
        e.preventDefault();

        try {

            let toInsertModel = {};
            for (let i = 0; i < Model.length; i++) {
                if (!Model[i].values && Model[i].value === '') {
                    if (!emptyFields.includes(Model[i].field)) {
                        setEmptyFields(prevEmptyFields => [...prevEmptyFields, Model[i].field]);
                    }
                    SnackbarRef.current.handleSnackbarOpen(`${Model[i].headerName} field cannot be empty`, 'error');
                    return
                }
                toInsertModel[Model[i].field] = getTypeValue(Model[i].value, Model[i].type);
            }

            if (typeAction === 'New Form') props.onAddItem({ ...toInsertModel })
            setTimeout(() => {
                handleClose();
            }, 500);

        } catch (error) {
            SnackbarRef.current.handleSnackbarOpen('Error: try again later', 'error');
        }
    };


    const handleChange = (e, index) => {
        const currentField = fields.flat()[index];
        if (e.target.value === '') {
            if (!emptyFields.includes(currentField.field)) {
                setEmptyFields(prevEmptyFields => [...prevEmptyFields, currentField.field]);
            }
        } else {
            setEmptyFields(prevEmptyFields => prevEmptyFields.filter(field => field !== currentField.field));
        }


        const newFormData = [...Model];
        newFormData[index].value = e.target.value;
        setModel(newFormData);
    };

    const handlePhoneChange = (e, index) => {
        const currentField = fields.flat()[index];
        if (e === '') {
            if (!emptyFields.includes(currentField.field)) {
                setEmptyFields(prevEmptyFields => [...prevEmptyFields, currentField.field]);
            }
        } else {
            setEmptyFields(prevEmptyFields => prevEmptyFields.filter(field => field !== currentField.field));
        }


        const newFormData = [...Model];
        newFormData[index].value = e;
        setModel(newFormData);
    };

    const typeParsers = {
        int: (value) => parseInt(value, 10),
        string: (value) => String(value),
        date: (value) => new Date(value),
    };
    const getTypeValue = (value, type) => {
        type = type === 'number' ? 'int' : 'string';
        const parser = typeParsers[type];
        if (!parser) {
            throw new Error(`Unsupported type: ${type}`);
        }
        return parser(value);
    };

    useImperativeHandle(ref, () => ({
        handleOpen: handleOpen,
        setModel: setModel,
        setTitle: setTitle,
        setTypeAction: setTypeAction,
    }));

    return (
        <Dialog open={open} onClose={handleClose} >

            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />

            <form className="container-form">
                <div className = "title-form" style={{ marginBottom: '1rem' }}>
                    {Title}
                </div>
                {
                    fields.map((row, i) => (
                        <div key={i} className="other-fields-formNeed">
                            {row.map((item, j) => (
                                <>
                                    {item.field === 'name' ? (
                                        <FormControl fullWidth>
                                            <TextField
                                                key={j}
                                                label={item.headerName}
                                                value={item.value}
                                                type={item.type}
                                                onChange={(e) => handleChange(e, i * cols + j)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                            />
                                            
                                            {emptyFields.includes(item.field) && (
                                                <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                            )}
                                        </FormControl>
                                    ) : (item.field === 'lastName' ? (
                                        <FormControl fullWidth>
                                            <TextField
                                                key={j}
                                                label={item.headerName}
                                                value={item.value}
                                                type={item.type}
                                                onChange={(e) => handleChange(e, i * cols + j)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                            />
                                            
                                            {emptyFields.includes(item.field) && (
                                                <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                            )}
                                        </FormControl>
                                    ) : ((item.field === 'email') ? (
                                        <FormControl fullWidth>
                                            <TextField
                                                key={j}
                                                label={item.headerName}
                                                value={item.value}
                                                type={item.type}
                                                onChange={(e) => handleChange(e, i * cols + j)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                            />
                                            
                                            {emptyFields.includes(item.field) && (
                                                <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                            )}
                                        </FormControl>
                                    ) : ((item.field === "company") ? (
                                        <FormControl fullWidth>
                                            <TextField
                                                key={j}
                                                label={item.headerName}
                                                value={item.value}
                                                type={item.type}
                                                onChange={(e) => handleChange(e, i * cols + j)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                            />
                                            
                                            {emptyFields.includes(item.field) && (
                                                <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                            )}
                                        </FormControl>
                                    ) : ((item.field === "phone") ? (
                                        <FormControl fullWidth >
                                            <div className="textarea-description-task-cliente">
                                                <label className="textarea-description-task-title">Phone</label>
                                                <PhoneInput
                                                    key={j}
                                                    className="phone-input-need"
                                                    label={item.headerName}
                                                    defaultCountry="US"
                                                    name="phone"
                                                    value={item.value}
                                                    onChange={(e) => handlePhoneChange(e, i * cols + j)}
                                                />
                                            </div>
                                            {emptyFields.includes(item.field) && (
                                                <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                            )}
                                        </FormControl>
                                    ): null))))}
                                </>
                            ))}
                        </div>
                    ))
                }
                {
                    fields.map((row, i) => (
                        <div key={i} className="comments-field-formNeed">
                            {row.map((item, j) => (
                                <>
                                    {item.field === "comments" &&
                                        <FormControl fullWidth>
                                            <div className="textarea-description-task-cliente">
                                                <label className="textarea-description-task-title">Comments</label>
                                                <textarea
                                                    className="textarea-description-task-client"
                                                    key={j}
                                                    label={item.headerName}
                                                    value={item.value}
                                                    type={item.type}
                                                    onChange={(e) => handleChange(e, i * cols + j)}
                                                />
                                            </div>
                                        </FormControl>
                                    }
                                </>
                            ))}
                        </div>
                    ))
                }
                <div>
                    <Button
                        variant="contained"
                        className="form-button"
                        sx={{ margin: "2rem" }}
                        onClick={saveButtonClick}
                    >
                        Send
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ margin: "2rem" }}
                        className="form-button"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </Dialog >
    );
});


export { ModalFormNeedHelp };
