import React, {useContext, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import socket from "../../../utils/socket";
import { GlobalContext } from '../../../context/GlobalContext'
import { Button } from '../../../components/Shared/Button'
import './BoxPassword.css'
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";

const MySwal = withReactContent(Swal);


function BoxPassword(props){
    const { item, statusTask } = props
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [ password , setPassword ] = useState();
    const [ isLoading , setIsLoading ] = useState(false);

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const handleClick = () => {
        setIsLoading(true);
        if (statusTask === "Done") {
            setActiveBlur(true);
            MySwal.fire({
                title: 'This task is already Done',
                text: "It is not possible to obtain associated secret",
                icon:"info",
                timer:4000,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
              }).finally(() => {setActiveBlur(false)})
              setIsLoading(false);
              return;
        }
        const endpoint = `${API_BASE_URL}/vault/getSecretValue`;
        const params = new URLSearchParams();
        params.append('email_user', item.email_user);
        params.append('secret_name', item.secret_name);
        const url = `${endpoint}?${params}`;
        consumeFetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error en la solicitud de getSecretValue');
            }
            return response.json();
        }).then(data => {
            setPassword(data.secret);
            setIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
        });
    };

    return (
        <React.Fragment>

            <div className="boxPassword">
                <div>{item.secret_name}</div>
                {/* <button onClick={handleClick}>GET</button> */}
                <Button buttonClassName="button-get-password" onClick={handleClick} children="GET"/>
                <div className="input-secret">
                    {isLoading===true && (
                        <CircularProgress style={{height:"10px", width:"10px"}} color="inherit" />
                    )}
                    <input
                        className={`secret-value-task`}
                        defaultValue="********"
                        type="text"
                        name="secret"
                        value={password!==undefined ? (password):("********")}
                        contentEditable="false"
                        readOnly
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export { BoxPassword };