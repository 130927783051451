import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { ContentHeader } from '../../../components/Shared/ContentHeader';
import { GlobalContext } from '../../../context/GlobalContext';
import { ToDoList } from './toDoList';
import { TaskList } from './taskList';
import { HeadTask } from './Task';
import { PmBox } from './pm/PmBox';

import { HomePage } from './videoHome'

import { WidgetTasksToApproved } from './tasksToApproved/index'
import { AssistantBox } from './assistants/AssistantBox';
import './home.css'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function Home() {
    const { userRole, planesUsuario } = useContext(GlobalContext)


    return (
        <React.Fragment>
            {planesUsuario.length === 0 ?
                    <HomePage />

                :
                <>
                    <ContentHeader text='Home' />
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                            {userRole === "Client" && (<Grid item xs={12} sm={6} md={6}>
                                <h1 className='home-header'>Tasks</h1>
                                <Item><HeadTask /></Item>
                                <Item><TaskList /></Item>
                                <h1 className='home-header'>Tasks to Approve</h1>

                                <Item><WidgetTasksToApproved /></Item>
                            </Grid>)}
                            {userRole === "Client" && (<Grid item xs={12} sm={6} md={6}>
                                <h1 className='home-header'>Your Assisttus</h1>
                                <Item><PmBox /></Item>
                                <Item><AssistantBox /></Item>
                                <h1 className='home-header'>To Do List</h1>

                                <Item><ToDoList /></Item>
                                <br />

                            </Grid>
                            )}

                        </Grid>

                    </Box>
                </>
            }

        </React.Fragment>
    );
}

export { Home };
