import React, { useContext, useState, useEffect, useRef } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { GlobalContext } from '../../../context/GlobalContext'
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { Button } from "../../../components/Shared/Button";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { FormControl, TextField } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import './PopupCreateEvent.css'

const MySwal = withReactContent(Swal);

function PopupCreateEvent(props) {
    const { idClient, closePopup, fetchDataTask } = props;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setToken, token, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [dateInitial, setDateInitial] = useState();
    const [dateEnd, setDateEnd] = useState();
    const [minDateEnd, setMinDateEnd] = useState(dayjs().add(30, 'minute'));
    const [minDateInitial, setMinDateInitial] = useState(dayjs());
    const [maxDateEnd, setMaxDateEnd] = useState(null);
    const [maxDateInitial, setMaxDateInitial] = useState(null);
    const [titleEvent, setTitleEvent] = useState('');
    const [descriptionEvent, setDescriptionEvent] = useState('');
    const [isEmptyTitle, setIsEmptyTitle] = useState(false);
    const [isEmptyDescription, setIsEmptyDescription] = useState(false);
    const [isEmptyDateInitial, setIsEmptyDateInitial] = useState(false);
    const [isEmptyDateEnd, setIsEmptyDateEnd] = useState(false);

    const SnackbarRef = useRef();

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect(() => {
        // si dateInitial es instancia Date se le asigna a minDateEnd mas 30 minutos
        if (dateInitial && dateInitial.toString() !== 'Invalid Date') {
            var dateInitialAux = new Date(dateInitial);
            dateInitialAux.setMinutes(dateInitialAux.getMinutes() + 30);
            setMinDateEnd(dateInitialAux);
        }

        //si  dateEnd tiene valor y es instancia Date, se le asigna a maxDateInitial, menos 30 minutos
        if (dateEnd && dateInitial.toString() !== 'Invalid Date') {
            var dateEndAux = new Date(dateEnd);
            dateEndAux.setMinutes(dateEndAux.getMinutes() - 30);
            setMaxDateInitial(dateEndAux);
        }
    }, [dateInitial, dateEnd]);

    const handleChangeTitle = (e) => {
        const value = e.target.value;
        setTitleEvent(value);
        (isEmpty(value)) ? setIsEmptyTitle(true) : setIsEmptyTitle(false);

    }

    const handleChangeDescription = (e) => {
        const value = e.target.value;
        setDescriptionEvent(value);
        (isEmpty(value)) ? setIsEmptyDescription(true) : setIsEmptyDescription(false);
    }

    const handleChangeDateInitial = (e) => {
        const value = e;
        setDateInitial(value);
        (isEmpty(value)) ? setIsEmptyDateInitial(true) : setIsEmptyDateInitial(false);
    }

    const handleChangeDateEnd = (e) => {
        const value = e;
        setDateEnd(value);
        (isEmpty(value)) ? setIsEmptyDateEnd(true) : setIsEmptyDateEnd(false);
    }


    const handleCreateRepeatedTask = async () => {

        if ((dateInitial && dateInitial.toString() === 'Invalid Date') || (dateEnd && dateEnd.toString() === 'Invalid Date')) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid Date!`, 'error');
            return;
        }

        if (isEmpty(titleEvent)) { setIsEmptyTitle(true); return; } else { setIsEmptyTitle(false); };
        if (isEmpty(descriptionEvent)) { setIsEmptyDescription(true); return; } else { setIsEmptyDescription(false); };
        if (isEmpty(dateInitial)) { setIsEmptyDateInitial(true); return; } else { setIsEmptyDateInitial(false); };
        if (isEmpty(dateEnd)) { setIsEmptyDateEnd(true); return; } else { setIsEmptyDateEnd(false); };

        if (isInvalidDateInitial(dateInitial)) { return; } ;
        if (isInvalidDateEnd(dateEnd)) { return; } ;

        try {

            const response = await consumeFetch(`${API_BASE_URL}/user_calendar/`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify({
                    user_id: idClient,
                    title: titleEvent,
                    startDate: dateInitial,
                    endDate: dateEnd,
                    description: descriptionEvent
                })
            });

            if (response.ok) {
                setActiveBlur(true);
                MySwal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Event saved.',
                    showConfirmButton: false,
                    timer: 1700,
                    backdrop: true,
                    customClass: {
                        popup: 'popup-sweet',
                        title: 'title-sweet',
                        htmlContainer: 'text-sweet',
                        confirmButton: 'confirm-button-sweet',
                        denyButton: 'deny-button-sweet',
                    }
                }).then(() => {
                    closePopup();
                    fetchDataTask();
                }).finally(() => { setActiveBlur(false) });
            } else {
                setActiveBlur(true);
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops ...',
                    text: 'There was a unexpected error, please contact support.',
                    showConfirmButton: false,
                    timer: 1700,
                    backdrop: true,
                    customClass: {
                        popup: 'popup-sweet',
                        title: 'title-sweet',
                        htmlContainer: 'text-sweet',
                        confirmButton: 'confirm-button-sweet',
                        denyButton: 'deny-button-sweet',
                    }
                }).then(() => {
                    closePopup();
                    fetchDataTask();
                }).finally(() => { setActiveBlur(false) });
            }
        } catch (err) {
            console.error(err);
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: 'There was a unexpected error, please contact support.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).then(() => {
                closePopup();
                fetchDataTask();
            }).finally(() => { setActiveBlur(false) });
        };
    }

    const isEmpty = (field) => {
        if (!field || field === '') {
            return true;
        }
        return false;
    }

    const isInvalidDateInitial = (date) => {
        if (date && minDateInitial && date < minDateInitial) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid start datetime!`, 'error');
            return true;
        }
        if (date && maxDateInitial && date > maxDateInitial) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid start datetime!`, 'error');
            return true;
        }
        return false;
    }

    const isInvalidDateEnd = (date) => {
        if (date && minDateEnd && date < minDateEnd) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid end datetime!`, 'error');
            return true;
        }
        if (date && maxDateEnd && date > maxDateEnd) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid end datetime!`, 'error');
            return true;
        }
        return false;
    }

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="full-task-modal">
                {
                    <>
                        <div className="modal-repeat-title">
                            <h2 className="modal-repeat-title-text">Create Event</h2>
                        </div>
                        <div className="type-field-task-repeat">
                            <div className="date-start-form">
                                <FormControl fullWidth >
                                    <DateTimePicker
                                        label="Start Datetime"
                                        value={dateInitial}
                                        onChange={handleChangeDateInitial}
                                        minDateTime={dayjs(minDateInitial)}
                                        maxDateTime={dayjs(maxDateInitial)}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />
                                </FormControl>
                                {isEmptyDateInitial && (
                                    <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                )}
                            </div>
                            <div className="date-end-form">
                                <FormControl fullWidth >
                                    <DateTimePicker
                                        label="End Datetime"
                                        value={dateEnd}
                                        onChange={handleChangeDateEnd}
                                        minDateTime={dayjs(minDateEnd)}
                                        maxDateTime={dayjs(maxDateEnd)}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />
                                </FormControl>
                                {isEmptyDateEnd && (
                                    <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                )}
                            </div>
                            <>
                                <div>
                                    <FormControl fullWidth >
                                        <TextField
                                            label="Title"
                                            type="text"
                                            value={titleEvent}
                                            onChange={handleChangeTitle}
                                            size="small"
                                        />
                                    </FormControl>
                                    {isEmptyTitle && (
                                        <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                    )}
                                </div>
                                <div>
                                    <FormControl fullWidth >
                                        <TextField
                                            label="Description"
                                            type="text"
                                            value={descriptionEvent}
                                            onChange={handleChangeDescription}
                                            size="small"
                                        />
                                    </FormControl>
                                    {isEmptyDescription && (
                                        <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                    )}
                                </div>
                            </>


                        </div>
                        <div className="modal-rate-button-container">
                            <Button
                                buttonClassName="modal-rate-button"
                                onClick={() => handleCreateRepeatedTask()}
                                children="Create Event"
                            />
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    )
}

export { PopupCreateEvent };