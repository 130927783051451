import React, { useState, useEffect, useContext, useRef } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import socket from "../../../utils/socket";
import { ContentHeader } from "../../../components/Shared/ContentHeader";
import { Button } from "../../../components/Shared/Button";
import { CardTasks } from "./CardTasks";
import { Popup } from "../../../components/Shared/Popup";
import { GlobalContext } from "../../../context/GlobalContext";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { PopupInfoTask } from "../../../components/Shared/tasks/PopupInfoTask";
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import "./AllTasks.css";
const MySwal = withReactContent(Swal);


function AllTasks() {
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [tasks, setTasks] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const SnackbarRef = useRef();

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";


    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }


    useEffect(() => {
        // Fetch unassigned tasks from the server
        fetchUnassignedTasks();
    }, []);

    async function takeTask(event,taskId, email) {
        event.stopPropagation();
        try {
            consumeFetch(`${API_BASE_URL}/tasks/takeTaskByAssistant`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    taskId: taskId,
                    email: email
                })
            }).then((res) => {
                if (res.ok) {
                    setTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
                    SnackbarRef.current.handleSnackbarOpen(`You took the task succesfully`, "success");
                }else{
                    SnackbarRef.current.handleSnackbarOpen(`Failed to take the task`, "error");
                    throw new Error("Failed to take the task");
                }
            }).catch((err) => {
                console.log(err);
                SnackbarRef.current.handleSnackbarOpen(`Failed to take the task`, "error");
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function fetchUnassignedTasks() {
        try {
            const response = await consumeFetch(`${API_BASE_URL}/tasks/unnasignedTasks`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setTasks(data.data);
            } else {
                throw new Error("Failed to fetch unassigned tasks");
            }
        } catch (error) {
            console.error(error);
        }
    }

    function openPopup(task) {
        setSelectedTask(task);
        setIsPopupOpen(true);
        setActiveBlur(true);
    }

    function closePopup() {
        setIsPopupOpen(false);
        setActiveBlur(false);
    }

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="tasks-header">
                <ContentHeader text="All Tasks" />
            </div>
            <span className="info-summary-text">Here you can find all tasks to be assigned</span>
            <div className="tasks-info-unassigned">
                <div className="unassigned-frame">
                    {tasks.map((task, index) => (
                        <div className="full-unassigned-task" key={index} onClick={() => openPopup(task)}>
                            <div className="unassigned-card-task">
                                <CardTasks
                                    userId = {task.clientId.id}
                                    owner={task.clientId.name}
                                    taskTitle={task.taskTitle}
                                    takeTask = {(e) => takeTask(e, task.id, emailGlobal)}
                                />
                            </div>
                        </div>
                    ))}
                    <Popup isOpen={isPopupOpen} onClose={closePopup} modalClass="popup-info-task">
                        {selectedTask && (
                            <PopupInfoTask selectedTask={selectedTask}/>
                        )}
                    </Popup>
                </div>
            </div>
        </React.Fragment>
    );
}

export { AllTasks };
