import React, { useContext, useState, useEffect } from "react";

import "./CardTasks.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { GlobalContext } from "../../../context/GlobalContext";
import { Button } from "../../../components/Shared/Button";
import { Select } from "../../../components/Shared/Select";
import { Box } from "@mui/material";
import { IoCreateOutline } from 'react-icons/io5';
import { SliderProgress } from "../../../components/Shared/ProgressBar";

function CardTasks(props) {

    const { userId, owner, taskTitle, isOwned, statuses, task, handleStatusChange, saveNewStatus, progressBar, selectId, takeTask } = props;
    const { selectedUserId, setSelectedUserId, setOwnerName } = useContext(GlobalContext);

    const [progress, setProgress] = useState(0);
    const [max, setMax] = useState(0);
    const [min, setMin] = useState(0);

    const handleNotesClick = (event) => {
        event.stopPropagation();
        setSelectedUserId(userId);
        setOwnerName(owner);
    }

    function timeToHours(tiempoStr) {
        if (!tiempoStr) {
            return 1;
        }
        var partes = tiempoStr.split(':');
        var horas = parseInt(partes[0]);
        var minutos = parseInt(partes[1]);
        var totalHoras = horas + minutos / 60;
        return totalHoras;
    }


    useEffect(() => {
        if (task) {
            setMax(timeToHours(task.hours));
            setProgress(task.timeHoursUsed);
        }
    }, [task]);

    useEffect(() => {
        if (selectId && progressBar) {
            if (selectId === task.id) {
                setProgress(progressBar);
            }
        }
    }, [progressBar])


    const normalise = (value) => ((value - min) * 100) / (max - min);

    return (
        <React.Fragment>
            <div className="card-task-my-tasks-assistant">
                <div className="full-content-card">
                    <div className="header-card-task">
                        <div className="card-task-title">
                            <h3>{taskTitle}</h3>
                        </div>
                        <div className="card-task-owner">
                            <h4>For:&nbsp; <span className="name-owner-task">{owner}</span> &nbsp;&nbsp; {isOwned === true ? <Link to="/user/Notes" className="icon-notes-user" onClick={(e) => handleNotesClick(e)}>{<IoCreateOutline />}</Link> : ""}</h4>
                        </div>
                    </div>
                    {task && <div className="counter-frame">
                        <div className="bar-timeout">
                            <Box sx={{ width: '100%', padding: '10px' }}>
                                <SliderProgress value={normalise(progress)} />
                            </Box>
                        </div>
                    </div>}
                </div>
                {task && <div className="full-change-status-task">
                    <p className="change-status-text">Change status task:</p>
                    <div className="change-status-form" onClick={(e) => e.stopPropagation()}>
                        <Select
                            className="change-status-select"
                            options={[
                                { value: "Active", label: "Active" },
                                { value: "In Progress", label: "In Progress" },
                                { value: "Done", label: "Done" },
                            ]}
                            value={statuses[task.id] || task.status_Id.status_name}
                            handleOnChange={(event) => handleStatusChange(event, task.id)}
                        />
                        <Button
                            buttonClassName="change-status-button"
                            children="Save"
                            onClick={(e) => saveNewStatus(e,task)}
                        />

                    </div>
                </div>
                }
                {takeTask && <div className=".full-take-task">
                    <Button
                        buttonClassName="take-task-button"
                        childrenClassName="take-task-button-text"
                        children="Take Task"
                        onClick={takeTask}
                    />
                </div>
                }
            </div>
        </React.Fragment>
    );
}

export { CardTasks };
