import React, { useContext, useEffect, useRef } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { GlobalContext } from '../../../context/GlobalContext';
const MySwal = withReactContent(Swal);

function ApproveTask() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const SnackbarRef = useRef();

    const searchParams = new URLSearchParams(useLocation().search);
    const paramIdTask = searchParams.get('idTaskToApprove');
    const paramEmail = searchParams.get('email');


    useEffect(() => {
        setActiveBlur(true);
        MySwal.fire({
            icon: 'info',
            title: `Approve task`,
            text: 'Do you want to approve the task?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes, I want to approve it',
            denyButtonText: `No, I want to approve with it`,
            allowOutsideClick: false,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let url = `${API_BASE_URL}/tasks/approveTask/${paramIdTask}`
                const response = await fetch(url, {
                    method: "PUT",
                    headers: {

                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify({
                        email: paramEmail
                    })
                })

                if (response.ok) {
                    setActiveBlur(true);
                    MySwal.fire({
                        icon: 'success',
                        title: 'success',
                        text: 'Task Approved',
                        showConfirmButton: false,
                        timer: 3000,
                        backdrop: true,
                        customClass: {
                            popup: 'popup-sweet',
                            title: 'title-sweet',
                            htmlContainer: 'text-sweet',
                            confirmButton: 'confirm-button-sweet',
                            denyButton: 'deny-button-sweet',
                        }
                    }).finally(() => {setActiveBlur(false)});
                    history.push('/');
                } else {
                    setActiveBlur(true);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops ...',
                        text: 'There was a unexpected error, please contact support.',
                        showConfirmButton: false,
                        timer: 1700,
                        backdrop: true,
                        customClass: {
                            popup: 'popup-sweet',
                            title: 'title-sweet',
                            htmlContainer: 'text-sweet',
                            confirmButton: 'confirm-button-sweet',
                            denyButton: 'deny-button-sweet',
                        }
                    }).finally(() => {setActiveBlur(false)});
                }
            } else {
                setActiveBlur(true);
                MySwal.fire({
                    icon: 'warning',
                    title: 'Attention',
                    text: 'Remember that you can approve the task from the Home section.',
                    showConfirmButton: false,
                    backdrop: true,
                    customClass: {
                        popup: 'popup-sweet',
                        title: 'title-sweet',
                        htmlContainer: 'text-sweet',
                        confirmButton: 'confirm-button-sweet',
                        denyButton: 'deny-button-sweet',
                    }
                }).finally(() => {setActiveBlur(false)});
                history.push('/');
            }
        }).finally(() => {setActiveBlur(false)});
    }, [])
    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
        </React.Fragment>
    )
}

export { ApproveTask };