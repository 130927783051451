import PeopleIcon from '@mui/icons-material/People';
import { FaTasks, FaFileInvoiceDollar } from 'react-icons/fa';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { AllClients } from '../pages/supportContent/clients';
import { ViewAllTasks } from '../components/Shared/tasks';
import { ViewAllInvoices } from '../pages/supportContent/invoices';

const supportMainOptions = [
    { name: 'Clients', component: <AllClients/>  ,icon: <PeopleIcon/>, show: true },
    { name: 'Task Summary', component: <ViewAllTasks/>  ,icon: <FaTasks />, show: true },
    { name: 'Invoices', component: <ViewAllInvoices/>  ,icon: <LiaFileInvoiceDollarSolid />, show: true }
];

export { supportMainOptions };