import React, { useEffect, useMemo, useState, useRef } from "react";
import { Controller } from "react-hook-form";
import { Header } from "../../../components/Shared/Header";
import { Button } from "../../../components/Shared/Button";
import { Select } from "../../../components/Shared/Select";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import rightArrow from '../../../assets/images/register-right-arrow.png'
import leftArrow from '../../../assets/images/register-left-arrow.png'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import DatePicker from "react-datepicker";
import 'react-phone-number-input/style.css'
import "react-datepicker/dist/react-datepicker.css";
import "./RegisterSteps.css"
import countryList from "react-select-country-list";

const ContactStep = (props) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { onNext, onBack, register, setValue, control, errors, clearErrors, getValues } = props;
    const SnackbarRef = useRef();

    const [gender, setGender] = useState("");
    const [country, setCountry] = useState(getValues("country"));
    const [startDate, setStartDate] = useState(getValues("birthDate"));
    const [countryPhone, setCountryPhone] = useState("US");
    const [countryPhoneContry, setCountryPhoneCountry] = useState("US");


    const genderOptions = [
        { value: "", label: "Select a gender" },
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Other", label: "Other" }
    ];


    let countryOptions = useMemo(() => countryList().getData(), []);
    countryOptions = [{ value: "", label: "Select a country*" }, ...countryOptions]

    //Fecha hace 18 años, para fecha de nacimiento
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setValue("gender", event.target.value);

    };

    const handleCountryChange = (event) => {
        if (event.target.value !== "") {
            setCountryPhoneCountry(event.target.value)
            console.log('c   ', countryPhoneContry)
            setCountry(event.target.value);
            setValue("country", event.target.value);
            clearErrors("country");
        } else {
            setCountry(event.target.value);
            setValue("country", event.target.value);
        }
    };

    const handleDateChange = (date) => {
        if (date !== null) {
            setStartDate(date);
            setValue("birthDate", date);
            clearErrors("birthDate");
        } else {
            setStartDate(date);
            setValue("birthDate", date); 
        }
    };
    
    const validatePhone = (value) => {

        const selectedCountry = countryPhone;

        if (!value) {
            return 'Phone is required';
        }
        
        if (selectedCountry == "US") {
            if (value.length !== 12) {
                return 'Your phone number must be a valid 10-digit number';
            }
        }

        return true;
    };

    const validateNextPhone = async () => {
        
        if(getValues("phone")){
            const response = await fetch(`${API_BASE_URL}/users/existPhone/${getValues("phone")}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status == '404') {
                onNext();
                return;
            }
            if (response.ok) {
                SnackbarRef.current.handleSnackbarOpen('This phone already exists in Assisttu', 'warning');
            } else {
                SnackbarRef.current.handleSnackbarOpen('An error has occurred verifying phone, please contact support!', 'error');
            }

        }
    }

    const handleCountryChangePhone = (newCountry) => {
        console.log(newCountry)
        setCountryPhone(newCountry);
      };

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="form-frame">
                <div className="frame-signup">
                    <div className="section-header">
                        <Header level={2} headerClassName="subtitle" children="Contact Details" />
                    </div>
                    <div className="section-wrap">
                        <div>
                            <div>
                                <input
                                    className={`contact-input ${errors.name && 'error'}`}
                                    placeholder="Name*"
                                    type="text"
                                    {...register("name", { 
                                        required: "Name is required", 
                                        pattern: {
                                            value: /^.{2,}$/,
                                            message: "Your name must be at least 2 characters long."
                                        }
                                    })} />
                                {errors.name && <span className="error-message">{errors.name.message}</span>}
                            </div>
                            <div>
                                <input
                                    className={`contact-input ${errors.lastname && 'error'}`}
                                    placeholder="Last Name*"
                                    type="text"
                                    {...register("lastname", { 
                                        required: "Last Name is required",
                                        pattern: {
                                            value: /^.{2,}$/,
                                            message: "Your lastname must be at least 2 characters long."
                                        }
                                    })} />
                                {errors.lastname && <span className="error-message">{errors.lastname.message}</span>}
                            </div>
                        </div>
                        <div>
                            <div>
                                <Controller
                                    name="birthDate"
                                    control={control}
                                    rules={{ required: "Birth date is required" }}
                                    render={() => (
                                        <DatePicker
                                            className={`contact-input ${errors.birthDate && 'error'}`}
                                            name="birthDate"
                                            placeholderText="Birth date*"
                                            selected={startDate}
                                            dateFormat="MM/dd/yyyy"
                                            maxDate={eighteenYearsAgo}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onChange={handleDateChange} />
                                    )}
                                />
                                {errors.birthDate && <span className="error-message">{errors.birthDate.message}</span>}

                            </div>
                            <div>
                                <input
                                    className="contact-input"
                                    placeholder="Company"
                                    type="text"
                                    {...register("company")} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <Select {...register("country", { required: "Country is required" })}
                                    options={countryOptions}
                                    value={country}
                                    className={`contact-input select-input ${country !== "" ? 'select-input-choosen' : ''} ${errors.country ? 'error' : ""}`}
                                    handleOnChange={handleCountryChange}
                                />
                                {errors.country && <span className="error-message">{errors.country.message}</span>}
                            </div>
                            <div>
                                <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue=""
                                    rules={{ validate: validatePhone }}
                                    
                                    render={() => (
                                        <PhoneInputWithCountry
                                            className={`contact-input ${errors.phone && 'error'}`}
                                            placeholder="Phone*"
                                            defaultCountry={countryPhoneContry}
                                            name="phone"
                                            control={control}
                                            onCountryChange={handleCountryChangePhone}
                                            />
                                    )}
                                />
                                {errors.phone && <span className="error-message">{errors.phone.message}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buttons-form interests">
                    <Button buttonClassName="button-signup previous"
                        onClick={() => { onBack(); }}
                        children="Go back"
                        image={leftArrow}
                        imgClassName="left-arrow-register"
                        imageAlt="Back Arrow"
                        imageSide="left"
                        three={true}
                    />
                    <Button type="submit"
                        buttonClassName="button-signup next"
                        onClick={validateNextPhone}
                        children="Next"
                        image={rightArrow}
                        imgClassName="right-arrow-register"
                        imageAlt="Next Arrow"
                        imageSide="right"
                        three={true}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export { ContactStep };
