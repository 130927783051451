import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../../context/GlobalContext";
import { ProfileButton } from './ProfileButton';
import './AssistantBox.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import ApiMiddleware from "../../../../components/Shared/ApiMiddleware";

export const AssistantBox = (props) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, idUserGlobal, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [rows, setRows] = useState([]);


    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)

                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }




    const fetchData = async () => {
        const params = new URLSearchParams();
        let endpoint = `${API_BASE_URL}/preferredAssistants/getActiveAssistants/${idUserGlobal}`;
        try {
            const response = await consumeFetch(endpoint, {
                headers: {
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            const data = await response.json();
            setRows(data.data)
            console.log(rows)
        } catch (error) {
            console.error(error);
            // Manejar el error
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const { asistente, bgColor } = props;
    return (
        <div className='assistant-content'>
            {rows?.length > 0 && rows?.map((option, i) => {

                return (
                    <>
                        <div className='assistant-box'>
                            <ProfileButton
                                profilePicture={option.assistantId.avatar === null ? 'https://i.pinimg.com/280x280_RS/2e/45/66/2e4566fd829bcf9eb11ccdb5f252b02f.jpg': option.assistantId.avatar}
                            />
                            <p className='assistant-box-text'>{option.assistantId.name}</p>
                        </div>
                    </>
                );
            })}
        </div>
    )
}
