import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'js-cookie';
import socket from "../../../../utils/socket";
import { GlobalContext } from '../../../../context/GlobalContext';
import { ChatList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import './chat.css';
import { Message_List } from '../message_list';
import ApiMiddleware from "../../../Shared/ApiMiddleware";
import noMessagesImg from '../../../../assets/images/no-messages.png'
import { Button } from '../../../Shared/Button';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { IoIosResize } from 'react-icons/io';

const MySwal = withReactContent(Swal);

function Chat(props) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { isSupport } = props;
    const [error, setError] = useState(false);
    const { setActivePage, setShowBadge, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName, userRole,  anchorEl, setAnchorEl  } = useContext(GlobalContext)
    const history = useHistory();
    const [dataChats, setDataChats] = useState([]);
    const [dataChatsSupport, setDataChatsSupport] = useState([]);
    const [dataChatsAdmin, setDataChatsAdmin] = useState([]);

    const [estadoModal, setEstadoModal] = useState(true);
    const [taskId, setTaskId] = useState();
    const [receiverId, setReceiverId] = useState();
    const [chatState, setChatState] = useState();
    const [user1Id, setUser1Id] = useState();
    const [user2Id, setUser2Id] = useState();
    const [chatKind, setChatKind] = useState();
    const url = `${API_BASE_URL}/messages/${idUserGlobal}`;
    const urlAdmin = `${API_BASE_URL}/admin_chat/messagesByUser/${idUserGlobal}`;
    const urlSupport = `${API_BASE_URL}/support_chat/messagesByUser/${idUserGlobal}`;


    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const fetchData = async () => {
        consumeFetch(url, {
            headers: {
                Authorization: `${token}`
            }
        }
        )
            .then(response => {
                return response.json();
            })
            .then(data => {
                const newRows = data.map((element, index) => {
                    let subtitle = "There´s no messages yet."
                    if (element.messages[0] != null) {
                        subtitle = element.messages[0].from === idUserGlobal ? `You: ${element.messages[0].text}` : element.messages[0].text;
                    }
                    let obj = {
                        avatar: 'https://avatars.githubusercontent.com/u/80540635?v=4',
                        alt: 'kursat_avatar',
                        title: element.client_id === idUserGlobal ? `${element.assistant_username} - ${element.task_title}` : `${element.client_username} - Task Name`,
                        subtitle: subtitle,
                        date: element.messages[0] === null ? new Date() : new Date(element.messages[0].timestamp),
                        unread: 0,
                        custom_task_id: element.task_id,
                        custom_receiver_id: element.client_id === idUserGlobal ? element.assistant_id : element.client_id,
                        custom_state_id: element.state
                    };
                    return obj;
                });
                setDataChats(newRows);
                console.log(newRows)
            })
            .catch(error => {
                console.error(error)
                setError(true);
            });
    }

    const fetchDataSupport = async () => {
        consumeFetch(urlSupport, {
            headers: {
                Authorization: `${token}`
            }
        }
        )
            .then(response => {
                return response.json();
            })
            .then(data => {
                const newRows = data.map((element, index) => {
                    let subtitle = "There´s no messages yet."
                    if (element.messages[0] != null) {
                        subtitle = element.messages[0].from === idUserGlobal ? `You: ${element.messages[0].text}` : element.messages[0].text;
                    }
                    let obj = {
                        avatar: 'https://avatars.githubusercontent.com/u/80540635?v=4',
                        alt: 'kursat_avatar',
                        title: element.user1_id === idUserGlobal ? element.user2_username : 'Assisttu Support',
                        subtitle: subtitle,
                        date: element.messages[0] === null ? new Date() : new Date(element.messages[0].timestamp),
                        unread: 0,
                        user1_id: element.user1_id,
                        user2_id: element.user2_id,
                    };
                    return obj;
                });
                setDataChatsSupport(newRows);
            })
            .catch(error => {
                console.error(error)
                setError(true);
            });
    }

    const fetchDataAdmin = async () => {
        consumeFetch(urlAdmin, {
            headers: {
                Authorization: `${token}`
            }
        }
        )
            .then(response => {
                return response.json();
            })
            .then(data => {
                const newRows = data.map((element, index) => {
                    let subtitle = "There´s no messages yet."
                    if (element.messages[0] != null) {
                        subtitle = element.messages[0].from === idUserGlobal ? `You: ${element.messages[0].text}` : element.messages[0].text;
                    }
                    let obj = {
                        avatar: 'https://avatars.githubusercontent.com/u/80540635?v=4',
                        alt: 'kursat_avatar',
                        title: element.user1_id === idUserGlobal ? element.user2_username : 'Assisttu Admin',
                        subtitle: subtitle,
                        date: element.messages[0] === null ? new Date() : new Date(element.messages[0].timestamp),
                        unread: 0,
                        user1_id: element.user1_id,
                        user2_id: element.user2_id,
                    };
                    return obj;
                });
                setDataChatsAdmin(newRows);
            })
            .catch(error => {
                console.error(error)
                setError(true);
            });
    }

    useEffect(() => {
        if (userRole == 'Admin') {
            fetchDataAdmin();
        } else if (userRole == 'Support' || isSupport) {
            fetchDataSupport();
        } else {
            fetchData();
            fetchDataAdmin();
        }
        socket.on("private message list", ({ content, from, to }) => {
            fetchData();
        });

        socket.on("Admin Message list", ({ content, from, to }) => {
            fetchDataAdmin();
        });

        return () => {
            socket.off("private message list");
            socket.off("Admin Message list");
        }
    }, []);

    const handleClick = (event) => {
        setEstadoModal(false);
        setShowBadge(true);
        setTaskId(event.custom_task_id);
        setReceiverId(event.custom_receiver_id);
        setChatState(event.custom_state_id);
        setChatKind('Client');
    };

    const handleClickSupport = (event) => {
        setEstadoModal(false);
        setShowBadge(true);
        setUser1Id(event.user1_id);
        setUser2Id(event.user2_id);
        setChatKind('Support');
    };

    const handleClickAdmin = (event) => {
        setEstadoModal(false);
        setShowBadge(true);
        setUser1Id(event.user1_id);
        setUser2Id(event.user2_id);
        setChatKind('Admin');
    };

    const closeModal = () => {
        setAnchorEl(false);
    }

    return (
        <div className="ModalChat">

            {(dataChats && estadoModal) ?
                <>
                    <Link to="/user/MessagesWindow" onClick={()=>closeModal()}>
                        <IoIosResize
                            className='open_window'
                            color='#A4A4A4'

                        />
                    </Link>
                    {((userRole == 'Client' || userRole == 'Assistant') && isSupport!==true) &&
                        <>
                            <p className='support-spreader'>Assisttu Messages</p>
                            <ChatList
                                className='chat-list'
                                onClick={handleClick}
                                dataSource={dataChats}
                            />
                        </>
                    }
                    {((userRole == 'Client' || userRole == 'Admin') && isSupport!==true) &&
                        <>
                            <p className='support-spreader'>PM Messages</p>
                            <ChatList
                                className='chat-list'
                                onClick={handleClickAdmin}
                                dataSource={dataChatsAdmin}
                            />
                        </>
                    }
                    {userRole == 'Support' || isSupport === true &&
                        <>
                            <p className='support-spreader'>Support Messages</p>
                            <ChatList
                                className='chat-list'
                                onClick={handleClickSupport}
                                dataSource={dataChatsSupport}
                            />
                        </>
                    }
                </>

                :
                <Message_List estadoModal={estadoModal} setEstadoModal={setEstadoModal} taskId={taskId} receiverId={receiverId} chatState={chatState} fetchList={fetchData} user1Id={user1Id} user2Id={user2Id} chatKind={chatKind} />
            }

        </div>
    );
}

export { Chat };
