import React, { useState } from "react";
import { Button } from "../../../components/Shared/Button";
import "./ContractCard.css"
import { AiFillCheckCircle } from "react-icons/ai";

function ContractCardPackage(props) {

    const { cardTitle, subdescription, featuresList, handleSubmit, bgColor, description, boldDescription, subDescription } = props;

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <React.Fragment>
            <div className="full-card-pricing" style={{ background: bgColor }}>
                <div className="full-price-info">
                    <div className="plan-header-container">
                        <span className="card-contract-title">{cardTitle}</span>
                        <div className="feature-text">{description}</div>
                    </div>
                    <div className="features-list-container">
                        <div className="features-list">
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Administrative.</div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Marketing.</div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Sales.</div>
                            </div>
                        </div>
                        <div className="features-list">
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Customer Support.</div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Finance Work.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="feature-text" >{subDescription}</div>
                <div className="feature-text">
                    <label>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        I agree to the {cardTitle} <a href="https://assisttu.com/"><u>Terms and Conditions</u></a>.
                    </label>
                </div>
                <div className="button-subscribe-container">
                    <div className="package-row">
                        <div className="price-subdescription-package">
                            <div className="feature-text"><b>2 task, </b>Required to be used in 1 month</div>
                            <Button
                                type="submit"
                                onClick={event => handleSubmit(event, 60, "Task Package 2", "Virtual Assistant")}
                                buttonClassName="button-subscribe-package"
                                children="$60"
                                inactive={!isChecked}
                            />
                        </div>
                        <div className="price-subdescription-package">
                            <div className="feature-text"><b>4 task, </b>Required to be used in 2 month</div>
                            <Button
                                type="submit"
                                onClick={event => handleSubmit(event, 115, "Task Package 4", "Virtual Assistant")}
                                buttonClassName="button-subscribe-package"
                                children="$115"
                                inactive={!isChecked}
                            />
                        </div>
                    </div>
                    <div className="package-row">
                        <div className="price-subdescription-package">
                            <div className="feature-text"><b>8 task, </b>Required to be used in 8 month</div>
                            <Button
                                type="submit"
                                onClick={event => handleSubmit(event, 225, "Task Package 8", "Virtual Assistant")}
                                buttonClassName="button-subscribe-package"
                                children="$225"
                                inactive={!isChecked}
                            />
                        </div>
                        <div className="price-subdescription-package">
                            <div className="feature-text"><b>12 task, </b>Required to be used in 12 month</div>
                            <Button
                                type="submit"
                                onClick={event => handleSubmit(event, 330, "Task Package 12", "Virtual Assistant")}
                                buttonClassName="button-subscribe-package"
                                children="$330"
                                inactive={!isChecked}
                            />
                        </div>
                    </div>
                </div>
                {boldDescription?.map((item, index) => {
                    return (
                        <div className="feature-text-bold" >{item}</div>
                    );
                })}

            </div>
        </React.Fragment>
    );
}

export { ContractCardPackage };
