import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { Button } from "../../../components/Shared/Button";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './NotesPage.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import InputAdornment from '@mui/material/InputAdornment';
import { ModalDialog } from '../../../components/Shared/ModalForm';
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiSolidEdit } from 'react-icons/bi';

const MySwal = withReactContent(Swal);

function NotesPage() {
    const [notesList, setNotesList] = useState([]);
    const [notesListSearch, setNotesListSearch] = useState([]);
    const [isSerching, setsISerching] = useState(false);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setToken, token, setEmailGlobal, setIdUserGlobal, setUserName, selectedUserId, ownerName, setActiveBlur } = useContext(GlobalContext);
    const ModalDialogRef = useRef();
    const SnackbarRef = useRef();
    const history = useHistory();
    const [inputSearchValue, setInputSearchValue] = useState('');

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const compare = (a, b) => {
        if (a.pinned && !b.pinned) {
            return -1;
        }
        if (!a.pinned && b.pinned) {
            return 1;
        }
        if (a.date > b.date) {
            return -1;
        }
        if (a.date < b.date) {
            return 1;
        }
        return 0;
    };

    const handleAddNote = async (pModel) => {
        console.log(pModel);
        consumeFetch(`${API_BASE_URL}/user_notes`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify({
                user_id: selectedUserId,
                title: pModel.title_note,
                note: pModel.note
            })
        }).then(response => response.json()).then(data => {
            SnackbarRef.current.handleSnackbarOpen('Note saved successfully', 'success');
            fetchData();
        }).catch((err) => {
            console.error(err);
            SnackbarRef.current.handleSnackbarOpen('There was an error, contact support.', 'error');
        });
    }

    const handleUpdateNote = async (pModel) => {
        consumeFetch(`${API_BASE_URL}/user_notes`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify({
                newTitle: pModel.title_note,
                newNote: pModel.note,
                note_id: pModel.id,
            })
        }).then(response => response.json()).then(data => {
            SnackbarRef.current.handleSnackbarOpen('Note Updated', 'success');
            fetchData();
        }).catch((err) => {
            console.error(err);
            SnackbarRef.current.handleSnackbarOpen('There was an error, contact support.', 'error');
        });
    }

    const columns = [
        { field: 'title_note', headerName: 'Title', alignItems: 'center', width: 300 },
        { field: 'note', headerName: 'Note', alignItems: 'center', width: 300 },
        { field: 'id', headerName: 'Id', alignItems: 'center', width: 300 }
    ];

    const openModal = (pAction) => {
        ModalDialogRef.current.setTitle(pAction);
        ModalDialogRef.current.handleOpen();
    };

    const handleCreateClick = async () => {
        const modifiedColumns = columns.map(column => {
            if (column.field === 'id') {
                return null;
            }
            // Conservar las demás propiedades
            return { ...column };
        }).filter(Boolean); // Filtrar los elementos nulos
        const initialModel = modifiedColumns.map(column => {
            return { ...column, value: '' };
        });
        ModalDialogRef.current.setModel(initialModel);
        ModalDialogRef.current.setTypeAction('create');
        openModal("Create Note");
    }

    const handleUpdateClick = async (id, title, note) => {
        const modifiedColumns = columns.map(column => {
            if (column.field === 'note') {
                column.value = `${note}`;
                return column;
            }
            if (column.field === 'title_note') {
                column.value = `${title}`;
                return column;
            }
            if (column.field === 'id') {
                column.value = `${id}`;
                return column;
            }
            return { ...column };
        }).filter(Boolean);// Filtrar los elementos nulos
        ModalDialogRef.current.setTypeAction('edit');
        ModalDialogRef.current.setModel(modifiedColumns.map(column => {
            return { ...column };
        }));
        openModal("Update Note");
    }

    const handleDeleteClick = (note_id) => {
        setActiveBlur(true);
        MySwal.fire({
            icon: 'warning',
            title: `Are you Sure?`,
            text: 'Do you want to delete this note ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes, I want to delete it.',
            denyButtonText: `No, I want to keep it`,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        }).then((result) => {
            const endpoint = `${API_BASE_URL}/user_notes`;
            const params = new URLSearchParams();
            params.append('note_id', note_id);
            const url = `${endpoint}/${note_id}`;
            if (result.isConfirmed) {
                consumeFetch(url, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${token}`
                    }
                }
                ).then(response => response.json()).then(data => {
                    if (data.status === "success") {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Note Deleted.',
                            showConfirmButton: false,
                            timer: 1700,
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => {setActiveBlur(false)});
                        fetchData();
                    } else {
                        throw new Error("Error on api call");
                    }
                }).catch(error => {
                    setActiveBlur(true);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops ...',
                        text: 'There was a unexpected error, please contact support.',
                        showConfirmButton: false,
                        timer: 1700,
                        backdrop: true,
                        customClass: {
                            popup: 'popup-sweet',
                            title: 'title-sweet',
                            htmlContainer: 'text-sweet',
                            confirmButton: 'confirm-button-sweet',
                            denyButton: 'deny-button-sweet',
                        }
                    }).finally(() => {setActiveBlur(false)});
                });
            }
        }).finally(() => {setActiveBlur(false)});
    };

    const fetchData = async () => {
        let endpoint = `${API_BASE_URL}/user_notes`;
        const url = `${endpoint}/${selectedUserId}`;
        try {
            const response = await consumeFetch(url, {
                headers: {
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            const data = await response.json();

            const newRows = data.data.map((item, index) => {
                const dateObject = new Date(item.created_at);

                // Get the components of the date (year, month, day)
                const year = dateObject.getFullYear();
                const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
                const day = String(dateObject.getDate()).padStart(2, "0");

                // Create the new date string in "DD/MM/YYYY" format
                const newDateString = `${day}/${month}/${year}`;
                return {
                    id: index,
                    user_id: item.user_id,
                    note_id: item.id,
                    title: item.title,
                    note: item.note,
                    date: newDateString,
                    pinned: item.pinned,
                }
            });
            newRows.sort(compare);
            setNotesList(newRows);
        } catch (error) {
            console.error(error);
            // Manejar el error
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setInputSearchValue(event.target.value);
        if(inputSearchValue.length>1){
            setsISerching(true)
            const notesListUpdt = notesList.filter((note) => note.title.includes(inputSearchValue));

            notesListUpdt.sort(compare);
            setNotesListSearch(notesListUpdt)
        }else{
            setsISerching(false);
        }


    };

    const handlePinClick = async (note_id) => {
        const notesListUpdt = await notesList.map(option => {
            if (option.note_id === note_id) {
                option.pinned = !option.pinned;
                consumeFetch(`${API_BASE_URL}/user_notes/pinNote`, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${token}`
                    },
                    body: JSON.stringify({
                        note_id: note_id,
                        pinned: option.pinned
                    })
                }).then(response => response.json()).then(data => {
                    // MySwal.fire({
                    //     icon: 'success',
                    //     title: 'Success',
                    //     text: 'Note Pinned.',
                    //     showConfirmButton: false,
                    //     timer: 1700
                    // });
                }).catch((err) => {
                    setActiveBlur(true);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops ...',
                        text: 'There was a unexpected error, please contact support.',
                        showConfirmButton: false,
                        timer: 1700,
                        backdrop: true,
                        customClass: {
                            popup: 'popup-sweet',
                            title: 'title-sweet',
                            htmlContainer: 'text-sweet',
                            confirmButton: 'confirm-button-sweet',
                            denyButton: 'deny-button-sweet',
                        }
                    }).finally(() => {setActiveBlur(false)});
                });
            }
            return option;
        });
        notesListUpdt.sort(compare);
        setNotesList(notesListUpdt)
    }

    return (
        <React.Fragment>
            <ModalDialog
                open={ModalDialogRef.open}
                handleClose={ModalDialogRef.handleClose}
                ref={ModalDialogRef}
                onUpdateItem={handleUpdateNote}
                onAddItem={handleAddNote}
            />
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="notes-general-container">
                <p className="note-title">Notes for {ownerName}</p>
                <div className="add-note-button-container">
                    <OutlinedInput endAdornment={
                    <InputAdornment position="start">
                        <AiOutlineSearch />
                    </InputAdornment>
                    } className="inputSearch" type="text" placeholder="Search" value={inputSearchValue} onChange={handleSearchChange} />
                    <Button
                        buttonClassName="notes-add-button-client"
                        onClick={handleCreateClick}
                        children="Add Note"
                    />
                </div>
                <div className="list-notes">
                    {notesList.length > 0 ?
                        <List sx={{ width: '100%' }}>
                            {isSerching === false ? notesList.map((option) => {
                                const labelId = `checkbox-list-label-${option}`;
                                return (
                                    <div key={option.id}>
                                        <ListItem disablePadding className={`note-item ${option.pinned ? "pinned-item" : ""}`}>
                                            <ListItemIcon>
                                                <IconButton edge="end" aria-label="delete" onClick={() => { handlePinClick(option.note_id); }}>
                                                    <PushPinIcon className="pin-icon"/>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={option.title} secondary={option.note} primaryTypographyProps={{color: '#004752'}}/>
                                            <ListItemIcon>
                                                <IconButton edge="end" aria-label="delete" onClick={() => { handleUpdateClick (option.note_id, option.title, option.note); }}>
                                                    <BiSolidEdit className="other-icon"/>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemIcon>
                                                <IconButton edge="end" aria-label="delete" onClick={() => { handleDeleteClick(option.note_id); }}>
                                                    <DeleteIcon className="other-icon"/>
                                                </IconButton>
                                            </ListItemIcon>
                                        </ListItem>
                                    </div>
                                );
                            }):notesListSearch.map((option) => {
                                const labelId = `checkbox-list-label-${option}`;
                                return (
                                    <div key={option.id}>
                                        <ListItem disablePadding className={`note-item ${option.pinned ? "pinned-item" : ""}`}>
                                            <ListItemIcon>
                                                <IconButton edge="end" aria-label="delete" onClick={() => { handlePinClick(option.note_id); }}>
                                                    <PushPinIcon className="pin-icon"/>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={option.title} secondary={option.note} />
                                            <ListItemIcon>
                                                <IconButton edge="end" aria-label="delete" onClick={() => { handleUpdateClick (option.note_id, option.title, option.note); }}>
                                                    <BiSolidEdit className="other-icon"/>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemIcon>
                                                <IconButton edge="end" aria-label="delete" onClick={() => { handleDeleteClick(option.note_id); }}>
                                                    <DeleteIcon className="other-icon"/>
                                                </IconButton>
                                            </ListItemIcon>
                                        </ListItem>
                                    </div>
                                );
                            })}
                        </List>
                    :
                        <Box sx={{ width: '100%' }} style={{ display: 'flex', justifyContent: 'center', margin: '17px' }}>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                This client doesn´t have any note
                            </Typography>
                        </Box>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export { NotesPage };
