import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../../context/GlobalContext";
import { CustomizedSnackbar } from '../../../../components/Shared/Snackbar';
import ApiMiddleware from "../../../../components/Shared/ApiMiddleware";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import '../home.css';


function WidgetTasksToApproved() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName } = useContext(GlobalContext)
    const history = useHistory();
    const SnackbarRef = useRef();
    const [tasksToApprove, setTasksToApprove] = useState();

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)

                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect(() => {
        async function fetchData() {
            const params = new URLSearchParams();
            let endpoint = `${API_BASE_URL}/tasks/tasksByClient`;
            params.append('email', emailGlobal);
            params.append('approved', false);
            const url = `${endpoint}?${params}`;
            try {
                const response = await consumeFetch(url, {
                    headers: {
                        Authorization: `${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Error en la solicitud');
                }

                const data = await response.json();
                if (data.data.length !== 0) {
                    setTasksToApprove(data.data);
                }
            } catch (error) {
                console.error(error);
                // Manejar el error
            }

        }
        fetchData();
    }, []);

    const approveTask = async (idTask) => {
        let url = `${API_BASE_URL}/tasks/approveTask/${idTask}`
        const response = await consumeFetch(url, {
            method: "PUT",
            headers: {
                Authorization: `${token}`,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                email: emailGlobal
            })
        })

        if (response.ok) {
            SnackbarRef.current.handleSnackbarOpen('Task Approved Succesfully!', 'success');
            const newTasksToApprove = tasksToApprove.filter(item => item.id !== idTask);
            setTasksToApprove(newTasksToApprove);
        } else {
            SnackbarRef.current.handleSnackbarOpen('An error has occurred', 'error');
        }
    }

    function timeToHours(tiempoStr) {
        if (!tiempoStr) {
            return 1;
        }
        var partes = tiempoStr.split(':');
        var horas = parseInt(partes[0]);
        var minutos = parseInt(partes[1]);
        var totalHoras = horas + minutos / 60;
        return totalHoras;
    }

    const formatDate = (date) => {
        var dateLimit = new Date(date);
        var userTimezoneOffset = dateLimit.getTimezoneOffset() * 60000;
        dateLimit = new Date(dateLimit.getTime() + userTimezoneOffset);
        const year = dateLimit.getFullYear();
        const month = dateLimit.getMonth() + 1;
        const day = dateLimit.getDate();

        return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;

    }

    return (
        <React.Fragment className="text-tasks-to-approve">
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            {tasksToApprove ? (
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {tasksToApprove.map((item, index) => {
                        return (
                            <>
                                <ListItem className="text-tasks-to-approve" alignItems="flex-start" key={index}
                                >
                                    <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#FF5C34' }}>{item.taskTitle.charAt(0).toUpperCase()}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText

                                        primary={`${item.taskTitle} - ${timeToHours(item.hours)} hours`}
                                        secondary={
                                            <React.Fragment>
                                                <span className='assistant-name-taskToApprove'>{item.assistantId.name}</span>
                                                {`, ${item.description}`}
                                                <br /><span className='date-limit-taskToApprove align-right'>
                                                    {`Deadline - ${formatDate(item.date_limit)}`}
                                                </span>
                                            </React.Fragment>
                                        }
                                    />
                                    <Tooltip title="Approved">
                                        <IconButton edge="end" aria-label="approved" onClick={() => approveTask(item.id)}>
                                            <CheckIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItem>

                                {tasksToApprove.length > 0 && <Divider variant="inset" component="li" />}
                            </>
                        )
                    })}
                </List>
            ) : (
                <Box sx={{ width: '100%' }} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        You don´t have any pending tasks.
                    </Typography>
                </Box>
            )}
        </React.Fragment >
    )
}

export { WidgetTasksToApproved }