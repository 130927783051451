import React, { useState, useEffect, useContext } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { Button } from "../../../components/Shared/Button";
import "./DownloadInvoice.css";
import logo from '../../../assets/images/logos/logo-assisttu-petroleum-2.png';

import ApiMiddleware from '../../../components/Shared/ApiMiddleware';

export const DownloadInvoice = (props) => {
    const { selectedTask } = props;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const history = useHistory();
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, userName, address } = useContext(GlobalContext)
  
    const [userData, setUserData] = useState("");
    const [error, setError] = useState(false);
    
  const consumeFetch = async (url, options) => {
    try {
      const originalFetch = fetch;
      const fetchWithMiddleware = ApiMiddleware(originalFetch);
      const { response, token } = await fetchWithMiddleware(url, options);
      // Se detecta token nuevo
      if (token) {
        setToken(token)
      }
      return await response;
    } catch (error) {
      if (error.message === "RefreshToken Vencido") {
        console.log(error.message,error)
        setToken("");
        setEmailGlobal("");
        setIdUserGlobal("");
        setUserName("");
        history.push('/');
      }
    }

  }
  
    useEffect(() => {
        consumeFetch(`${API_BASE_URL}/users/${emailGlobal}`, {
          headers: {
            Authorization: `${token}`
          }
        }).then(response => {
          return response.json();
        }).then(data => {
          setUserData(data);
        }).catch(error => {
          console.error(error)
          setError(true);
        });
      }, []);

      const generatePDF = () => {
        const input = document.getElementById('pdf-content');
    
        html2canvas(input).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 180; // Adjust as needed
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const margin = 10; // Set your desired margin value
    
          let heightLeft = imgHeight;
          let position = margin;
          const pageWidth = pdf.internal.pageSize.getWidth(); // Get page width
    
          pdf.addImage(imgData, 'PNG', pageWidth - imgWidth - margin, position, imgWidth, imgHeight);
          heightLeft -= imgHeight;
    
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight + margin;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', pageWidth - imgWidth - margin, position, imgWidth, imgHeight);
            heightLeft -= imgHeight;
          }
    
          pdf.save('download.pdf');
        });
      };

    return (
        <div className="download-invoice-content" >
            <div  className="content-body" id="pdf-content">
                {/* Your content to be converted to PDF */}
                <header className='header-download-invoice'>

                    <h1 className='h1-download-invoice'> INVOICE </h1>
                    <address className='address-download-invoice'> 
                        <p className='p-download-invoice'> {userData.name} </p>
                        <p className='p-download-invoice'> {userData.company} </p>
                        <p className='p-download-invoice'> {emailGlobal} </p>
                        <p className='p-download-invoice'> {userData.phone} </p>
                    </address>

                    <span className='span-download-invoice'>
                        <img
                            className='NavbarLogo img-download-invoice'
                            src={logo}
                            alt='Assisttu Logo'
                        />
                    </span>

                </header >
                <body>
                    <article className='article-download-invoice'>
                        <address className='address-download-invoice'>
                            <p className='p-download-invoice'> Assisttu Plan </p>
                        </address>

                        <table className="firstTable table-download-invoice">
                            <tr className='tr-download-invoice'>
                                <th className='th-download-invoice'><span className='span-download-invoice'>Invoice #</span></th>
                                <td lassName='th-download-invoice'><span className='span-download-invoice'>{selectedTask.stripeId}</span></td>
                            </tr>
                            <tr className='tr-download-invoice'>
                                <th className='th-download-invoice'><span className='span-download-invoice'>Date</span></th>
                                <td className='td-download-invoice'><span className='span-download-invoice'>{selectedTask.created_at}</span></td>
                            </tr>
                            <tr className='tr-download-invoice'>
                                <th className='th-download-invoice'><span className='span-download-invoice'>Amount Due</span></th>
                                <td className='td-download-invoice'><span id="prefix" className='span-download-invoice'>$</span><span className='span-download-invoice'>{selectedTask.amount}</span></td>
                            </tr>
                        </table>

                        <table className="secondTable table-download-invoice">
                            <thead className="thead-download-invoice">
                                <tr className="tr-download-invoice">
                                    <th className="th-download-invoice"><span className='span-download-invoice'>Item</span></th>
                                    <th className="th-download-invoice"><span className='span-download-invoice'>Description</span></th>
                                    <th className="th-download-invoice"><span className='span-download-invoice'>Price</span></th>
                                </tr>
                            </thead>
                            <tbody className="tbody-download-invoice">
                                <tr className="tr-download-invoice">
                                    <td className="td-download-invoice"><a class="cut" className="a-download-invoice">-</a><span >Monthly Plan</span></td>
                                    <td className="td-download-invoice"><span className="span-download-invoice">Virtual Assistant - Hours Available: 160 hours 0 minutes</span></td>
                                    <td className="td-download-invoice"><span data-prefix className="span-download-invoice">$</span><span>{selectedTask.amount}</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="firstTable table-download-invoice">
                            <tr className="tr-download-invoice">
                                <th className="th-download-invoice"><span className="span-download-invoice">Total</span></th>
                                <td className="th-download-invoice"><span className="span-download-invoice" data-prefix>$</span><span>{selectedTask.amount}</span></td>
                            </tr>
                        </table>
                    </article>


                </body>
            </div>
            <Button
                buttonClassName="button-submit vault-button"
                children="Download"
                onClick={generatePDF}
            />
        </div>
    );
};
