import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from '../../../../context/GlobalContext';
import ApiMiddleware from "../../../../components/Shared/ApiMiddleware";
import { Button } from "../../../../components/Shared/Button";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './toDoList.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
const MySwal = withReactContent(Swal);

function ToDoList() {
    const [todolist_tasks, setToDoListTasks] = useState([]);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { idUserGlobal, setToken, token, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [inputValue, setInputValue] = useState('');

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const handleSaveStatus = async () => {
        let todoList = todolist_tasks.map((item, index) => {
            return {
                todo_id: item.todo_id,
                statusName: item.status ? "Completed" : "Active"
            }
        });
        consumeFetch(`${API_BASE_URL}/todo_list/updateManyStatus`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify({
                todoList
            })
        }).then(response => response.json()).then(data => {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'success',
                title: 'Success',
                text: 'To Do List status saved.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => { setActiveBlur(false) });
        }).catch((err) => {
            console.error(err);
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: 'There was a unexpected error, please contact support.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => { setActiveBlur(false) });
        });
    }

    const compare = (a, b) => {
        if (a.task_title < b.task_title) {
            return -1;
        }
        if (a.task_title > b.task_title) {
            return 1;
        }
        return 0;
    }

    const handleAddToDoTask = async () => {
        console.log(inputValue);
        consumeFetch(`${API_BASE_URL}/todo_list/createByClient`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify({
                client_id: idUserGlobal,
                description: inputValue
            })
        }).then(response => response.json()).then(data => {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'success',
                title: 'Success',
                text: 'To Do List Task Saved.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => { setActiveBlur(false) });
            setInputValue('');
            fetchData();
        }).catch((err) => {
            console.error(err);
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: 'There was a unexpected error, please contact support.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => { setActiveBlur(false) });
        });
    }

    const handleDeleteClick = (todo_id) => {
        setActiveBlur(true);
        MySwal.fire({
            icon: 'warning',
            title: `Are you Sure?`,
            text: 'Do you want to delete this to do task ?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes, I want to delete it.',
            denyButtonText: `No, I want to keep it`,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        }).then((result) => {
            const endpoint = `${API_BASE_URL}/todo_list`;
            const params = new URLSearchParams();
            params.append('todo_id', todo_id);
            const url = `${endpoint}?${params}`;
            if (result.isConfirmed) {
                consumeFetch(url, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${token}`
                    }
                }
                ).then(response => response.json()).then(data => {
                    if (data.status === "success") {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'To Do Task Deleted.',
                            showConfirmButton: false,
                            timer: 1700,
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => { setActiveBlur(false) });
                        fetchData();
                    } else {
                        throw new Error("Error on api call");
                    }
                }).catch(error => {
                    console.error(error);
                    setActiveBlur(true);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops ...',
                        text: 'There was a unexpected error, please contact support.',
                        showConfirmButton: false,
                        timer: 1700,
                        backdrop: true,
                        customClass: {
                            popup: 'popup-sweet',
                            title: 'title-sweet',
                            htmlContainer: 'text-sweet',
                            confirmButton: 'confirm-button-sweet',
                            denyButton: 'deny-button-sweet',
                        }
                    }).finally(() => { setActiveBlur(false) });
                });
            }
        }).finally(() => { setActiveBlur(false) });
    };

    const fetchData = async () => {
        let endpoint = `${API_BASE_URL}/todo_list/getByClient`;
        const url = `${endpoint}/${idUserGlobal}`;
        try {
            const response = await consumeFetch(url, {
                headers: {
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            const data = await response.json();
            const newRows = data.map((item, index) => {
                const status = item.statusId.status_name === "Active" ? false : true;
                return {
                    id: index,
                    todo_id: item.id,
                    status: status,
                    task_title: item.task != null ? item.task.task_title : "Personal Task",
                    description: item.description
                }
            });
            newRows.sort(compare);

            setToDoListTasks(newRows);
        } catch (error) {
            console.error(error);
            // Manejar el error
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleClickCheckbox = async (todo_id, value) => {
        todolist_tasks.map(option => {
            if (option.todo_id === todo_id) {
                option.status = value;
            }
        });
        handleSaveStatus();
    }

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    return (
        <React.Fragment>
            {/* <FormGroup className="modal-rate-checkbox-container">
                {todolist_tasks.map(option =>
                    // <div className="checkbox">
                    //     <input key={option.id} type="checkbox" checked={option.status} onChange={e => handleClickCheckbox(option.todo_id, e.target.checked)}/>
                    //     <label className="todo_label">{option.description}</label>
                    // </div>
                    <FormControlLabel 
                        key={option.id} 
                        className="text-list"
                        control={<Checkbox defaultChecked={option.status} onChange={e => handleClickCheckbox(option.todo_id, e.target.checked)}/>} 
                        label={option.description} />
                )}
            </FormGroup> */}
            {todolist_tasks.length > 0 ?
                <><List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {todolist_tasks.map((option) => {
                        const labelId = `checkbox-list-label-${option}`;
                        return (
                            <div key={option.id}>
                                <ListItem disablePadding >
                                    <ListItemIcon>
                                        <Checkbox defaultChecked={option.status} onChange={e => handleClickCheckbox(option.todo_id, e.target.checked)} />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={option.description} className={option.status && 'linethrough'} secondary={`${option.task_title}`} />
                                </ListItem>
                                <Divider style={{ borderColor: '#000', borderBottomWidth: 'medium' }} />
                            </div>
                        );
                    })}
                </List>

                </>
                :
                <>
                    <Box sx={{ width: '100%' }} style={{ display: 'flex', justifyContent: 'center', margin: '17px' }}>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            You don´t have any task to do
                        </Typography>
                    </Box>

                </>
            }
        </React.Fragment>
    );
}

export { ToDoList };
