import React, { useContext, useState, useEffect, useRef } from "react";
import { Box } from '@mui/material';
import { format } from 'date-fns';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { GlobalContext } from '../../../context/GlobalContext'
import { BoxPassword } from "../../assistantContent/tasks/BoxPassword";
import { Button as Btn } from "../../../components/Shared/Button";
import './PopupInfoTaskToApprove.css'
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { SliderProgress } from '../../../components/Shared/ProgressBar'
const MySwal = withReactContent(Swal);

function PopupInfoTaskToApprove(props) {
    const { selectedTask, classHeader, fetchData, closePopup } = props;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [secretsName, setSecretsName] = useState();
    const [progress, setProgress] = useState(0);
    const [max, setMax] = useState(0);
    const [min, setMin] = useState(0);
    const [timeUsedHours, setTimeUsedHours] = useState();
    const [timeUsedMinutes, setTimeUsedMinutes] = useState();
    const [recurrences, setRecurrences] = useState();
    const [principalTask, setPrincipalTask] = useState();
    const SnackbarRef = useRef();
    const isMobile = window.innerWidth <= 600;

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect(() => {
        //Consumo de recurrents tasks
        async function getRecurrents() {
            const response = await consumeFetch(`${API_BASE_URL}/tasks/recurrentsByParent/${selectedTask.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setRecurrences(data);
            }

        }

        if (selectedTask.recurrent_Id != null) {
            getRecurrents();
        }
    }, [])

    useEffect(() => {
        async function getTaskPrincipal() {
            const response = await consumeFetch(`${API_BASE_URL}/tasks/getTaskById/${selectedTask.parentTask}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setPrincipalTask(data);
            }

        }
        if (selectedTask.parentTask != null) {
            getTaskPrincipal();
        }
    }, []);

    useEffect(() => {
        const emailAssistantTask = selectedTask && selectedTask.assistantId ? selectedTask.assistantId.email : null;
        if (emailAssistantTask == null) {
            return;
        }
        if (emailAssistantTask !== emailGlobal) {
            return;
        }
        consumeFetch(`${API_BASE_URL}/task_secrets/getByTaskId/${selectedTask.id}`, {
            headers: {
                Authorization: `${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                const mappedData = data.data.map((item, index) => {
                    return {
                        secret_name: item.secret.name_secret,
                        email_user: selectedTask.clientId.email
                    };
                });
                if (mappedData.length !== 0) {
                    setSecretsName(mappedData);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [])

    function timeToHours(tiempoStr) {
        if (!tiempoStr) {
            return 1;
        }
        var partes = tiempoStr.split(':');
        var horas = parseInt(partes[0]);
        var minutos = parseInt(partes[1]);
        var totalHoras = horas + minutos / 60;
        return totalHoras;
    }

    const normalise = (value) => ((value - min) * 100) / (max - min);

    const formatDate = (date) => {
        var dateLimit = new Date(date);
        var userTimezoneOffset = dateLimit.getTimezoneOffset() * 60000;
        dateLimit = new Date(dateLimit.getTime() + userTimezoneOffset);
        const year = dateLimit.getFullYear();
        const month = dateLimit.getMonth() + 1;
        const day = dateLimit.getDate();

        return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
    }

    const getHours = (timeUsed) => {
        return Math.floor(timeUsed);
    }

    const getMinutes = (timeUsed) => {
        return Math.round((timeUsed - Math.floor(timeUsed)) * 60)
    }

    useEffect(() => {
        setMax(timeToHours(selectedTask.hours));
        setTimeUsedHours(getHours(selectedTask.timeHoursUsed));
        setTimeUsedMinutes(getMinutes(selectedTask.timeHoursUsed));
        setProgress(selectedTask.timeHoursUsed);
    }, []);

    const createdAt = format(new Date(selectedTask.created_at), 'MM/dd/yyyy HH:mm');

    const approveTaskByAdmin = async () => {
        const request = {
            email: emailGlobal,
        };
        const response = await consumeFetch(`${API_BASE_URL}/tasks/approveTaskByAdmin/${selectedTask.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify(request)
        });

        if (response.ok) {
            closePopup();
            setActiveBlur(true);
            MySwal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Task Approved.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).then(() => {
                fetchData();
            }).finally(() => {setActiveBlur(false)});
        } else {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: 'There was a unexpected error, please contact support.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => {setActiveBlur(false)});
        }
    }

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="full-task-modal">
                <div className="modal-task-upper">
                    <div className={`modal-task-header ${classHeader}`}>
                        <section className="modal-task-header-left">
                            <div className="modal-task-owner">
                                <label className="modal-task-label modal-task-owner-text">For: {selectedTask.clientId.name}</label>
                            </div>
                        </section>
                        <section className="modal-task-header-right">
                            <label className="modal-task-label modal-task-created">Created: {createdAt}</label>
                            <label className="modal-task-label modal-task-status">Status: {selectedTask.status_Id.status_name}</label>
                            {/* <label className="modal-task-label modal-task-type">Type: {selectedTask.taskType_Id.task_type_name}</label> */}
                        </section>
                    </div>
                    <div className="modal-task-header-title">
                        <h2>{selectedTask.taskTitle}</h2>
                    </div>
                </div>
                <div className="modal-task-description">
                    <p className="modal-task-description-text">{selectedTask.description}</p>
                </div>
                <div className="modal-task-hours-date-limit">
                    <p>
                        {principalTask != null && principalTask != undefined && `The main task is ${principalTask.taskTitle} with ${principalTask.nRecurrences} recurrences and a deadline of ${formatDate(principalTask.date_limit)} .`}{principalTask != null && principalTask != undefined && <br />}
                        {selectedTask.hours && `This task have a total of ${timeToHours(selectedTask.hours)} hours to be completed`}{selectedTask.hours && <br />}
                        {!selectedTask.hours && `This task has no time limit to be executed.`}{!selectedTask.hours && <br />}
                        {selectedTask.recurrent_Id != null && `This task is recurrent with the option "${selectedTask.recurrent_Id.description}".`}{selectedTask.recurrent_Id != null && <br />}
                        {selectedTask.dailyLimitHours && selectedTask.recurrent_Id == null && selectedTask.assistantId == null && `This task is limited to ${selectedTask.dailyLimitHours} hours per day to be executed by the assistant.`}{selectedTask.dailyLimitHours && selectedTask.recurrent_Id == null && selectedTask.assistantId == null && <br />}
                        {selectedTask.dailyLimitHours && selectedTask.recurrent_Id != null && `This task has ${selectedTask.nRecurrences} recurrences, each with ${selectedTask.dailyLimitHours} hours to be executed by the assistant.`}{selectedTask.dailyLimitHours && selectedTask.recurrent_Id != null && <br />}
                        {selectedTask.date_limit && `This task is due to be completed by `}{selectedTask.date_limit && <span className="date-limit-popup-info-task">{formatDate(selectedTask.date_limit)}.</span>}{selectedTask.date_limit && <br />}
                        {!selectedTask.date_limit && `This task does not have a deadline for completion`}{!selectedTask.date_limit && <br />}
                    </p>
                </div>

                <br />
                {selectedTask.recurrent_Id != null && recurrences &&
                    <>
                        <div className="modal-task-recurrents">
                            <h3 className="modal-task-recurrents-title">Recurrences</h3>
                        </div>
                        <div className="modal-task-table-recurrences">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recurrences.map(rec => (
                                        <tr>
                                            <td>{rec.taskTitle}</td>
                                            <td>{format(new Date(rec.dateDisplayed), 'MM/dd/yyyy')}</td>
                                            <td>{rec.status_Id.status_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }

                {secretsName && (selectedTask.assistant_id !== null && (
                    <section className="modal-task-secrets">
                        <div className="modal-task-secrets-title">
                            Associated secrets
                        </div>
                        <div className="modal-task-secrets-items">
                            {secretsName.map((item) => (
                                <BoxPassword item={item} statusTask={selectedTask.status_Id.status_name} />
                            ))}
                        </div>
                    </section>
                )
                )}
                <div className="modal-task-progress-bar">
                    <div className="modal-task-progress-hours">
                        <div className="hour-executed"><b>Executed: </b>
                            {timeUsedHours !== 0 && `${timeUsedHours} Hours `}
                            {timeUsedHours === 0 && `${timeUsedHours} Hours `}
                            {timeUsedMinutes !== 0 && `${timeUsedMinutes} Minutes`}
                        </div>
                        <div className="maximum-hours-client">{selectedTask.hours && <div><b>Max:</b> {timeToHours(selectedTask.hours)} Hours</div>}</div>
                    </div>
                    <Box sx={{ width: '100%', padding: '10px' }}>
                        <SliderProgress value={normalise(progress)} />
                    </Box>
                </div>
                <div className="modal-approve-button-container">
                    <Btn
                        buttonClassName="modal-rate-button"
                        onClick={() => approveTaskByAdmin() }
                        children="Approve Task"
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export { PopupInfoTaskToApprove };