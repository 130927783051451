import React, { useContext, useRef } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import socket from "../../../utils/socket";
import { GlobalContext } from "../../../context/GlobalContext";
import { ContentHeader } from "../../../components/Shared/ContentHeader";
import { ContractCard } from "./ContractCard";
import { ContractCardPackage } from "./ContractCardPackage";
import { ContractCardHours } from "./ContractCardHours";
import { Button } from "../../../components/Shared/Button";
import { useStripe, useElements } from '@stripe/react-stripe-js';
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import "./Contract.css"
import { ModalFormNeedHelp } from "./ModalFormNeedHelp";
import { ModalFormSuspendPayments } from "./ModalFormSuspendPayments";
const MySwal = withReactContent(Swal);


function Contracts() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const FRONT_BASE_URL = process.env.REACT_APP_FRONT_BASE_URL || "http://localhost:3000";
    const { setPlanesUsuario, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur, planesUsuario } = useContext(GlobalContext)
    const history = useHistory();
    const ModalDialogRef = useRef();
    const CancelPlanModalDialogRef = useRef();

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }


    const elements = useElements();
    const stripe = useStripe();


    const handlePaymentSubmit = async (event, pricePlan, namePlan, planType, hours) => {
        event.preventDefault();
        setActiveBlur(true);
        const { value: prom_code } = await MySwal.fire({
            title: `Do you have a promotional code?`,
            input: 'text',
            inputLabel: `Enter it below. If you don't have a code please click on next.`,
            inputPlaceholder: 'Promotional Code',
            confirmButtonText: 'Next',
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        }).finally(() => { setActiveBlur(false) });
        if (prom_code !== null && prom_code !== undefined) {
            if (prom_code.trim() !== '') {
                consumeFetch(`${API_BASE_URL}/coupons/validateCoupon`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`
                    },
                    body: JSON.stringify({
                        couponCode: prom_code,
                        planName: namePlan,
                        planType: planType,

                    }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.message === "Coupon not found") {
                            setActiveBlur(true);
                            MySwal.fire({
                                icon: 'error',
                                title: 'Code not Valid',
                                text: 'This code is not valid, please repeat the process.',
                                showConfirmButton: false,
                                timer: 1700,
                                backdrop: true,
                                customClass: {
                                    popup: 'popup-sweet',
                                    title: 'title-sweet',
                                    htmlContainer: 'text-sweet',
                                    confirmButton: 'confirm-button-sweet',
                                    denyButton: 'deny-button-sweet',
                                }
                            }).then((result) => {
                                return;
                            }).finally(() => { setActiveBlur(false) });
                        } else if (data.valid) {
                            setActiveBlur(true);
                            MySwal.fire({
                                title: `Code Valid`,
                                icon: 'success',
                                text: `The value to pay is $${data.valuetoPay}. Do you wish to continue with the process ?`,
                                showDenyButton: true,
                                showCancelButton: false,
                                confirmButtonText: 'Yes',
                                denyButtonText: `No, cancel the payment.`,
                                backdrop: true,
                                customClass: {
                                    popup: 'popup-sweet',
                                    title: 'title-sweet',
                                    htmlContainer: 'text-sweet',
                                    confirmButton: 'confirm-button-sweet',
                                    denyButton: 'deny-button-sweet',
                                }
                            }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                    if (data.valuetoPay > 0) {
                                        paymentProcess(namePlan, planType, data.valuetoPay);
                                    } else {
                                        consumeFetch(`${API_BASE_URL}/plans/assignPlan`, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                Authorization: `${token}`
                                            },
                                            body: hours ? JSON.stringify({
                                                email: emailGlobal,
                                                planName: namePlan,
                                                planType: planType
                                            }) :
                                                JSON.stringify({
                                                    email: emailGlobal,
                                                    planName: namePlan,
                                                    planType: planType,
                                                    hoursPurshased: hours,
                                                }),
                                        }).then((response) => response.json()).then((data) => {
                                            setActiveBlur(true);
                                            MySwal.fire({
                                                icon: 'success',
                                                title: 'Plan purchased !!!',
                                                text: `Now you have the ${data.planName} assigned.`,
                                                showConfirmButton: false,
                                                timer: 1700,
                                                backdrop: true,
                                                customClass: {
                                                    popup: 'popup-sweet',
                                                    title: 'title-sweet',
                                                    htmlContainer: 'text-sweet',
                                                    confirmButton: 'confirm-button-sweet',
                                                    denyButton: 'deny-button-sweet',
                                                }
                                            }).finally(() => {
                                                setActiveBlur(false);
                                                setPlanesUsuario(data.userPlans);
                                            });
                                        }).catch((error) => {
                                            setActiveBlur(true);
                                            MySwal.fire({
                                                icon: 'error',
                                                title: 'Oops ...',
                                                text: 'There was a unexpected error, please contact support.',
                                                showConfirmButton: false,
                                                timer: 1700,
                                                backdrop: true,
                                                customClass: {
                                                    popup: 'popup-sweet',
                                                    title: 'title-sweet',
                                                    htmlContainer: 'text-sweet',
                                                    confirmButton: 'confirm-button-sweet',
                                                    denyButton: 'deny-button-sweet',
                                                }
                                            }).finally(() => { setActiveBlur(false) });
                                        });
                                    }
                                } else if (result.isDenied) {
                                    return;
                                }
                            }).finally(() => { setActiveBlur(false) });
                        }
                    });
            } else {
                Swal.fire({
                    title: "You are being redirected to our Payment Processor Platform. This platform is an external page thus having its own Terms and Conditions and Privacy Policy.",
                    
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    showLoaderOnConfirm: true,
                    customClass: {
                                    popup: 'popup-sweet',
                                    title: 'title-sweet',
                                    htmlContainer: 'text-sweet',
                                    confirmButton: 'confirm-button-sweet',
                                    denyButton: 'deny-button-sweet',
                                },
                    preConfirm: (checkboxValue) => {
                        // Handle checkbox value
                        return checkboxValue;
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                            paymentProcess(namePlan, planType, pricePlan, hours);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        console.log('Canceled');
                    }
                });

            }
        }
    }

    const paymentProcess = (namePlan, planType, pricePlan, hours) => {

        if (!stripe || !elements) {
            return;
        }

        // Call your backend API to generate the checkout session.
        consumeFetch(`${API_BASE_URL}/payments/checkout-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`
            },
            body: JSON.stringify({
                email: emailGlobal, // Replace with the user's email.
                plan: hours ? {
                    name: namePlan,
                    planType: planType,
                    price: `${pricePlan}00`,
                } : {
                    name: namePlan,
                    planType: planType,
                    price: `${pricePlan}00`,
                    hoursPurshased: hours,
                }, // Replace with the selected plan.
                successUrl: `${FRONT_BASE_URL}/user/Billings`,
                cancelUrl: `${FRONT_BASE_URL}/user/Home`
            }),
        }).then((response) => response.json()).then((data) => {
            // Redirect the user to the Stripe checkout page.
            stripe.redirectToCheckout({
                sessionId: data.session.id,
            }).then((result) => {
                console.log(result);
            });
        }).catch((error) => {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: "Maybe you're trying to purchase a plan with the same type that one of your active plans. If not, please contact support.",
                showConfirmButton: false,
                timer: 2500,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => { setActiveBlur(false) });
        });
    }

    const needHelpClick = async () => {
        const modifiedColumns = [
            { field: 'name', headerName: 'Name' },
            { field: 'lastName', headerName: 'Last Name' },
            { field: 'company', headerName: 'Company' },
            { field: 'phone', headerName: 'Phone' },
            { field: 'comments', headerName: 'Comments' },
        ];

        const initialModel = modifiedColumns.map(column => {
            // Conservar las demás propiedades
            return { ...column, value: '' };
        });
        ModalDialogRef.current.setModel(initialModel);
        ModalDialogRef.current.setTypeAction('New Form');
        openModal();
    };

    const modifyPlanClick = async () => {
        const modifiedColumns = [];
        planesUsuario.forEach(element => {
            const el = { field: element, headerName: 'Plan' }
            modifiedColumns.push(el)
        });
        const initialModel = modifiedColumns.map(column => {
            // Conservar las demás propiedades
            return { ...column, value: '' };
        });

        CancelPlanModalDialogRef.current.setModel(initialModel);
        ModalDialogRef.current.setTypeAction('New Form');

        openModalModify();
    };

    const handleSendForm = async (pModel) => {
        console.log(pModel);
        pModel.email = emailGlobal;
        consumeFetch(`${API_BASE_URL}/plans/sendFormHelp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`
            },
            body: JSON.stringify(pModel),
        })
            .then((response) => response.json()).then((data) => {
                setActiveBlur(true);
                MySwal.fire({
                    icon: 'success',
                    title: 'Information Sent',
                    text: `Thank you for your interest, a team member will be in contact soon`,
                    showConfirmButton: false,
                    timer: 2500,
                    backdrop: true,
                    customClass: {
                        popup: 'popup-sweet',
                        title: 'title-sweet',
                        htmlContainer: 'text-sweet',
                        confirmButton: 'confirm-button-sweet',
                        denyButton: 'deny-button-sweet',
                    }
                }).finally(() => { setActiveBlur(false) });
            }).catch((error) => {
                console.error(error);
                setActiveBlur(true);
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops ...',
                    text: 'There was a unexpected error, please contact support.',
                    showConfirmButton: false,
                    timer: 2000,
                    backdrop: true,
                    customClass: {
                        popup: 'popup-sweet',
                        title: 'title-sweet',
                        htmlContainer: 'text-sweet',
                        confirmButton: 'confirm-button-sweet',
                        denyButton: 'deny-button-sweet',
                    }
                }).finally(() => { setActiveBlur(false) });
            });
    };

    const handleSendFormCancel = async (pModel) => {
        console.log(pModel);
        pModel.email = emailGlobal;
        consumeFetch(`${API_BASE_URL}/plans/sendFormHelp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`
            },
            body: JSON.stringify(pModel),
        })
            .then((response) => response.json()).then((data) => {
                setActiveBlur(true);
                MySwal.fire({
                    icon: 'success',
                    title: 'Information Sent',
                    text: `Thank you for your interest, a team member will be in contact soon`,
                    showConfirmButton: false,
                    timer: 2500,
                    backdrop: true,
                    customClass: {
                        popup: 'popup-sweet',
                        title: 'title-sweet',
                        htmlContainer: 'text-sweet',
                        confirmButton: 'confirm-button-sweet',
                        denyButton: 'deny-button-sweet',
                    }
                }).finally(() => { setActiveBlur(false) });
            }).catch((error) => {
                console.error(error);
                setActiveBlur(true);
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops ...',
                    text: 'There was a unexpected error, please contact support.',
                    showConfirmButton: false,
                    timer: 2000,
                    backdrop: true,
                    customClass: {
                        popup: 'popup-sweet',
                        title: 'title-sweet',
                        htmlContainer: 'text-sweet',
                        confirmButton: 'confirm-button-sweet',
                        denyButton: 'deny-button-sweet',
                    }
                }).finally(() => { setActiveBlur(false) });
            });
    };

    const openModal = () => {
        ModalDialogRef.current.setTitle("Company information");
        ModalDialogRef.current.handleOpen();
    };

    const openModalModify = () => {
        CancelPlanModalDialogRef.current.setTitle("Plan View");
        CancelPlanModalDialogRef.current.handleOpen();
    };

    return (
        <React.Fragment>
            <ModalFormNeedHelp
                open={ModalDialogRef.open}
                handleClose={ModalDialogRef.handleClose}
                ref={ModalDialogRef}
                onAddItem={handleSendForm}
            />
            <ModalFormSuspendPayments
                open={CancelPlanModalDialogRef.open}
                handleClose={CancelPlanModalDialogRef.handleClose}
                ref={CancelPlanModalDialogRef}
                onAddItem={handleSendFormCancel}
            />
            <div className="header-plans">
                <ContentHeader text="Plans" />
            </div>
            <div className="plans-full-frame">
                <div className="plans-cards-container">
                    <div className="plans-cards">
                        <ContractCard
                            cardTitle="Part Time"
                            description="Qualified pre-vetted virtual assistants for basic task:"
                            subdescription={[{ desc: "Monthly price for unlimited tasks within the plan:", price: "$1100", value: "1100", planName: "Part Time", planType: "Virtual Assistant" }]}
                            boldDescription={["*Limit of 4 hours per day, 5 days a week for the month", "*Limited to 'Task offered'"]}
                            handleSubmit={handlePaymentSubmit}
                        />
                        <ContractCard
                            cardTitle="Full time"
                            description="Qualified pre-vetted virtual assistants for basic tasks:"
                            subdescription={[{ desc: "Monthly price for unlimited tasks within the plan:", price: "$2000", value: "2000", planName: "Full Time", planType: "Virtual Assistant" }]}
                            boldDescription={["*Limit of 4 hours per day, 5 days a week for the month", "*Limited to 'Task offered'"]}
                            handleSubmit={handlePaymentSubmit}
                        />
                    </div>
                    <div className="plans-cards">
                        <ContractCardPackage
                            cardTitle="Package Hours"
                            description="Qualified pre-vetted virtual assistants for basic tasks:"
                            boldDescription={["*Limit of 4 hours per day, 5 days a week for the month", "*Limited to 'Task offered'"]}
                            handleSubmit={handlePaymentSubmit}
                        />
                        <ContractCardHours
                            cardTitle="Hours"
                            description="Qualified pre-vetted virtual assistants for basic tasks:"
                            handleSubmit={handlePaymentSubmit}
                        />


                    </div>
                </div>
                {planesUsuario.length === 0 ?
                    <div className="need-help-container">
                        <Button onClick={needHelpClick} buttonClassName="button-need-help" children="Need Help Deciding ?" />
                    </div> :
                    <div className="need-help-container">
                        <Button onClick={modifyPlanClick} buttonClassName="button-need-help" children="Modify Payment" />
                    </div>
                }
            </div>
        </React.Fragment>
    );
}

export { Contracts };
