import React from 'react'
import imgDefaultUser from '../../../assets/images/programador.png';
import IconButton from '@mui/material/IconButton';
import { RiDeleteBin6Fill, RiHeartLine, RiHeartFill } from 'react-icons/ri';
import { IoIosStar } from 'react-icons/io';
import './Assistant.css'

export const CuadroAssistant = (props) => {

    const {asistente, handleClick, isActiveAssistants} = props;
  return (
        <div className='cuadroInfoAssistants sombra2' style={{ background: `rgba(${asistente.bgColor?.slice(4, -1)}, 0.2)`, borderColor: asistente.bgColor, borderWidth: '3px', borderStyle: 'solid'}}>
            <div className='assistant-info-container'>
                <div className='name-container'>
                    <div className="preferred-assistants-assistant-name">
                        <span>{asistente.nombre}</span>
                    </div>
                </div>
                <div className='img-container'>
                    <img className='imgUser' src={asistente.avatar !== null && asistente.avatar !== undefined ? asistente.avatar : imgDefaultUser} alt="usuario" />
                </div>
                <div className='rating-box'>
                    {Array.from({ length: asistente.calification }).map((element, index) => 
                        <div key={index}>
                            <IoIosStar className='rating-star'/>
                        </div>
                    )}
                </div>
                <div>
                    <p><strong>Email: </strong> {asistente.correo}</p>
                </div>
            </div>
            <div className='button-delete-container'>
                    {isActiveAssistants ? 
                        <>
                            {asistente.isPreferred ?
                                <IconButton>
                                    <RiHeartFill 
                                        className="button-delete-preferred-icon" 
                                    />
                                </IconButton>
                            :
                                <IconButton onClick={(event) => handleClick(event,asistente.idUser)}>
                                    <RiHeartLine 
                                        className="button-delete-preferred-icon" 
                                    />
                                </IconButton>
                            }
                        </>
                    :
                        <IconButton onClick={(event) => handleClick(event,asistente.idUser)}>
                            <RiDeleteBin6Fill 
                                className="button-delete-preferred-icon" 
                            />
                        </IconButton>
                    }
            </div>
        </div>
  )
}
