import React, { useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import { CuadroAssistant } from './CuadroAssistant';
import { ContentHeader } from '../../../components/Shared/ContentHeader';
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { GlobalContext } from '../../../context/GlobalContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

function Assistant() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
  const history = useHistory();
  const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
  const [asistentes, setAsistentes] = useState();
  const [activeAssistants, setActiveAssistants] = useState();
  const bgColors = ['#EBBE46','#B57FFF','#4285FF','#DC7E80'];
  const bgColorAr = ['rgb(237, 192, 81)', 'rgb(183, 133, 255)', 'rgb(70, 139, 255)', 'rgb(234, 128, 148)'];

  const consumeFetch = async (url, options) => {
    try {
        const originalFetch = fetch;
        const fetchWithMiddleware = ApiMiddleware(originalFetch);
        const { response, token } = await fetchWithMiddleware(url, options);
        // Se detecta token nuevo
        if (token) {
            setToken(token)
        }
        return await response;
    } catch (error) {
        if (error.message === "RefreshToken Vencido") {
            console.log(error.message, error)

            setToken("");
            setEmailGlobal("");
            setIdUserGlobal("");
            setUserName("");
            setActivePage("Home");
            history.push('/');
        }
    }
  }

  const fetchData = async () => {
    let endpoint = `${API_BASE_URL}/preferredAssistants/getByClient`;
    const url = `${endpoint}/${idUserGlobal}`;
    try {
        const response = await consumeFetch(url, {
            headers: {
                Authorization: `${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }
        const data = await response.json();
        let indexColor = 0;
        const newRows = data.data.map((item, index) => {
          let obj = {
            idUser: item.assistant.id,
            imagen: item.assistant.avatar,
            nombre: item.assistant.name,
            calification: Math.round(Number(item.assistant.calification)),
            correo: item.assistant.email,
            bgColor: bgColorAr[indexColor]
          }
          indexColor = indexColor === bgColors.length-1 ? 0 : indexColor+1;
          return obj;
        });
        setAsistentes(newRows);
    } catch (error) {
        console.error(error);
        // Manejar el error
    }
  }

  const fetchDataActive = async () => {
    let endpoint = `${API_BASE_URL}/preferredAssistants/getActiveAssistants`;
    const url = `${endpoint}/${idUserGlobal}`;
    try {
        const response = await consumeFetch(url, {
            headers: {
                Authorization: `${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }
        const data = await response.json();
        let indexColor = 0;
        const newRows = data.data.map((item, index) => {
          let obj = {
            idUser: item.assistantId.id,
            imagen: item.assistantId.avatar,
            nombre: item.assistantId.name,
            calification: Math.round(Number(item.assistantId.calification)),
            correo: item.assistantId.email,
            isPreferred: item.isPreferred,
            bgColor: bgColorAr[indexColor]
          }
          indexColor = indexColor === bgColors.length-1 ? 0 : indexColor+1;
          return obj;
        });
        setActiveAssistants(newRows);
    } catch (error) {
        console.error(error);
        // Manejar el error
    }
  }

  useEffect(() => {
    fetchData();
    fetchDataActive();
  }, []);

  const handleDeleteClick = async (event, idAssistant) => {
    event.preventDefault();
    setActiveBlur(true);
    MySwal.fire({
      icon:'warning',
      title: `Are you Sure?`,
      text: 'Do you want to delete this assistant from your preferred list?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes, I want to delete it',
      denyButtonText: `No, I want to continue with it`,
      backdrop: true,
      customClass: {
          popup: 'popup-sweet',
          title: 'title-sweet',
          htmlContainer: 'text-sweet',
          confirmButton: 'confirm-button-sweet',
          denyButton: 'deny-button-sweet',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const params = new URLSearchParams();
        let endpoint = `${API_BASE_URL}/preferredAssistants`;
        params.append('user_id', idUserGlobal);
        params.append('assistant_id', idAssistant);
        const url = `${endpoint}?${params}`;
        consumeFetch(url, {
          method: "DELETE",
          headers: {
              Authorization: `${token}`
          }
        }).then(response => response.json()).then(data => {
          if(data.status === "success"){
            setActiveBlur(true);
            MySwal.fire({
                icon: 'success',
                title: 'Success',
                text: 'The assistant was removed from your preferred list.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
              }).finally(() => {setActiveBlur(false)});
            fetchData();
            fetchDataActive();
          }else{
              throw new Error("Error on api call");
          }
        }).catch(error => {
          console.error(error);
          setActiveBlur(true);
          MySwal.fire({
            icon: 'error',
            title: 'Oops ...',
            text: 'There was a unexpected error, please contact support.',
            showConfirmButton: false,
            timer: 1700,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
          }).finally(() => {setActiveBlur(false)});
        });
      }
    }).finally(() => {setActiveBlur(false)});
  }

  const handleAddPreferredClick = async (event, idAssistant) => {
    event.preventDefault();
    setActiveBlur(true);
    MySwal.fire({
      icon:'warning',
      title: `Are you Sure?`,
      text: 'Do you want to add this assistant to your preferred list?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes, I want to add it',
      denyButtonText: `No, I want to let him in active`,
      backdrop: true,
      customClass: {
          popup: 'popup-sweet',
          title: 'title-sweet',
          htmlContainer: 'text-sweet',
          confirmButton: 'confirm-button-sweet',
          denyButton: 'deny-button-sweet',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `${API_BASE_URL}/preferredAssistants`;
        consumeFetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            user_id: idUserGlobal,
            assistant_id: idAssistant
          }),
        }).then(response => {
          if(response.ok){
            setActiveBlur(true);
            MySwal.fire({
                icon: 'success',
                title: 'Success',
                text: 'The assistant was added to your preferred list.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
              }).finally(() => {setActiveBlur(false)});
            fetchData();
            fetchDataActive();
          }else{
              throw new Error("Error on api call");
          }
        }).catch(error => {
          console.error(error);
          setActiveBlur(true);
          MySwal.fire({
            icon: 'error',
            title: 'Oops ...',
            text: 'There was a unexpected error, please contact support.',
            showConfirmButton: false,
            timer: 1700,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
          }).finally(() => {setActiveBlur(false)});
        });
      }
    }).finally(() => {setActiveBlur(false)});
  }

    return (
      <React.Fragment>
        { asistentes && (
          <>
            <div className="assistant-header" style={{display:'flex',flexDirection:'row',alignItems:'center',padding:'20px',gap:'10px',flex:'none',order:'0', alignSelf:'stretch',flexGrow:'0'}}>
                  <ContentHeader text=" Preferred Assistants" />
            </div>
            <div className='profile' style={{display:'flex', gap:'20px',rowGap:'30px' ,flexWrap: 'wrap', justifyContent:'flex-start',alignItems:'center', alignContent:'center', padding:'20px' }}>
              {asistentes.map(
                  option => <div key={option.idUser} style={{}}><CuadroAssistant
                  asistente = {option}
                  handleClick = {handleDeleteClick}
                  isActiveAssistants={false}
                  /></div>
              )}
            </div>
          </>
        )}
        { activeAssistants && (
          <>
            <div className="assistant-header" style={{display:'flex',flexDirection:'row',alignItems:'center',padding:'20px',gap:'10px',flex:'none',order:'0', alignSelf:'stretch',flexGrow:'0'}}>
                  <ContentHeader text=" Active Assistants" />
            </div>
            <div className='profile' style={{display:'flex', gap:'20px',rowGap:'30px' ,flexWrap: 'wrap', justifyContent:'flex-start',alignItems:'center', alignContent:'center', padding:'20px' }}>
              {activeAssistants.map(
                  option => <div key={option.idUser} style={{}}><CuadroAssistant
                  asistente = {option}
                  handleClick = {handleAddPreferredClick}
                  isActiveAssistants={true}
                  /></div>
              )}
            </div>
          </>
        )}
      </React.Fragment>
    );
}

export { Assistant };
