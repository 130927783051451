
import { Home } from '../pages/clientContent/home';
import { OwnedTasks } from '../pages/assistantContent/tasks/OwnedTasks';
import { AllTasks } from '../pages/assistantContent/tasks/AllTasks';
import { CalendarAssistant } from '../pages/assistantContent/calendar';
import { ToDoListPage } from '../pages/assistantContent/todolist/ToDoListPage';
import { AiFillHome } from 'react-icons/ai';
import { FaUserAstronaut, FaTasks } from 'react-icons/fa';
import { BiTask} from 'react-icons/bi';
import { BsCalendarDate } from 'react-icons/bs'
import { Client } from '../pages/assistantContent/client';
import { NotesPage } from '../pages/assistantContent/notes/NotesPage';
import { Incomings } from '../pages/assistantVOContent/incomings'


const assistantMainOptions = [
    { name: 'My Tasks', component: <OwnedTasks />, icon: <FaTasks />, show: true },
    { name: 'All Tasks', component: <AllTasks />, icon: <BiTask />, show: true },
    { name: 'Calendar', component: <CalendarAssistant />, icon: <BsCalendarDate />, show: true },
    { name: "Preferred Clients", component: <Client />, icon: <FaUserAstronaut />, show: true },
    { name: 'ToDoList', component: <ToDoListPage />, icon: <AiFillHome />, show: false },
    { name: 'Notes', component: <NotesPage />, icon: <AiFillHome />, show: false }
];

const assistantOptionsVOffice = [
    { name: 'Incomings', component: <Incomings />, icon: <FaTasks />, show: true }
];

export { assistantMainOptions, assistantOptionsVOffice }
