import React, { useState, useContext, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
import { ListSidebar } from './ListSidebar';
import { ProfileButtonSidebar } from './ProfileButtonSidebar';
import './ListSidebar/ListSidebar.css'
import './Sidebar.css'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';
import { GlobalContext } from '../../context/GlobalContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import socket from "../../utils/socket.js";
import menu from "../../assets/images/menu.png"

const MySwal = withReactContent(Swal);

function Sidebar(props) {

    let { mainOptions, middleOptions, supportOptions, onLogoutClick } = props;
    mainOptions = mainOptions.filter(option => option.show === true);
    middleOptions = middleOptions.filter(option => option.show === true);

    const history = useHistory();
    const { activePage, setToken, setEmailGlobal, setIdUserGlobal, setUserName, showSupportBadge, setShowSupportBadge, showAdminBadge, setShowAdminBadge,  setActiveBlur} = useContext(GlobalContext);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const sidebarRef = useRef(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateScreenWidth);
        setShowSupportBadge(true);
        setShowAdminBadge(true);
        socket.on("Support Message notification", ({ content, from, to }) => {
            setShowSupportBadge(false);
        });

        socket.on("Admin Message notification", ({ content, from, to }) => {
            setShowAdminBadge(false);
        });

        return ()=>{
            window.removeEventListener('resize', updateScreenWidth);
            socket.off("Support Message notification");
            socket.off("Admin Message notification");
        }
    }, []);

    useEffect(() => {
        if (screenWidth < 600)
            window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [isCollapsed]);

    const handleOutsideClick = (e) => {
        const clickedElement = e.target;

        // Get the ID of the clicked element
        const elementId = clickedElement.id;
        console.log(elementId);

        if (elementId!='toggleSidebarButton' && !(isCollapsed) ) {
            toggleSidebar();
        }
    };

    const handleOptionClick = (option) => {
        if (option === "Logout") {
            setActiveBlur(true);
            MySwal.fire({
                title: 'Are you sure you want to Log Out?',
                width: 430,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Log Out.',
                denyButtonText: `No, I want to continue`,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Cookies.remove('refreshToken');
                    Cookies.remove('rememberMe');
                    socket.disconnect();
                    setToken("");
                    setEmailGlobal("");
                    setIdUserGlobal("");
                    setUserName("");
                    history.push('/');
                } else if (result.isDenied) {
                }
            }).finally(() => {setActiveBlur(false)});
        }else{
            if(option === "Support"){
                setShowSupportBadge(true);
            }else if(option === "Messages Admin"){
                setShowAdminBadge(true);
            }
            history.push(`/user/${option}`);
        } 
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    }

    return (
        <React.Fragment>
            <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
                <div className={`upper-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
                    <ListSidebar
                        className={`ListSidebar MainListSidebar ${isCollapsed ? 'collapsed' : ''}`}
                        options={mainOptions}
                        selectedOption={activePage}
                        onOptionClick={handleOptionClick}
                        isCollapsed={isCollapsed}
                        showBadge={showAdminBadge}
                    />

                    <ListSidebar
                        className={`ListSidebar MobileListSidebar ${isCollapsed ? 'collapsed' : ''}`}
                        options={middleOptions}
                        selectedOption={activePage}
                        onOptionClick={handleOptionClick}
                        isCollapsed={isCollapsed}
                    />

                    <ListSidebar
                        className={`ListSidebar SupportListSidebar ${isCollapsed ? 'collapsed' : ''}`}
                        options={supportOptions}
                        selectedOption={activePage}
                        onOptionClick={handleOptionClick}
                        isCollapsed={isCollapsed}
                        showBadge={showSupportBadge}
                    />
                </div>
                <div className='lower-sidebar'>
                  
                </div>

                <div className='collapse-button-container' >
                    <button className='collapse-button' onClick={toggleSidebar} >
                        {screenWidth > 600 ? (!isCollapsed ?
                            <IoIosArrowDropleftCircle
                                className='collapse-icon'
                                color='#A4A4A4'
                            />
                            :
                            <IoIosArrowDroprightCircle
                                className='collapse-icon'
                                color='#A4A4A4'
                            />
                        ) : (isCollapsed ? <img
                        src={menu}
                            id='toggleSidebarButton'
                            className='collapse-icon'
                            color='#A4A4A4'
                        /> : <></>)}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

export { Sidebar };