import React, { useContext, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import socket from "../../../utils/socket";
import Rating from '@mui/material/Rating';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { GlobalContext } from '../../../context/GlobalContext'
import { BoxPassword } from "./BoxPassword";
import { Button as Btn } from "../../../components/Shared/Button";
import './PopupInfoTask.css'
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { LinearProgressWithLabel, SliderProgress } from '../../../components/Shared/ProgressBar'
const MySwal = withReactContent(Swal);

function PopupInfoTask(props) {
    const { selectedTask, setSelectedTask, closePopupDetail } = props;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { userRole, setToken, token, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [secretsName, setSecretsName] = useState();
    const [progress, setProgress] = useState(0);
    const [max, setMax] = useState(0);
    const [min, setMin] = useState(0);
    const [timeUsedHours, setTimeUsedHours] = useState();
    const [timeUsedMinutes, setTimeUsedMinutes] = useState();
    const [calification, setCalification] = useState(0);
    const [observations, setObservations] = useState("");
    const [addAssistant, setAddAssistant] = useState(false);
    const [recurrences, setRecurrences] = useState();


    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect(() => {
        consumeFetch(`${API_BASE_URL}/task_secrets/getByTaskId/${selectedTask.id}`, {
            headers: {
                Authorization: `${token}`
            }
        }).then(response => response.json())
            .then(data => {
                const mappedData = data.data.map((item, index) => {
                    return {
                        secret_name: item.secret.name_secret,
                        email_user: selectedTask.clientId.email
                    };
                });
                if (mappedData.length !== 0) {
                    setSecretsName(mappedData);
                }
            })
            .catch((err) => {
                console.error(err);
            });

        //Consumo de recurrents tasks
        async function getRecurrents() {
            const response = await consumeFetch(`${API_BASE_URL}/tasks/recurrentsByParent/${selectedTask.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setRecurrences(data);
            }

        }

        if (selectedTask.recurrent_Id != null) {
            getRecurrents();
        }

    }, [])

    function timeToHours(tiempoStr) {
        if (!tiempoStr) {
            return 1;
        }
        var partes = tiempoStr.split(':');
        var horas = parseInt(partes[0]);
        var minutos = parseInt(partes[1]);
        var totalHoras = horas + minutos / 60;
        return totalHoras;
    }

    const getHours = (timeUsed) => {
        return Math.floor(timeUsed);
    }

    const getMinutes = (timeUsed) => {
        return Math.round((timeUsed - Math.floor(timeUsed)) * 60)
    }

    const normalise = (value) => ((value - min) * 100) / (max - min);

    const formatDate = (date) => {
        var dateLimit = new Date(date);
        var userTimezoneOffset = dateLimit.getTimezoneOffset() * 60000;
        dateLimit = new Date(dateLimit.getTime() + userTimezoneOffset);
        const year = dateLimit.getFullYear();
        const month = dateLimit.getMonth() + 1;
        const day = dateLimit.getDate();

        return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;

    }

    const handleCreateChat = (task, assistant, status) => {
        if (assistant !== "" && assistant !== undefined && assistant !== null && assistant !== "Unassigned" && status !== "Done") {
            let data = {
                task_id: task
            }
            consumeFetch(`${API_BASE_URL}/messages/createChat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify(data)
            })
                .then((res) => {
                    if (res.ok) {
                        MySwal.fire(
                            'Chat Created!',
                            'Task chat has been created. You can see it on messages view.',
                            'success'
                        );
                    } else if (res.status === 400) {
                        MySwal.fire({
                            icon: 'info',
                            title: 'Chat Already Exists',
                            text: 'The chat already exists, see it on messages list.',
                        });
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'We can´t create the chat. Contact support.',
                        });
                    }
                }).catch((err) => {
                    console.log(err);
                });
        } else if (status === "Done") {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This task is already done, so we can´t create a chat.',
            });
        } else {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This task doesn´t have an assistant asigned, so we can´t create a chat.',
            });
        }
    }

    const handleSendRate = async () => {
        consumeFetch(`${API_BASE_URL}/tasks/addCalification`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify({
                idTask: selectedTask.id,
                calification: calification,
                observations: observations,
                addAssistant: addAssistant
            })
        }).then(response => response.json()).then(data => {
            closePopupDetail();
            setActiveBlur(true)
            MySwal.fire({
                icon: 'success',
                title: 'Success',
                text: 'The rate was saved.',
                showConfirmButton: false,
                timer: 1700,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => {
                setActiveBlur(false);
                setSelectedTask(null);
            });
        }).catch((err) => {
            console.error(err);
            MySwal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: 'There was a unexpected error, please contact support.',
                showConfirmButton: false,
                timer: 1700,
                customClass: {
                    title: 'titleModalLogout',
                }
            });
        });
    }

    useEffect(() => {
        setMax(timeToHours(selectedTask.hours));
        setTimeUsedHours(getHours(selectedTask.timeHoursUsed));
        setTimeUsedMinutes(getMinutes(selectedTask.timeHoursUsed));
        setProgress(selectedTask.timeHoursUsed);
    }, []);

    const createdAt = format(new Date(selectedTask.created_at), 'MM/dd/yyyy HH:mm');


    return (
        <React.Fragment>
            <div className="full-task-modal">
                { (selectedTask.status_Id.status_name === 'Done' && selectedTask.calification === null) ?

                    <div className="full-task-modal">
                        <div className="modal-rate-title">
                            <h2 className="modal-rate-title-text">Rate your task</h2>
                        </div>
                        <div className="modal-rate-description">
                            <p className="modal-rate-description-text">For us is necessary that you rate
                                your experience with the task process and your satisfaction with the assistant work.</p>
                            <br />
                            <p className="modal-rate-indication-text">Please leave a Calification from 1 to 5 stars</p>
                        </div>
                        <div className="modal-rate-rating-box">
                            <Rating
                                name="simple-controlled"
                                value={calification}
                                onChange={(event, newValue) => {
                                    setCalification(newValue);
                                }}
                                size="large"
                            />
                        </div>
                        <div className="modal-rate-observations">
                            <label className="textarea-observations-title">Observations</label>
                            <textarea
                                className="textarea-observations"
                                label="Observations"
                                value={observations}
                                onChange={(event) => {
                                    setObservations(event.target.value);
                                }}
                            />
                        </div>
                        <FormGroup className="modal-rate-checkbox-container">
                            <FormControlLabel className="modal-rate-checkbox" control={<Checkbox onChange={e => setAddAssistant(e.target.checked)} />} label="Add Assistant to your Preferred Assistants." />
                        </FormGroup>
                        <div className="modal-rate-button-container">
                            <Btn
                                buttonClassName="modal-rate-button"
                                onClick={() => handleSendRate()}
                                children="Send"
                            />
                        </div>
                    </div>
                :( 
                    <>
                        <div className="modal-task-upper">
                            <div className="modal-task-header-title">
                                <h2>{selectedTask.taskTitle}</h2>
                            </div>
                            <div className="modal-task-header">
                                <section className="modal-task-header-left">
                                    <div className="modal-task-owner">
                                        <label className="modal-task-label modal-task-owner-text">For: {selectedTask.assistantId != null ? selectedTask.assistantId.name : 'Not asigned task'}</label>
                                    </div>
                                </section>
                                <section className="modal-task-header-right">
                                    <label className="modal-task-label modal-task-created">Created: {createdAt}</label>
                                    <label className="modal-task-label modal-task-status">Status: {selectedTask.status_Id.status_name}</label>
                                    {/* <label className="modal-task-label modal-task-type">Type: {selectedTask.taskType_Id.task_type_name}</label> */}
                                </section>
                            </div>
                        </div>
                        <div className="modal-task-description">
                            <p className="modal-task-description-text">{selectedTask.description}</p>
                        </div>
                        <div className="modal-task-hours-date-limit">
                            <p>
                                {selectedTask.hours && userRole==='Assistant' ? `You have a total of ${timeToHours(selectedTask.hours)} hours to complete this task.`: (selectedTask.status_Id.status_name !=='Done'? `The Assistant has a total of ${timeToHours(selectedTask.hours)} hours to complete this task.`: `This task had a limit of ${timeToHours(selectedTask.hours)} hours. The assistant taked ${isNaN(timeToHours(selectedTask.timeHoursUsed)) ? `0` : timeToHours(selectedTask.timeHoursUsed) } hours to complete this task.`)}{selectedTask.hours && <br />}
                                {!selectedTask.hours && `This task has no time limit to be executed.`}{!selectedTask.hours && <br />}
                                {selectedTask.recurrent_Id != null && `This task is recurrent with the option "${selectedTask.recurrent_Id.description}".`}{selectedTask.recurrent_Id != null && <br />}
                                {selectedTask.dailyLimitHours && selectedTask.recurrent_Id == null && (selectedTask.status_Id.status_name !=='Done' ? `This task is limited to ${selectedTask.dailyLimitHours} hours per day to be executed by the assistant.` : `This task was limited to ${selectedTask.dailyLimitHours} hours per day to be executed by the assistant.`)}{selectedTask.dailyLimitHours && selectedTask.recurrent_Id == null && <br />}
                                {selectedTask.dailyLimitHours && selectedTask.recurrent_Id != null && `This task has ${selectedTask.nRecurrences} recurrences, each with ${selectedTask.dailyLimitHours} hours to be executed by the assistant.`}{selectedTask.dailyLimitHours && selectedTask.recurrent_Id != null && <br />}
                                {selectedTask.date_limit && selectedTask.status_Id.status_name !=='Done' ? `This task is due to be completed by ` : `The Date Limit of this task was: `}{selectedTask.date_limit && <span className="date-limit-popup-info-task">{formatDate(selectedTask.date_limit)}.</span>}{selectedTask.date_limit && <br />}
                                {!selectedTask.date_limit && `This task does not have a deadline for completion`}{!selectedTask.date_limit && <br />}
                            </p>
                        </div>
                        <br />
                        {selectedTask.recurrent_Id != null && recurrences &&
                            <>
                                <div className="modal-task-recurrents">
                                    <h3 className="modal-task-recurrents-title">Recurrences</h3>
                                </div>
                                <div className="modal-task-table-recurrences">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {recurrences.map(rec => (
                                                <tr>
                                                    <td>{rec.taskTitle}</td>
                                                    <td>{format(new Date(rec.dateDisplayed), 'MM/dd/yyyy')}</td>
                                                    <td>{rec.status_Id.status_name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                        {secretsName && (selectedTask.assistant_id !== null && (
                            <section className="modal-task-secrets">
                                <div className="modal-task-secrets-title">
                                    Associated secrets
                                </div>
                                <div className="modal-task-secrets-items">
                                    {secretsName.map((item) => (
                                        <BoxPassword item={item} statusTask={selectedTask.status_Id.status_name} />
                                    ))}
                                </div>
                            </section>
                        ))}
                        {(selectedTask.status_Id.status_name !== 'Done') &&
                            <>
                                <div className="modal-task-progress-bar">
                                    <div className="modal-task-progress-title">Progress: <br /></div>
                                    <div className="modal-task-progress-hours">
                                        <div className="hour-executed"><b>Executed: </b>
                                            {timeUsedHours !== 0 && `${timeUsedHours} Hours `}
                                            {timeUsedHours === 0 && `${timeUsedHours} Hours `}
                                            {timeUsedMinutes !== 0 && `${timeUsedMinutes} Minutes`}
                                        </div>
                                        <div className="maximum-hours-client">{selectedTask.hours && <div><b>Max:</b> {timeToHours(selectedTask.hours)} Hours</div>}</div>
                                    </div>
                                    <Box sx={{ width: '100%', padding: '10px' }}>
                                        <SliderProgress value={normalise(progress)} />
                                    </Box>
                                </div>
                                {selectedTask.assistantId !== null && selectedTask.assistantId !== undefined &&
									<Btn
										buttonClassName="create_Chat_Button"
										onClick={() => {
											handleCreateChat(selectedTask.task_id, selectedTask.assistantId.name, selectedTask.status_Id.status_name);
										}}

									>
										Create chat
									</Btn>
								}
                            </>
                        }
                        {selectedTask.assistant_comment !== null &&
                            <div className="modal-task-description">
                                <p className="modal-task-description-text"><b>Assistant Comment: </b>{selectedTask.assistant_comment}</p>
                            </div>
                        }
                    </>
                )}
            </div>
        </React.Fragment>
    )
}

export { PopupInfoTask };