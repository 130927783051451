import React, { useState, useEffect, useContext } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { GlobalContext } from '../../../context/GlobalContext';
import { Button } from '../../../components/Shared/Button';
import { BtnSelect } from '../../../components/Shared/BtnSelect';
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { useHistory } from "react-router-dom";
import avatar1 from '../../../assets/images/avatars/avatar1.png';
import avatar2 from '../../../assets/images/avatars/avatar2.png';
import avatar3 from '../../../assets/images/avatars/avatar3.png';
import avatar4 from '../../../assets/images/avatars/avatar4.png';
import avatar5 from '../../../assets/images/avatars/avatar5.png';
import avatar6 from '../../../assets/images/avatars/avatar6.png';
const MySwal = withReactContent(Swal);

function PopupChangePhoto( props ) {
    const { onClose } = props;
    const { token, setToken, setEmailGlobal, setIdUserGlobal, setUserName, idUserGlobal, setImgUsuario, setActiveBlur } = useContext(GlobalContext)
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const history = useHistory();
    const [selectAvatar, setSelectAvatar] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);



    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect(() => {
        if (selectAvatar) {
            setSelectedImage(null);
        }
    }, [selectAvatar])

    useEffect(() => {
        if (selectedImage) {
            setSelectAvatar(null);
        }
    }, [selectedImage])

    const handleAvatarClick = (avatar) => {
        if (avatar) {
            setSelectAvatar(avatar);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const handleImageUpload = async () => {

        try {
            
            if (selectedImage !== null) {
                const formData = new FormData();
                formData.append("upload_file", selectedImage);
                formData.append("userId", idUserGlobal);
    
                const response = await consumeFetch(`${API_BASE_URL}/users/uploadAvatar`, {
                    method: "POST",
                    headers: {
                        "Authorization": `${token}`
                    },
                    body: formData,
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message);
                }
    
                setImgUsuario(data.avatar);
    
            } else {
                const response = await consumeFetch(`${API_BASE_URL}/users/${idUserGlobal}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${token}`
                    },
                    body: JSON.stringify({
                        avatar: selectAvatar
                    })
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message);
                }

                setImgUsuario(selectAvatar);
            }
    
            onClose();
            setActiveBlur(true);
            MySwal.fire({
                title: 'Photo was changed!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }
            ).finally(() => {setActiveBlur(false)});
        } catch (error) {
            console.log("Fallo en guardar imagen - ",error);
        }

    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
    };


    return <React.Fragment>
        <div className="contenedor-popup-change-img">
            <div className="upload-photo">
                <h2>Change your profile photo: </h2>
                <div>
                    {!selectedImage && <div className="choose-img-container">
                        <Button children="Upload Photo" buttonClassName="button-charge-img-profile" />
                        <input id="img-input-profile" type="file" accept="image/*" onChange={handleImageChange} />
                    </div>}
                    {selectedImage && (
                        <div>
                            <h4>Selected image: {selectedImage.name}</h4>
                            <Button children="Remove" onClick={handleRemoveImage} buttonClassName="button-charge-img-profile" />
                        </div>
                    )}
                </div>
            </div>
            <div className="choose-avatar">
                <h2>Choose your avatar: </h2>
                <div className="avatares">
                    <div className="fila1-avatares">
                        <BtnSelect
                            key={1}
                            divClassName="select-avatar"
                            buttonClassName={"button-select-avatar" + (selectAvatar === "avatar1" ? " selected" : "")}
                            image={avatar1}
                            imgClassName="avatar-button-icon"
                            onClick={() => handleAvatarClick("avatar1")}>

                        </BtnSelect>
                        <BtnSelect
                            key={2}
                            divClassName="select-avatar"
                            buttonClassName={"button-select-avatar" + (selectAvatar === "avatar2" ? " selected" : "")}
                            image={avatar2}
                            imgClassName="avatar-button-icon"
                            onClick={() => handleAvatarClick("avatar2")}>

                        </BtnSelect>
                        <BtnSelect
                            key={3}
                            divClassName="select-avatar"
                            buttonClassName={"button-select-avatar" + (selectAvatar === "avatar3" ? " selected" : "")}
                            image={avatar3}
                            imgClassName="avatar-button-icon"
                            onClick={() => handleAvatarClick("avatar3")}>

                        </BtnSelect>
                    </div>
                    <div className="fila2-avatares">
                        <BtnSelect
                            key={4}
                            divClassName="select-avatar"
                            buttonClassName={"button-select-avatar" + (selectAvatar === "avatar4" ? " selected" : "")}
                            image={avatar4}
                            imgClassName="avatar-button-icon"
                            onClick={() => handleAvatarClick("avatar4")}>

                        </BtnSelect>
                        <BtnSelect
                            key={5}
                            divClassName="select-avatar"
                            buttonClassName={"button-select-avatar" + (selectAvatar === "avatar5" ? " selected" : "")}
                            image={avatar5}
                            imgClassName="avatar-button-icon"
                            onClick={() => handleAvatarClick("avatar5")}>

                        </BtnSelect>
                        <BtnSelect
                            key={6}
                            divClassName="select-avatar"
                            buttonClassName={"button-select-avatar" + (selectAvatar === "avatar6" ? " selected" : "")}
                            image={avatar6}
                            imgClassName="avatar-button-icon"
                            onClick={() => handleAvatarClick("avatar6")}>

                        </BtnSelect>
                    </div>
                </div>
            </div>
            <div className="save-select-img">
                <Button onClick={handleImageUpload} children="Save" buttonClassName={`button-save-img-profile ${selectAvatar == null && selectedImage == null ? "disabled" : ""}`} inactive={selectAvatar == null && selectedImage == null ? true : false} />
            </div>
        </div>
    </React.Fragment>
}

export { PopupChangePhoto };