import React, { useContext, useState, useEffect } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import Rating from '@mui/material/Rating';
import { GlobalContext } from '../../../context/GlobalContext'
import './PopupRateTask.css'
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { Button } from "../../../components/Shared/Button";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
const MySwal = withReactContent(Swal);

function PopupRateTask(props) {
    const { selectedTask, closePopup } = props;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [calification, setCalification] = useState(0);
    const [observations, setObservations] = useState("");
    const [addAssistant, setAddAssistant] = useState(false);

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const handleSendRate = async() => {
        consumeFetch(`${API_BASE_URL}/tasks/addCalification`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify({
                idTask: String(selectedTask),
                calification: calification,
                observations: observations,
                addAssistant: addAssistant
            })
        }).then(response => response.json()).then(data => {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'success',
                title: 'Success',
                text: 'The rate was saved.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).then(() => {
                closePopup();
            }).finally(() => {setActiveBlur(false)});
        }).catch((err) => {
            console.error(err);
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: 'There was a unexpected error, please contact support.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => {setActiveBlur(false)});
        });
    }

    return (
        <React.Fragment>
            <div className="full-task-modal">
                <div className="modal-rate-title">
                    <h2 className="modal-rate-title-text">Rate your task</h2>
                </div>
                <div className="modal-rate-description">
                    <p className="modal-rate-description-text">For us is necessary that you rate
                    your experience with the task process and your satisfaction with the assistant work.</p> 
                    <br/>
                    <p className="modal-rate-indication-text">Please leave a Calification from 1 to 5 stars</p>
                </div>
                <div className="modal-rate-rating-box">
                    <Rating
                        name="simple-controlled"
                        value={calification}
                        onChange={(event, newValue) => {
                            setCalification(newValue);
                        }}
                        size="large"
                    />
                </div>
                <div className="modal-rate-observations">
                    <label className="textarea-observations-title">Observations</label>
                    <textarea
                        className="textarea-observations"
                        label="Observations"
                        value={observations}
                        onChange={(event) => {
                            setObservations(event.target.value);
                        }}
                    />
                </div>
                <FormGroup className="modal-rate-checkbox-container">
                    <FormControlLabel className="modal-rate-checkbox" control={<Checkbox onChange={e => setAddAssistant(e.target.checked)}/>} label="Add Assistant to your Preferred Assistants." />
                </FormGroup>
                <div className="modal-rate-button-container">
                    <Button
                        buttonClassName="modal-rate-button"
                        onClick={() => handleSendRate() }
                        children="Send"
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export { PopupRateTask };