import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InitialStep } from "./InitialStep";
import { ContactStep } from "./ContactStep";
import { InterestsStep } from "./InterestsStep";
import { TermsStep } from "./TermsStep";
import { OTPStep } from "./OTPStep";
import { GlobalContext } from '../../../context/GlobalContext'
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { StepperRegister } from './StepperRegister'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "./RegisterSteps.css";
const MySwal = withReactContent(Swal);

function RegisterSteps() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { activeBlurRegister ,setActiveBlurRegister } = useContext(GlobalContext)

    const steps = [{ label: "Contact Details" }, { label: "Interests" }, { label: "Terms & Conditions" }, { label: "Verification" }];
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: { errors },
        clearErrors,
        watch,
    } = useForm();

    const SnackbarRef = useRef();

    const history = useHistory();
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [listInterests, setListInterests] = useState([]);

    useEffect(() => {
        setActiveBlurRegister(false);
    },[])

    useEffect(() => {
        async function fetchInterests() {
            const response = await fetch(`${API_BASE_URL}/task_type/`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const responseJson = await response.json();
            const sortingArr = ['Administrative',
            'Customer Support',
            'Graphic Design',
            'Virtual Assistance',
            'Finance Work',
            'Personal Errands',
            'HR Outsourcing',
            'Sales',
            'Marketing',
            'Web or App Development',
            'Other']
            console.log(responseJson.data)
            const sortArray = responseJson.data.sort((a, b) => sortingArr.indexOf(a.taskTypeName) - sortingArr.indexOf(b.taskTypeName));
            setListInterests(sortArray);
        }
        fetchInterests();
    }, [])

    async function onSubmit(data) {

        data.name = data.name + " " + data.lastname;
        try {
            if (!getValues("otp") || String(getValues("otp")).trim() === "") {
                return;
            }
            let dataVerify = {
                otp: getValues("otp"),
                email: getValues("email")
            };
            const verify = await fetch(`${API_BASE_URL}/users/verifyOTP`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataVerify),
            })
            const verifyData = await verify.json();
            if (!verify.ok) {
                SnackbarRef.current.handleSnackbarOpen(verifyData.message, 'error');
                return;
            }
            // append role = Client to the data
            data.roleName = "Client";
            const response = await fetch(`${API_BASE_URL}/users/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message);
            }
            setActiveBlurRegister(true);
            MySwal.fire({
                icon: 'success',
                title: 'User Created!',
                text:  'User was successfully created.',
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }
            ).then(() => {
                history.push("/");
            }).finally(() => {setActiveBlurRegister(false)});;
        } catch (error) {
            if (error.message === "Failed to fetch") {
                SnackbarRef.current.handleSnackbarOpen('Sorry, this should not happened. Try again', 'error');
            }
            if (error.message === "User already exists") {
                SnackbarRef.current.handleSnackbarOpen('Email already in use.', 'error');
            }
        }
    }

    const handleFirstStep = () => {
        setIsFirstStep(!isFirstStep);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <React.Fragment>

            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="full-frame">
                <div className="register-wrap">
                    <form
                        className="form-signup"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        {isFirstStep &&
                            <InitialStep
                                onNext={handleSubmit(handleFirstStep)}
                                register={register}
                                getValues={getValues}
                                watch={watch}
                                errors={errors}
                            />
                        }
                        {!isFirstStep && <div className={`steps-form ${ activeBlurRegister && "blur-class" }`}>
                            <div className="background-people-top">
                            </div>
                            <div className="signup-steps">
                                {activeStep === 0 &&
                                    <ContactStep
                                        register={register}
                                        getValues={getValues}
                                        control={control}
                                        clearErrors={clearErrors}
                                        errors={errors}
                                        setValue={setValue}
                                        onNext={handleSubmit(handleNext)}
                                        onBack={handleFirstStep} 
                                        watch = {watch}
                                        />

                                }
                                {activeStep === 1 &&
                                    <InterestsStep
                                        register={register}
                                        onBack={handleBack}
                                        onNext={handleNext}
                                        setValue={setValue}
                                        getValues={getValues}
                                        listInterests={listInterests}
                                        />
                                }
                                {activeStep === 2 &&
                                    <TermsStep
                                        register={register}
                                        setValue={setValue}
                                        getValues={getValues}
                                        onBack={handleBack}
                                        onNext={handleNext}
                                        clearErrors={clearErrors}
                                        errors={errors} />
                                }
                                {activeStep === 3 &&
                                    <OTPStep
                                        register={register}
                                        setValue={setValue}
                                        getValues={getValues}
                                        onBack={handleBack}
                                        clearErrors={clearErrors}
                                        errors={errors} />
                                }

                            </div>

                            <div className={'signup-stepper'}>
                                <StepperRegister
                                    steps={steps}
                                    activeStep={activeStep}
                                >
                                </StepperRegister>
                            </div>

                        </div>
                        }
                    </form>
                </div>

                {isFirstStep &&
                    <div className="background-people">
                    </div>
                }
            </div>
        </React.Fragment>
    );
}

export { RegisterSteps };
