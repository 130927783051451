import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { GlobalContext } from '../../../context/GlobalContext';
import { ContentHeader } from '../../../components/Shared/ContentHeader'
import { TableAllInvoices } from './TableAllInvoices';
import { SearchComponent } from '../../../components/Shared/SearchComponent';
import './InvoicesSummary.css';

function ViewAllInvoices() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const history = useHistory();
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName } = useContext(GlobalContext)
    const URL = `${API_BASE_URL}/payments/AllPayments`;
    const [invoices, setInvoices] = useState([])
    const [search, setSearch] = useState("")

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)

                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                setActivePage("Home");
                history.push('/');
            }
        }
    }

    const showDataInvoices = async () => {
        const response = await consumeFetch(URL, {
            headers: {
                Authorization: `${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }
        const data = await response.json()
        const newRows = data.map((item, index) => {
            var paymentDate = new Date(item.created_at);
            var userTimezoneOffset = paymentDate.getTimezoneOffset() * 60000;
            paymentDate = new Date(paymentDate.getTime() + userTimezoneOffset);
            const year = paymentDate.getFullYear();
            const month = paymentDate.getMonth() + 1;
            const day = paymentDate.getDate();

            // Formatear la fecha en el formato deseado "MM-DD-YYYY"
            const fechaFormateadaStr = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
            return {
                paymentDate: fechaFormateadaStr,
                ...item
            }
        });
        setInvoices(newRows);
    }

    const searcher = (e) => {
        setSearch(e.target.value)   
    }

    const results = !search ? invoices : invoices.filter((dato)=> dato.client.name.toLowerCase().includes(search.toLocaleLowerCase()))


    useEffect(() => {
        showDataInvoices()
    }, []);

    return <div className="data-invoices-container">
        <ContentHeader text=" Invoices" />
        <SearchComponent search={search} searcher={searcher}/>
        <TableAllInvoices data={results} />
    </div>
}

export { ViewAllInvoices }