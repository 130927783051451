import React, { useState, useRef } from "react";
import { Header } from "../../../components/Shared/Header";
import { Button } from "../../../components/Shared/Button";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import rightArrow from '../../../assets/images/register-right-arrow.png'
import leftArrow from '../../../assets/images/register-left-arrow.png'


function TermsStep(props) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { onNext, register, setValue, getValues, onBack } = props;
    const [isChecked, setIsChecked] = useState(false);
    const SnackbarRef = useRef();

    const handleOnCheck = () => {
        setIsChecked(!isChecked);
        setValue("termsAccepted", !isChecked);
    };

    const OnNextTerms = async () => {
        if (!getValues("termsAccepted")) {
            SnackbarRef.current.handleSnackbarOpen('You must read and accept our Terms and Conditions in order to continue.', 'error');
            return;
        } else {
            let data = {
                email: getValues("email"),
                name: getValues("name"),
                verifyEmail: true
            };
            const response = await fetch(`${API_BASE_URL}/users/sendOTPEmail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message);
            }
            onNext();
        }
    }

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="form-frame">
                <div className="frame-signup">
                    <div className="section-header terms">
                        <Header level={2} headerClassName="subtitle" children="Terms and Conditions" />
                        <div className="section-detail left">
                            <span>Your virtual assistant is getting ready to assist, but before getting started, we need you to agree to our terms and conditions.</span>
                            <br /><br />
                            <span >To finish, please read and agree to our Terms and Conditions</span>
                        </div>
                    </div>
                    <div className="text-terms-conditions">
                        <textarea className="text-area-terms-conditions" disabled readonly>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet quae optio voluptates sapiente non deleniti nulla odio, dicta unde eaque excepturi placeat voluptatum esse temporibus, quos perspiciatis atque laboriosam cumque.
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet quae optio voluptates sapiente non deleniti nulla odio, dicta unde eaque excepturi placeat voluptatum esse temporibus, quos perspiciatis atque laboriosam cumque.
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet quae optio voluptates sapiente non deleniti nulla odio, dicta unde eaque excepturi placeat voluptatum esse temporibus, quos perspiciatis atque laboriosam cumque.
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet quae optio voluptates sapiente non deleniti nulla odio, dicta unde eaque excepturi placeat voluptatum esse temporibus, quos perspiciatis atque laboriosam cumque.
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet quae optio voluptates sapiente non deleniti nulla odio, dicta unde eaque excepturi placeat voluptatum esse temporibus, quos perspiciatis atque laboriosam cumque.
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet quae optio voluptates sapiente non deleniti nulla odio, dicta unde eaque excepturi placeat voluptatum esse temporibus, quos perspiciatis atque laboriosam cumque.
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet quae optio voluptates sapiente non deleniti nulla odio, dicta unde eaque excepturi placeat voluptatum esse temporibus, quos perspiciatis atque laboriosam cumque.
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet quae optio voluptates sapiente non deleniti nulla odio, dicta unde eaque excepturi placeat voluptatum esse temporibus, quos perspiciatis atque laboriosam cumque.
                        </textarea>
                    </div>
                    <div className="accept-terms checkbox-wrapper">
                        <input {...register("termsAccepted")}
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleOnCheck}
                        />
                        <span>I accept the Terms and Conditions and Privacy Policy.</span>
                    </div>
                </div>
            </div>
            <div className="buttons-form interests">
                <Button buttonClassName="button-signup previous"
                    onClick={() => { onBack(); }}
                    children="Go back"
                    image={leftArrow}
                    imgClassName="left-arrow-register"
                    imageAlt="Back Arrow"
                    imageSide="left"
                    three={true}
                />
                <Button
                    buttonClassName="button-signup next"
                    onClick={OnNextTerms}
                    children="Next"
                    image={rightArrow}
                    imgClassName="right-arrow-register"
                    imageAlt="Next Arrow"
                    imageSide="right"
                    three={true}
                />
            </div>
        </React.Fragment>
    );
};

export { TermsStep };
