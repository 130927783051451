import React from 'react'
import imgDefaultUser from '../../../assets/images/programador.png';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import EventIcon from '@mui/icons-material/Event';
import { Tooltip, IconButton } from '@mui/material';
import './Client.css'

export const CuadroClient = (props) => {

    const { cliente, handleClick, handleClickEvent } = props;
    return (
        <div className='cuadroInfo sombra2' style={{background: cliente.bgColor}}>
            <div className='name-container'>
                <div className="client-name">
                    <span>{cliente.nombre}</span>
                </div>
                <div className="icons-container">
                    <div className='button-addTask-container-1'>
                        <Tooltip title="Add Task">
                            <IconButton onClick={(event) => handleClick(event, cliente.correo, cliente.idUser)}>
                                <NoteAddIcon className="button-addTask-preferred-icon" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className='button-addEvent-container-1'>
                        <Tooltip title="Add Event">
                            <IconButton onClick={(event) => handleClickEvent(event, cliente.idUser)}>
                                <EventIcon className="button-addEvent-preferred-icon" />
                            </IconButton>
                        </Tooltip>
                    </div>

                </div>
            </div>
            <div className='img-container'>
                <img className='imgUser' src={imgDefaultUser} alt="usuario" />
            </div>
            <div>
                <p><strong>Company: </strong> {cliente.company}<br/>
                <strong>Phone: </strong> {cliente.telefono}<br/>
                <strong>Email: </strong> {cliente.correo}</p>
            </div>
        </div>
    )
}
