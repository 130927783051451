import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow
} from '@mui/material';
import './InvoicesSummary.css'

function TableAllInvoices(props) {
    const [dataInvoices, setDataInvoices] = useState(props.data);

    useEffect(() => {
        setDataInvoices(props.data);
    }, [props.data]);

    return <div className="table-invoices">
        <TableContainer>
            <Table>
                <TableHead className="invoice-summary-head">
                    <TableRow>
                        {/* <TableCell>Stripe Id</TableCell> */}
                        <TableCell className="table-invoices-headers">Client Name</TableCell>
                        <TableCell className="table-invoices-headers">Client Email</TableCell>
                        <TableCell className="table-invoices-headers">Amount</TableCell>
                        <TableCell className="table-invoices-headers">Payment Date</TableCell>
                        <TableCell className="table-invoices-headers">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="invoice-summary-body">
                    {dataInvoices.map((row) => (
                        <TableRow className={`invoice-summary-row`} key={row.id}>
                            {/* <TableCell>{row.stripeId ? row.stripeId : ''}</TableCell> */}
                            <TableCell>{row.client && row.client.name ? row.client.name : ''}</TableCell>
                            <TableCell>{row.client && row.client.email ? row.client.email : ''}</TableCell>
                            <TableCell>{row.amount ? row.amount : ''}</TableCell>
                            <TableCell>{row.paymentDate ? row.paymentDate : ''}</TableCell>
                            <TableCell>{row.status && row.status.status_name ? row.status.status_name : ''}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
}

export { TableAllInvoices };