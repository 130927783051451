import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { ModalDialog } from '../../../components/Shared/ModalForm';
import { CuadroClient } from './CuadroClient';
import { ContentHeader } from '../../../components/Shared/ContentHeader';
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { Popup } from '../../../components/Shared/Popup';
import { GlobalContext } from '../../../context/GlobalContext';
import { PopupCreateEvent } from './PopupCreateEvent';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

function Client() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
  const history = useHistory();
  const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
  const [clientes, setclientes] = useState();
  const [emailClienteActivo, setEmailClienteActivo] = useState();
  const [isPopupEventOpen, setIsPopupEventOpen] = useState();
  const [idClientSelected, setIdClientSelected] = useState();
  const [allValuesRecurrences, setAllValuesRecurrences] = useState([]);
  const SnackbarRef = useRef();
  const ModalDialogRef = useRef();
  const bgColors = ['#EBBE46', '#B57FFF', '#4285FF', '#DC7E80'];

  const consumeFetch = async (url, options) => {
    try {
      const originalFetch = fetch;
      const fetchWithMiddleware = ApiMiddleware(originalFetch);
      const { response, token } = await fetchWithMiddleware(url, options);
      // Se detecta token nuevo
      if (token) {
        setToken(token)
      }
      return await response;
    } catch (error) {
      if (error.message === "RefreshToken Vencido") {
        console.log(error.message, error)

        setToken("");
        setEmailGlobal("");
        setIdUserGlobal("");
        setUserName("");
        setActivePage("Home");
        history.push('/');
      }
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', alignItems: 'center', width: 150},
    { field: 'taskTitle', headerName: 'Task', alignItems: 'center', width: 150},
    { field: 'description', headerName: 'Description', alignItems: 'center', width: 150},
    { field: 'type_creation', headerName: 'Type Creation', alignItems: 'center', width: 200},
    { field: 'recurrence', headerName: 'Recurrence', alignItems: 'center', width: 150},
    { field: 'dateInitial', headerName: 'Initial Date', alignItems: 'center', width: 150},
    { field: 'dateLimit', headerName: 'Date Limit', alignItems: 'center', width: 150},
    // { field: 'taskTypeName', headerName: 'Task Type', alignItems: 'center', width: 150},
    { field: 'dailyLimitHours', headerName: 'Daily Limit', alignItems: 'center', width: 150},
    { field: 'hours', headerName: 'Hour Limit', alignItems: 'center', width: 150},
];

  const fetchData = async () => {
    let endpoint = `${API_BASE_URL}/preferredAssistants/getByAssistant`;
    const url = `${endpoint}/${idUserGlobal}`;
    try {
      const response = await consumeFetch(url, {
        headers: {
          Authorization: `${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
      const data = await response.json();
      let indexColor = 0;
      const newRows = data.data.map((item, index) => {
        let obj = {
          idUser: item.user.id,
          imagen: "ruta/a/la/imagenPersonalizada.jpg",
          nombre: item.user.name,
          correo: item.user.email,
          telefono: item.user.phone,
          company: item.user.company,
          bgColor: bgColors[indexColor]
        }
        indexColor = indexColor === bgColors.length - 1 ? 0 : indexColor + 1;
        return obj;
      });

      setclientes(newRows);
    } catch (error) {
      console.error(error);
      // Manejar el error
    }
  }

  useEffect(() => {
    fetchData();
    async function getAllValuesRecurrences() {
        const response = await consumeFetch(`${API_BASE_URL}/recurrences/`);
        const dataReturnRec = await response.json();
        setAllValuesRecurrences(dataReturnRec.data.map(item => ({
            description: item.description,
            key: item.key
        }
        )));
    }
    getAllValuesRecurrences();
  }, []);

  const openModal = (pAction) => {
    ModalDialogRef.current.setTitle(pAction + "Task");
    ModalDialogRef.current.handleOpen();
  };

  const handleCreateTask = async (event, emailCliente, idCliente) => {
    let dataReturnedPlans = await consumeFetch(`${API_BASE_URL}/plans/plansByUser/${idCliente}`, {
      headers: {
        Authorization: `${token}`
      }
    });
    dataReturnedPlans = await dataReturnedPlans.json();
    let maxValueHoursDailyLimit = 0;
    let maxValueTasksDailyLimit = 0;
    let valuesPlans = dataReturnedPlans.map((item, index) => {
        if(item.plan.planType === "Virtual Assistant"){
            if(item.plan.planName.indexOf("Task Package") !== -1){
                if(item.plan.dailyHourLimit > maxValueTasksDailyLimit) maxValueTasksDailyLimit = item.plan.dailyHourLimit;
                return "per task";
            }else{
                maxValueHoursDailyLimit = maxValueHoursDailyLimit + item.plan.dailyHourLimit;
                return "per hours";
            }
        }
    });
    for(var i = valuesPlans.length -1; i >=0; i--){
        if(valuesPlans.indexOf(valuesPlans[i]) !== i) valuesPlans.splice(i,1);
    }
    let valuesPlansToSend = valuesPlans.map(item => {
        if(item === "per hours") return {name: item, limit: maxValueHoursDailyLimit}
        if(item === "per task") return {name: item, limit: maxValueTasksDailyLimit}
    })

    const modifiedColumns = columns.map(column => {
        if (column.field === 'id' || column.field === 'assistantEmail' || column.field === 'statusName' || column.field === 'task_id' || column.field === 'calification' || column.field === 'task_id_detail') {
            // Omitir la propiedad 'description'
            return null;
        }
        if (column.field === 'recurrence') {
            column.values = allValuesRecurrences;
            return column;
        }
        if (column.field === 'type_creation') {
            column.values = valuesPlansToSend;
            return column;
        }
        // Conservar las demás propiedades
        return { ...column };
    }).filter(Boolean); // Filtrar los elementos nulos
    const initialModel = modifiedColumns.map(column => {
        // if (column.field === 'taskTypeName' || column.field === 'valuesVault') {
        if (column.field === 'recurrence' || column.field === 'type_creation') {
            // Incluir el campo 'values' en el modelo
            return { ...column, value: '', values: column.values };
        }
        // Conservar las demás propiedades
        return { ...column, value: '' };
    });
    ModalDialogRef.current.setModel(initialModel);
    ModalDialogRef.current.setTypeAction('create');
    openModal("Create ");
    setEmailClienteActivo(emailCliente);
  }

  const handleClickEvent = async (event, idCliente) => {
    event.preventDefault();
    setIdClientSelected(idCliente);
    openPopupEvent();
  }

  const handleAddTask = async (task) => {
    // let date = new Date(task.dateLimit);
    // task.date_limit = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    task.hours = `${Math.round(task.hours)}:00`;
    task.email = emailClienteActivo;
    task.emailAssistant = emailGlobal;
    try {
      let url_endpoint = ''
      if (task.recurrence && task.recurrence !== undefined && task.recurrence !== null) {
        url_endpoint = `${API_BASE_URL}/tasks/createTaskRecurrent/ByAssistantPreferred`;
      } else {
        url_endpoint = `${API_BASE_URL}/tasks/createTask/ByAssistantPreferred`;
      }
      const response = await consumeFetch(url_endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        },
        body: JSON.stringify(task)
      });
      if (response.ok) {
        setActiveBlur(true);
        MySwal.fire({
          icon: 'success',
          title: 'Success',
          text: 'You have created a new task for your client, this task must be approved by him, once the client approves the task you will receive an email!',
          showConfirmButton: true,
          backdrop: true,
          customClass: {
            popup: 'popup-sweet',
            title: 'title-sweet',
            htmlContainer: 'text-sweet',
            confirmButton: 'confirm-button-sweet',
            denyButton: 'deny-button-sweet',
          }
        }).finally(() => { setActiveBlur(false) });
      } else {
        setActiveBlur(true);
        MySwal.fire({
          icon: 'error',
          title: 'Oops ...',
          text: 'There was a unexpected error, please contact support.',
          showConfirmButton: false,
          timer: 2000,
          backdrop: true,
          customClass: {
            popup: 'popup-sweet',
            title: 'title-sweet',
            htmlContainer: 'text-sweet',
            confirmButton: 'confirm-button-sweet',
            denyButton: 'deny-button-sweet',
          }
        }).finally(() => { setActiveBlur(false) });

      }

    } catch (error) {
      console.error(error);
      setActiveBlur(true);
      MySwal.fire({
        icon: 'error',
        title: 'Oops ...',
        text: 'There was a unexpected error, please contact support.',
        showConfirmButton: false,
        timer: 1700,
        backdrop: true,
        customClass: {
          popup: 'popup-sweet',
          title: 'title-sweet',
          htmlContainer: 'text-sweet',
          confirmButton: 'confirm-button-sweet',
          denyButton: 'deny-button-sweet',
        }
      }).finally(() => { setActiveBlur(false) });
    }
  };

  function openPopupEvent() {
    setIsPopupEventOpen(true);
    setActiveBlur(true)
  }

  function closePopupEvent() {
    setIsPopupEventOpen(false);
    setActiveBlur(false);
  }

  return (
    <React.Fragment>
      <ModalDialog
        open={ModalDialogRef.open}
        handleClose={ModalDialogRef.handleClose}
        ref={ModalDialogRef}
        onAddItem={handleAddTask}
      />
      {clientes && (
        <>
          <div className="Client-header" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '20px', gap: '10px', flex: 'none', order: '0', alignSelf: 'stretch', flexGrow: '0' }}>
            <ContentHeader text=" My Clients" />
          </div>
          <div className='profile' style={{ display: 'flex', gap: '20px', rowGap: '40px', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center', padding: '20px' }}>
            {clientes.map(
              option => <div key={option.idUser} style={{}}><CuadroClient
                cliente={option}
                handleClick={handleCreateTask}
                handleClickEvent={handleClickEvent}
              /></div>
            )}
          </div>
          <Popup isOpen={isPopupEventOpen} onClose={closePopupEvent} modalClass="popup-info-task" isUpLessThirty={true}>
            <PopupCreateEvent idClient={idClientSelected} closePopup={closePopupEvent} fetchDataTask={fetchData} />
          </Popup>
        </>
      )}
    </React.Fragment>
  );
}

export { Client };
