import { Home } from '../pages/clientContent/home';
import { Assistant } from '../pages/clientContent/assistant';
import { Tasks } from '../pages/clientContent/tasks';
import { Contracts } from '../pages/clientContent/contracts';
import { Billings } from '../pages/clientContent/billings';
import { CalendarClient } from '../pages/clientContent/calendar';
import { Vault } from '../pages/clientContent/profile/vault';
import { AiFillHome} from 'react-icons/ai';
import { FaUserAstronaut, FaTasks, FaBriefcase } from 'react-icons/fa';
import { BsCalendarDate } from 'react-icons/bs';
import { BsFillBagCheckFill } from 'react-icons/bs';

import { MdPayments } from 'react-icons/md';

const generalMainOptions = [
    { name: 'Billings', component: <Billings />, icon: <MdPayments />, show: true },
    { name: 'Plans', component: <Contracts />, icon: <FaBriefcase />, show: true },
]

const noPlanOptions = [
{ name: 'Home', component: <Home />, icon: <AiFillHome />, show: true },
]

const clientOptionsVAssistant = [
    { name: 'Home', component: <Home />, icon: <AiFillHome />, show: true },
    { name: 'Assisttus', component: <Assistant />, icon: <FaUserAstronaut />, show: true },
    { name: 'Tasks', component: <Tasks />, icon: <FaTasks />, show: true },
    { name: 'Calendar', component: <CalendarClient />, icon: <BsCalendarDate />, show: true },
    { name: 'Vault', component: <Vault />, icon: <BsFillBagCheckFill />, show: true },
];

const clientOptionsVOffice = [
];



export { generalMainOptions, clientOptionsVAssistant, clientOptionsVOffice, noPlanOptions };
