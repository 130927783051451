import React, { useContext, useState } from "react";
import "./ContractCard.css"
import { AiFillCheckCircle } from "react-icons/ai";
import { Button } from "../../../components/Shared/Button";

function ContractCard(props) {

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const { cardTitle, subdescription, featuresList, handleSubmit, bgColor, description, boldDescription, subDescription } = props;
    return (
        <React.Fragment>
            <div className="full-card-pricing" style={{ background: bgColor }}>
                <div className="full-price-info">
                    <div className="plan-header-container">
                        <span className="card-contract-title">{cardTitle}</span>
                        <div className="feature-text">{description}</div>
                    </div>
                    <div className="features-list-container">
                        <div className="features-list">
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Administrative.</div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Marketing.</div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Sales.</div>
                            </div>
                        </div>
                        <div className="features-list">
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Customer Support.</div>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <AiFillCheckCircle color="#004752" />
                                </div>
                                <div className="feature-text">Finance Work.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="feature-text" >{subDescription}</div>
                <div className="feature-text">
                    <label>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        I agree to the {cardTitle} <a href="https://assisttu.com/"><u>Terms and Conditions</u></a>.
                    </label>
                </div>
                <div className="button-subscribe-container">
                    {subdescription?.map((item, index) => {
                        return (
                            <div className="price-subdescription">
                                <div className="feature-text"><b>{item.bloddesc}</b>{item.desc}</div>
                                <Button
                                    type="submit"
                                    onClick={event => handleSubmit(event, item.value, item.planName, item.planType)}
                                    buttonClassName="button-subscribe"
                                    children={item.price}
                                    inactive={!isChecked}
                                />
                            </div>
                        );
                    })}

                </div>
                {boldDescription?.map((item, index) => {
                    return (
                        <div className="feature-text-bold" >{item}</div>
                    );
                })}

            </div>
        </React.Fragment>
    );
}

export { ContractCard };
