import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Shared/Button";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { InputOTP } from "./OPTStep/InputOTP";
import "./InitialStep.css";
import logo from '../../../assets/images/logos/logo-assisttu-green-2.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

function ForgotPassword(props) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const SnackbarRef = useRef();
    const [ emailRequired, setEmailRequired ] = useState("");
    const [ inactiveButtonFinish, setInactiveButtonFinish ] = useState(true);
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();
    const history = useHistory();

    const validateUser = async () => {
        const response = await fetch(`${API_BASE_URL}/users/exist/${getValues("email")}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.ok) {
            const response_Send = await fetch(`${API_BASE_URL}/users/sendOTPEmail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: getValues("email"),
                    verifyEmail: false
                }),
            })
            const responseData = await response_Send.json();
            if (!response_Send.ok) {
                throw new Error(responseData.message);
            }else{
                setEmailRequired(getValues("email"));
                SnackbarRef.current.handleSnackbarOpen('OTP Code sent to your email', 'success');
            }
        } else if (response.status === 404) {
            SnackbarRef.current.handleSnackbarOpen('This email is not registered in Assisttu', 'error');
        }else {
            console.log(response);
            SnackbarRef.current.handleSnackbarOpen('An error has occurred sending the code, please contact support!', 'error');
        }
    }

    const changePassword = async () => {
        if (getValues("password") !== getValues("verifyPassword")) {
            SnackbarRef.current.handleSnackbarOpen("The passwords do not match", 'error');
            return;
        }
        if (!getValues("otp") || String(getValues("otp")).trim() === "") {
            SnackbarRef.current.handleSnackbarOpen("OTP Code is required", 'error');
            return;
        }
        if (!getValues("password").match(/^(?=.*[0-9!@#$%^&*])[\S]{6,}$/)) {
            SnackbarRef.current.handleSnackbarOpen("Your password must be at least 6 characters long and include a number or special character.", 'error');
            return;
        }
        const response = await fetch(`${API_BASE_URL}/users/verifyOTP`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                otp:getValues("otp"),
                email: emailRequired,
                password:getValues("password")
            }),
        });
        const data = await response.json();
        if (response.ok) {
            MySwal.fire({
                icon: 'success',
                title: 'Password Updated!',
                text:  'User password was successfully updated.',
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }
            ).then(() => {
                history.push("/");
            });
        } else {
            if(data.message){
                SnackbarRef.current.handleSnackbarOpen(data.message, 'error');
            }else{
                SnackbarRef.current.handleSnackbarOpen('An error has occurred, please contact support!', 'error');
            }
        }
    }

    function sendInactiveBtnFinish(value) {
        setInactiveButtonFinish(value);
    }

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="full-frame">
                <div className="register-wrap">
                    <div className="form-change-password">
                        <div className="signup-frame-initial-step">
                            <div className="hero-banner">
                                <img className='logo-hero'
                                    src={logo}
                                    alt='Assisttu Logo' ></img>
                            </div>
                            <div className="frame-initial-step">
                                <div className="signup-stepper">
                                    <span className="subtitle">Please complete the information below</span>
                                </div>
                                <div className="content-wrap">
                                {emailRequired === "" ? 
                                    <div className="form-register-first-step">
                                        <div className="form-group-input">
                                            <div>
                                                <input
                                                    className={`register-input ${errors.email && 'error'}`}
                                                    placeholder="Email"
                                                    type="text"
                                                    {...register(
                                                        "email",
                                                        {
                                                            required: "Email is required",
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "Invalid email address"
                                                            }
                                                        }
                                                    )}
                                                />
                                                {errors.email && <span className="error-message">{errors.email.message}</span>}
                                            </div>
                                        </div>
                                        <Button
                                            buttonClassName="button-signup-first-step"
                                            onClick={validateUser}
                                            children="Send Code"
                                        />
                                    </div>
                                    : 
                                    <div className="form-register-first-step"> 
                                        <div className="form-group-input">
                                            <div>
                                                <input
                                                    className={`register-input ${errors.password && 'error'}`}
                                                    placeholder="New Password"
                                                    type="password"
                                                    {...register(
                                                        "password",
                                                        {
                                                            required: "Password is required",
                                                            pattern: {
                                                                value: /^(?=.*[0-9!@#$%^&*])[\S]{6,}$/,
                                                                message: "Your password must be at least 6 characters long and include a number or special character."
                                                            }
                                                        }
                                                    )}
                                                />
                                                {errors.password && <span className="error-message">{errors.password.message}</span>}
                                            </div>
                                            <div>
                                                <input
                                                    className={`register-input ${errors.verifyPassword && 'error'}`}
                                                    placeholder="Verify New Password"
                                                    type="password"
                                                    {...register("verifyPassword", {
                                                        required: "Password is required",
                                                        validate: (value) => value === watch('password') || "The passwords do not match"
                                                    })}
                                                />
                                                {errors.verifyPassword && <span className="error-message">{errors.verifyPassword.message}</span>}
                                            </div>
                                            <div className="OTP-password">
                                                <InputOTP setValue={setValue} setInactive={sendInactiveBtnFinish}/>
                                            </div>
                                        </div>
                                        <Button
                                            buttonClassName={`button-signup-first-step ${inactiveButtonFinish && "inactive" }`}
                                            onClick={changePassword}
                                            children="Change Password"
                                            inactive={inactiveButtonFinish}
                                        />
                                    </div>
                                }
                                    <div className="login-ref">
                                        <span className="login-text">Already have an account?&nbsp;<a className="login-links" href="/"> Login here</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="background-people">
                </div>
            </div>
        </React.Fragment>
    );
}

export { ForgotPassword };
