import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { GlobalContext } from '../../../context/GlobalContext';
import './notifications.css';
import ApiMiddleware from "../../Shared/ApiMiddleware";
import noMessagesImg from '../../../assets/images/no-messages.png';
import happy_face_img from '../../../assets/images/happy-face.png';
import itching_face_img from '../../../assets/images/itching-face.png';

const MySwal = withReactContent(Swal);

function Notifications(props) {
    const {onClose} = props;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setShowNotificationsBadge, setToken, token, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName } = useContext(GlobalContext);
    const history = useHistory();
    const [dataNotifications, setDataNotifications] = useState([]);
    const url = `${API_BASE_URL}/notifications/getNotifications/${idUserGlobal}`;
    const [dataImage, setDataImage] = useState([happy_face_img, itching_face_img]);

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const fetchData = async () => {
        consumeFetch(url, {
            headers: {
                Authorization: `${token}`
            }
        }).then(response => response.json()).then(data => {
            const newRows = data.data.map((element, index) => {
                let obj = {
                    id: element.id,
                    text: element.text,
                    created_at: element.created_at,
                    redirectTo: element.redirectTo,
                    icon: dataImage[randomIntFromInterval(0,1)]
                };
                return obj;
            });
            setDataNotifications(newRows);
        }).catch(error => {
            console.error(error);
        });
    }

    const markAsRead = async () => {
        const params = new URLSearchParams();
        let endpoint = `${API_BASE_URL}/notifications/markAsRead`;
        params.append('userId', idUserGlobal);
        const url = `${endpoint}?${params}`;
        consumeFetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const randomIntFromInterval = (min, max) => { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    };

    const handleClickNotification = (redirectTo) => { 
        switch(redirectTo){
            case "Tasks":
                history.push("/user/Tasks");
                break;
            case "Messages":
                history.push("/user/MessagesWindow");
                break;
            case "MessagesAdmin":
                history.push("/user/PM");
                break;
            case "MessagesSupport":
                history.push("/user/Bruno%(Support)");
                break;
            case "Home":
                history.push("/user/Home");
                break;
            case "MyTasks":
                history.push("/user/My%20Tasks");
                break;
            default:
                break;
        }
        onClose();
    };

    useEffect(() => {
        fetchData();
        markAsRead();
        return () => {
            
        }
    }, []);

    return (
        <div className="NotificationsWindow">
            {dataNotifications.length > 0 &&
                <div className='NotificationsDisplay'>
                    {dataNotifications.map(element => 
                        <div className='NotificationMessage' onClick={() => handleClickNotification(element.redirectTo)}>
                            <span className="NotificationDate">{`${new Date(element.created_at).getMonth()+1}/${new Date(element.created_at).getDate()}`}</span>
                            <div className="NotificationMessageText">
                                <span className='NotificationText'>{element.text}</span>
                                <img alt='notificationIcon' className='NotificationIcon' src={element.icon}/>
                            </div>
                        </div>
                    )}
                </div>
            // : 
            //     <img src={noMessagesImg} width='100%'></img>
            }
        </div>
    );
}

export { Notifications };
