import React, { useState, useRef, useMemo, useContext, useEffect } from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";

import { Button, Button as ButtonShared } from "../../../components/Shared/Button";
import { Button as MuiButton } from '@mui/material';
import { BiSolidImageAdd } from 'react-icons/bi';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import countryList from "react-select-country-list";

import './Profile.css';
import 'react-phone-number-input/style.css'
import "react-datepicker/dist/react-datepicker.css";

import imgDefaultUser from '../../../assets/images/default_user.png';
import { ContentHeader } from '../../../components/Shared/ContentHeader';
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { Select } from "../../../components/Shared/Select";
import { PopupChangePhoto } from './PopupChangePhoto';

import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { Popup } from '../../../components/Shared/Popup';
import avatar1 from '../../../assets/images/avatars/avatar1.png';
import avatar2 from '../../../assets/images/avatars/avatar2.png';
import avatar3 from '../../../assets/images/avatars/avatar3.png';
import avatar4 from '../../../assets/images/avatars/avatar4.png';
import avatar5 from '../../../assets/images/avatars/avatar5.png';
import avatar6 from '../../../assets/images/avatars/avatar6.png';

function CuadroProfile(props) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setToken, token, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName, imgUsuario, setImgUsuario } = useContext(GlobalContext)
    const history = useHistory();
    const { data } = props;
    const [gender, setGender] = useState();
    const [country, setCountry] = useState();
    const [startDate, setStartDate] = useState(new Date(data.birthDate).setUTCHours(5));
    const [dataUser, setDataUser] = useState();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const allAvatar = {
        "avatar1": avatar1,
        "avatar2": avatar2,
        "avatar3": avatar3,
        "avatar4": avatar4,
        "avatar5": avatar5,
        "avatar6": avatar6,
    }

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    //Mapper data Usuario
    useEffect(() => {
        const includedKeys = ["birthdate", "company", "country", "name", "phone", "avatar"];
        const filteredDataUser = Object.keys(data)
            .filter(key => includedKeys.includes(key.toLowerCase()))
            .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
            }, {});
        setDataUser(filteredDataUser);
        let search = "gender";
        if (search in filteredDataUser) {
            setGender(filteredDataUser[search]);
        }
        search = "country";
        if (search in filteredDataUser) {
            setCountry(filteredDataUser[search]);
        }
        search = "avatar";
        if (search in filteredDataUser) {
            setImgUsuario(filteredDataUser[search])
        }
    }, [data])

    const genderOptions = [
        { value: "", label: "Select a gender" },
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Other", label: "Other" }
    ];

    let countryOptions = useMemo(() => countryList().getData(), []);
    countryOptions = [{ value: "", label: "Select a country" }, ...countryOptions]

    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setValue("gender", event.target.value);

    };

    const handleCountryChange = (event) => {
        if (event.target.value !== "") {
            setCountry(event.target.value);
            setValue("country", event.target.value);
            clearErrors("country");
        } else {
            setCountry(event.target.value);
            setValue("country", event.target.value);
        }
    };



    const handleDateChange = (date) => {
        if (date !== null) {
            setStartDate(date);
            setValue("birthDate", date);
            if (errors) {
                clearErrors("birthDate");
            }
        }
        setStartDate(date);
        setValue("birth", date);
    };

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        clearErrors
    } = useForm();

    const SnackbarRef = useRef();

    async function onSubmit(data) {
        consumeFetch(`${API_BASE_URL}/users/${idUserGlobal}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (res.ok) {
                    SnackbarRef.current.handleSnackbarOpen('Updated Information!!', 'success');
                } else {
                    SnackbarRef.current.handleSnackbarOpen('Error!!', 'error');
                }
                // props.onClose();
            })
            .catch((err) => {
                console.log(err);
            });
    }



    function openPopup() {
        setIsPopupOpen(true);
    }

    function closePopup() {
        setIsPopupOpen(false);
    }

    const validatePhoneLength = (value) => {
        console.log(value)
        if (value.length <= 10) {
            return false;
        } else {
            return true;
        }
    };

    //Fecha hace 18 años, para fecha de nacimiento
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    return (
        <>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="Profile-header">
                <ContentHeader text="Profile" />
            </div>
            <div className="profile-container">
                <div className="content">
                    <section className='section panel-profile'>
                        <div className="image">
                            <div div className='imgUserProfile'>
                                <img className='foto-user-profile' src={`${imgUsuario && imgUsuario !== null && imgUsuario.includes("s3.amazonaws.com") ? imgUsuario : allAvatar[imgUsuario] ? allAvatar[imgUsuario] : imgDefaultUser}`} alt="usuario" />
                                <div className="button-change-img">
                                    <Button buttonClassName="imgUser-button-submit" onClick={openPopup} icon={<BiSolidImageAdd />} iconClassName="icon-button-change-img"></Button>
                                </div>
                            </div>
                        </div>

                    </section>

                    <form className="form-profile" onSubmit={handleSubmit(onSubmit)}>
                        <section className="section right-panel-profile">
                            {dataUser && Object.entries(dataUser).map(([key, value]) => {
                                if (key === "phone") {
                                    return (
                                        <div key={key} className="section-input-profile info">
                                            <label className='title-input-profile'>{key}</label>
                                            <div className="input-profile" style={{ width: '100%', display: 'flex' }}>
                                                <PhoneInputWithCountry
                                                    className={`data-profile-input ${errors.phone && 'error'}`}
                                                    placeholder="Phone"
                                                    defaultValue={value}
                                                    defaultCountry="US"
                                                    name="phone"
                                                    control={control}
                                                    rules={{
                                                        required: 'Phone is required',
                                                        validate: {
                                                            phoneLength: (value) => validatePhoneLength(value) || 'Invalid phone number length',
                                                        },
                                                    }}
                                                />
                                                {errors.phone && <span className="error-message">{errors.phone.message}</span>}
                                            </div>
                                        </div>
                                    )
                                } else if (key === "birthDate") {
                                    return (
                                        <div key={key} className="section-input-profile info">
                                            <label className='title-input-profile'>{key}</label>
                                            <div className="input-profile" style={{ width: '100%', display: 'flex' }}>
                                                <Controller
                                                    name="birthDate"
                                                    defaultValue={value}
                                                    control={control}
                                                    rules={{ required: "Birth date is required" }}
                                                    render={() => (
                                                        <DatePicker
                                                            className={`data-profile-input ${errors.birthDate && 'error'}`}
                                                            placeholderText="Birth date"
                                                            selected={startDate}
                                                            maxDate={eighteenYearsAgo}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            onChange={handleDateChange} />
                                                    )}
                                                />
                                                {errors.birthDate && <span className="error-message">{errors.birthDate.message}</span>}

                                            </div>
                                        </div>
                                    )
                                } else if (gender && key === "gender") {
                                    return (
                                        <div key={key} className="section-input-profile info">
                                            <label className='title-input-profile'>{key}</label>
                                            <div className="input-profile" style={{ width: '100%', display: 'flex' }}>
                                                <Select {...register("gender", { required: "Genre is required" })}
                                                    options={genderOptions}
                                                    className={`data-profile-input select-input ${gender !== "" ? 'select-input-choosen' : ''} ${errors.gender ? 'error' : ""}`}
                                                    value={gender}
                                                    handleOnChange={handleGenderChange}
                                                />
                                                {errors.gender && <span className="error-message">{errors.gender.message}</span>}
                                            </div>
                                        </div>
                                    )
                                } else if (country && key === "country") {
                                    return (
                                        <div key={key} className="section-input-profile info">
                                            <label className='title-input-profile'>{key}</label>
                                            <div className="input-profile" style={{ width: '100%', display: 'flex' }}>
                                                <Select {...register("country", { required: "Country is required" })}
                                                    options={countryOptions}
                                                    value={country}
                                                    className={`data-profile-input select-input ${country !== "" ? 'select-input-choosen' : ''} ${errors.country ? 'error' : ""}`}
                                                    handleOnChange={handleCountryChange}
                                                />
                                                {errors.country && <span className="error-message">{errors.country.message}</span>}
                                            </div>
                                        </div>
                                    )
                                } else if (key === "role") {
                                    return (
                                        <div key={key} className="section-input-profile info">
                                            <label className='title-input-profile'>{key}</label>
                                            <div className="input-profile" style={{ width: '100%', display: 'flex' }}>
                                                <input
                                                    className={`data-profile-input`}
                                                    placeholder={`My ${key}`}
                                                    type="text"
                                                    defaultValue={key === "role" ? value.role_name : value.status_name}
                                                    readOnly={key === "role" || key === "Plan" || key === "Score"}
                                                    {...register(key, { required: `${key} is required` })}
                                                />
                                                {errors[key] && <span className="error-message">{errors[key].message}</span>}
                                            </div>
                                        </div>
                                    )
                                } else if (key !== "avatar") {
                                    return (
                                        <div key={key} className="section-input-profile info">
                                            <label className='title-input-profile'>{key}</label>
                                            <div className="input-profile" style={{ width: '100%', display: 'flex' }}>
                                                <input
                                                    className={`data-profile-input`}
                                                    placeholder={`My ${key}`}
                                                    type="text"
                                                    defaultValue={value}
                                                    readOnly={key === "role" || key === "planId" || key === "Score"}
                                                    {...register(key, { required: `${key} is required` })}
                                                />
                                                {errors[key] && <span className="error-message">{errors[key].message}</span>}
                                            </div>
                                        </div>
                                    )
                                }
                            }
                            )}
                            <div className="button-save-profile">
                                <ButtonShared
                                    type="submit"
                                    buttonClassName="button-save"
                                    children="Save changes"
                                />
                            </div>
                        </section>
                    </form>
                    <Popup isOpen={isPopupOpen} onClose={closePopup} modalClass="popup-change-img-profile">
                        <PopupChangePhoto onClose={closePopup} />
                    </Popup>
                </div>
            </div>
        </>
    )
}


export { CuadroProfile };
