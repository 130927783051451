import PeopleIcon from '@mui/icons-material/People';
import { FaUserAstronaut, FaTasks } from 'react-icons/fa';
import { AllClients } from '../pages/adminContent/clients';
import { AllAsisstants } from '../pages/adminContent/assistants';
import { ViewAllTasks } from '../components/Shared/tasks';
import { Chat } from '../components/messages/window/chat-window';
import { SlEnvolope } from 'react-icons/sl';
import { ViewTasksToApprove } from '../pages/adminContent/tasksToApprove';

const adminMainOptions = [
    { name: 'Clients', component: <AllClients/>  ,icon: <PeopleIcon/>, show: true },
    { name: 'Asisstants', component: <AllAsisstants/>  ,icon: <FaUserAstronaut />, show: true },
    { name: 'Task Summary', component: <ViewAllTasks/>  ,icon: <FaTasks />, show: true },
    { name: 'Approve Tasks', component: <ViewTasksToApprove/>  ,icon: <FaTasks />, show: true },
    { name: 'Messages Admin', component: <Chat/>  ,icon: <SlEnvolope />, show: true }

];

export { adminMainOptions };