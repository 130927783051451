import React, { useEffect, useState, useContext} from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import socket from "../../../../utils/socket";
import { GlobalContext } from '../../../../context/GlobalContext'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Header } from "../../../../components/Shared/Header";
import { Button } from "../../../../components/Shared/Button";
import { Table } from "./tableVault/index"
import ApiMiddleware from "../../../../components/Shared/ApiMiddleware";
import "./vault.css";
import { ContentHeader } from "../../../../components/Shared/ContentHeader";
const MySwal = withReactContent(Swal);


function Vault() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName } = useContext(GlobalContext)
    const history = useHistory();
    const [data, setData] = useState();
    const [newData, setNewData] = useState({ id: null, name: "", password: "" });
    const [editing, setEditing] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showMessageErrorName , setShowMessageErrorName] = useState(false);
    const [showMessageErrorPass , setShowMessageErrorPass] = useState(false);

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect (()=>{
        consumeFetch(`${API_BASE_URL}/vault/getSecretsByUser/${emailGlobal}`,{
            headers: {
                Authorization: `${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const mappedVaultData = data.data.map((item, index) => {
                return {
                  id: index + 1,
                  name: item.name_secret,
                  password: ""
                };
              });
            setData(mappedVaultData);
        })
        .catch((err) => {
            console.error(err);
        });
    },[])


      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewData({ ...newData, [name]: value });
      };

      const handleAdd = () => {
        if (newData.name.trim() === '') {
            setShowMessageErrorName(true);
            return
        }
        if (newData.password.trim().length <= 3) {
            setShowMessageErrorPass(true);
            return
        }
        const request = {
            email_user: emailGlobal,
            secret_name: newData.name,
            secret_value: newData.password
        };
        consumeFetch(`${API_BASE_URL}/vault`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify(request),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la solicitud de creacion de vault');
            }
            return response.json();
        })
        .then(response => {
            const id = data.length + 1;
            setData([...data, { ...newData, id }]);
            setNewData({ id: null, name: "", password: "" });
            setShowMessageErrorName(false);
            setShowMessageErrorPass(false);
        })
        .catch((err) => {
            console.error(err);
        });

      };

      const handleEdit = (id) => {
        const itemsito = data.find((item) => item.id === id);
        const params = new URLSearchParams();
        const endpoint = `${API_BASE_URL}/vault/getSecretValue`;
        params.append('email_user', emailGlobal);
        params.append('secret_name', itemsito.name);
        const url = `${endpoint}?${params}`;
        consumeFetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error en la solicitud de getSecretValue');
            }
            return response.json();
        }).then(data => {
            itemsito.password = data.secret;
            setEditing(true);
            setNewData(itemsito);

        })
        .catch((err) => {
            console.log(err);
        });
      };

      const handleUpdate = () => {
        if (newData.name.trim() === '') {
            setShowMessageErrorName(true);
            return
        }
        if (newData.password.trim() === '') {
            setShowMessageErrorPass(true);
            return
        }
        const request = {
            email_user: emailGlobal,
            secret_name: newData.name,
            secret_value: newData.password
        };
        consumeFetch(`${API_BASE_URL}/vault/`,{
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify(request),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la solicitud de edición de vault');
            }
            return response.json();
        })
        .then(response => {
            setData(data.map((item) => (item.id === newData.id ? newData : item)));
            setNewData({ id: null, name: "", password: "" });
            setEditing(false);
        })
        .catch((err) => {
            console.error(err);
        });
      };

      const handleDelete = (id) => {
        const params = new URLSearchParams();
        const endpoint = `${API_BASE_URL}/vault`;
        params.append('email_user', emailGlobal);
        params.append('secret_name', data.filter((item) => item.id === id).map((item) => item.name)[0]);
        const url = `${endpoint}?${params}`;
        consumeFetch(url,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la solicitud de creacion de vault');
            }
            setNewData({ id: null, name: "", password: "" });
            return response.json();
        })
        .then(response => {
            setData(data.filter((item) => item.id !== id));
        })
        .catch((err) => {
            console.error(err);
        });
      };

      const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
      };


    return (
        <React.Fragment>
            <div className="vault">
                <section className="title-vault">
                    <ContentHeader text="My Vault" />
                </section>
                <div className="vault-options">
                    <section className="form-vault">
                        <form className="form-vault">
                            <div className="inputs-form-vault">
                                <div className="input-vault-name">
                                    <label>Name</label>
                                    <div>
                                        <input
                                            className={`data-vault-input`}
                                            defaultValue=""
                                            type="text"
                                            name="name"
                                            value={newData.name}
                                            onChange={handleInputChange}
                                            maxLength="25"
                                            required
                                        />
                                        {showMessageErrorName && <span className="error-message-vault" >Name is required!</span>}
                                    </div>
                                </div>
                                <div className="input-vault-pass">
                                    <label>Password</label>
                                    <div className="input-vault-password">
                                        <div>
                                            <input
                                                className={`data-vault-input`}
                                                defaultValue=""
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={newData.password}
                                                onChange={handleInputChange}
                                                required
                                                // {...register("pass", {required: `Password is required`})}
                                            />
                                            {showMessageErrorPass && <span className="error-message-vault" >Password must be at lest 4 characters!</span>}
                                        </div>
                                        <span className="icon-vault-visibility" onClick={handleTogglePassword}>
                                            {showPassword ? <VisibilityIcon />:<VisibilityOffIcon/>}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-form-vault">
                                {editing ? (
                                    <Button
                                        buttonClassName="button-submit vault-button"
                                        children="Update"
                                        onClick={handleUpdate}
                                    />
                                ):(
                                    <Button
                                        buttonClassName="button-submit vault-button"
                                        children="Save"
                                        onClick={handleAdd}
                                    />
                                )}
                            </div>
                        </form>
                    </section>
                    <section className="vault-table-section">
                        <div className="vault-table">
                            <Table data={ data }
                                    handleEdit= {handleEdit}
                                    handleDelete= {handleDelete}
                                    editing={editing}
                                    newData={newData}
                                    handleInputChange={handleInputChange}
                                    handleUpdate={handleUpdate}
                                    handleAdd={handleAdd}
                            />
                        </div>
                    </section>
                </div>
                <div className="bk-vault"></div>
            </div>
        </React.Fragment>
    )
}

export { Vault };