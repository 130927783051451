import { Chat } from '../components/Navbar/messages/chat/index';
import { Chat as ChatWindow } from '../components/messages/window/chat-window';

import { Notifications } from '../components/Navbar/notifications';
import { Profile } from '../pages/clientContent/profile/Profile';

import { BiTask} from 'react-icons/bi';
import { RiNotification3Fill } from 'react-icons/ri';
import { CgProfile } from 'react-icons/cg';

const mobileOptions = [
    { name: 'Messages', component: <Chat />, icon: <BiTask />, show: true },
    { name: 'MessagesWindow', component: <ChatWindow />, icon: <BiTask />, show: false },
    { name: 'Notifications', component: <Notifications />, icon: <RiNotification3Fill />, show: true },
    { name: 'Profile', component: <Profile />, icon: <CgProfile />, show: true },
];

export { mobileOptions }
