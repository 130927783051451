import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalContext'
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import socket from "../../../utils/socket";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { CuadroProfile } from './CuadroProfile';

import ApiMiddleware from '../../../components/Shared/ApiMiddleware';
const MySwal = withReactContent(Swal);

function Profile() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
  const history = useHistory();
  const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName } = useContext(GlobalContext)

  const [userData, setUserData] = useState("");
  const [error, setError] = useState(false);

  const consumeFetch = async (url, options) => {
    try {
      const originalFetch = fetch;
      const fetchWithMiddleware = ApiMiddleware(originalFetch);
      const { response, token } = await fetchWithMiddleware(url, options);
      // Se detecta token nuevo
      if (token) {
        setToken(token)
      }
      return await response;
    } catch (error) {
      if (error.message === "RefreshToken Vencido") {
        console.log(error.message,error)
        setToken("");
        setEmailGlobal("");
        setIdUserGlobal("");
        setUserName("");
        history.push('/');
      }
    }

  }


  useEffect(() => {
    consumeFetch(`${API_BASE_URL}/users/${emailGlobal}`, {
      headers: {
        Authorization: `${token}`
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      setUserData(data);
    }).catch(error => {
      console.error(error)
      setError(true);
    });
  }, []);

  return (
    <>
      {userData && !error ? (
        <CuadroProfile data={userData} />
      ) : (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress style={{ height: "50px", width: "100px" }} color="inherit" />
        </Backdrop>
      )}
    </>
  )
}

export { Profile };
