import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'js-cookie';
import socket from "../../../../utils/socket";
import { GlobalContext } from '../../../../context/GlobalContext';
import { ChatList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import './chat.css';
import { MessageListWindow } from '../message_list_window';
import ApiMiddleware from "../../../Shared/ApiMiddleware";
import noMessagesImg from '../../../../assets/images/no-messages.png';
import imgDefaultUser from '../../../../assets/images/default_user.png';
import avatar1 from '../../../../assets/images/avatars/avatar1.png';
import avatar2 from '../../../../assets/images/avatars/avatar2.png';
import avatar3 from '../../../../assets/images/avatars/avatar3.png';
import avatar4 from '../../../../assets/images/avatars/avatar4.png';
import avatar5 from '../../../../assets/images/avatars/avatar5.png';
import avatar6 from '../../../../assets/images/avatars/avatar6.png';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const MySwal = withReactContent(Swal);

function Chat(props) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const [error, setError] = useState(false);
    const { setActivePage, setShowBadge, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName, userRole, setActiveBlur } = useContext(GlobalContext);
    const history = useHistory();
    const [dataChats, setDataChats] = useState([]);
    const [dataChatsSupport, setDataChatsSupport] = useState([]);
    const [dataChatsAdmin, setDataChatsAdmin] = useState([]);
    const [taskId, setTaskId] = useState();
    const [receiverId, setReceiverId] = useState();
    const [chatState, setChatState] = useState();
    const [chatKind, setChatKind] = useState();
    const url = `${API_BASE_URL}/messages/${idUserGlobal}`;
    const [user1Id, setUser1Id] = useState();
    const [user2Id, setUser2Id] = useState();
    
    const urlAdmin = `${API_BASE_URL}/admin_chat/messagesByUser/${idUserGlobal}`;
    const urlSupport = `${API_BASE_URL}/support_chat/messagesByUser/${idUserGlobal}`;

    const location = useLocation();

    const edit = location.state?.edit || {};

    const [taskIdState, setTaskIdState] = useState(edit);

    const handleCreateChat = (task) => {
        if (task!='') {
            let data = {
                task_id: task
            }
            consumeFetch(`${API_BASE_URL}/messages/createChat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify(data)
            })
                .then((res) => {
                    if (res.ok) {
                        fetchData();
                    } 

                }).catch((err) => {
                    console.log(err);
                });
        } 
    }

    const allAvatar = {
        "avatar1": avatar1,
        "avatar2": avatar2,
        "avatar3": avatar3,
        "avatar4": avatar4,
        "avatar5": avatar5,
        "avatar6": avatar6,
    }
    const isOpen = true;
    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect(() => {
        if (Object.keys(edit).length>0) {
            setTaskIdState(edit)
            fetchData();
        }

    }, [taskIdState]);

    const fetchData = async () => {
        consumeFetch(url, {
            headers: {
                Authorization: `${token}`
            }
        }
        )
            .then(response => {
                return response.json();
            })
            .then(data => {
                const newRows = data.map((element, index) => {
                    let subtitle = "There´s no messages yet."
                    if (element.messages[0] != null) {
                        subtitle = element.messages[0].from === idUserGlobal ? `You: ${element.messages[0].text}` : element.messages[0].text;
                    }
                    let obj = {
                        avatar: ` ${element.Avatar && element.Avatar.includes("s3.amazonaws.com") ? element.Avatar : allAvatar[element.Avatar] ? allAvatar[element.Avatar] :imgDefaultUser } `,
                        alt: 'kursat_avatar',
                        title: element.client_id === idUserGlobal ? element.assistant_username+' - '+element.task_title  : `${element.client_username} `,
                        subtitle: subtitle,
                        date: element.messages[0] === null ? new Date() : new Date(element.messages[0].timestamp),
                        unread: 0,
                        custom_task_id: element.task_id,
                        custom_receiver_id: element.client_id === idUserGlobal ? element.assistant_id : element.client_id,
                        custom_state_id: element.state
                    };
                    return obj;
                });
                console.log(newRows)
                console.log(taskIdState)

                const idState = newRows.findIndex(item => item.custom_task_id === taskIdState);
                const i = idState>0 ? idState : 0;
                if (Object.keys(taskIdState)!=0 && idState==-1){
                    handleCreateChat(taskIdState)
                }
                if (newRows.length > 0  && taskId == null) {
                    setShowBadge(true);
                    setTaskId(newRows[i].custom_task_id);
                    setReceiverId(newRows[i].custom_receiver_id);
                    setChatState(newRows[i].custom_state_id);
                    setChatKind('Client');
                }
                setDataChats(newRows);

            })
            .catch(error => {
                console.error(error)
                setError(true);
            });
    }

    const fetchDataAdmin = async () => {
        consumeFetch(urlAdmin, {
            headers: {
                Authorization: `${token}`
            }
        }
        )
            .then(response => {
                return response.json();
            })
            .then(data => {
                const newRows = data.map((element, index) => {
                    let subtitle = "There´s no messages yet."
                    if (element.messages[0] != null) {
                        subtitle = element.messages[0].from === idUserGlobal ? `You: ${element.messages[0].text}` : element.messages[0].text;
                    }
                    let obj = {
                        avatar: 'https://assisttu-user-avatars.s3.amazonaws.com/UserAvatar-80326dee-7ae6-40c6-b359-1713123a6eac-11-2-2023%2C%209%3A54%3A16%20PM',
                        alt: 'kursat_avatar',
                        title: element.user1_id === idUserGlobal ? element.user2_username : 'Assisttu Admin',
                        subtitle: subtitle,
                        date: element.messages[0] === null ? new Date() : new Date(element.messages[0].timestamp),
                        unread: 0,
                        user1_id: element.user1_id,
                        user2_id: element.user2_id,
                    };
                    return obj;
                });
                if ((userRole == 'Admin' || props.isAdmin) && newRows.length > 0) {
                    setChatState(newRows[0].custom_state_id);
                    setShowBadge(true);
                    setUser1Id(newRows[0].user1_id);
                    setUser2Id(newRows[0].user2_id);
                    setTaskId(newRows[0].custom_task_id);
                    setChatKind('Admin');
                    props.isAdmin = false;
                }
                setDataChatsAdmin(newRows);
            })
            .catch(error => {
                console.error(error)
                setError(true);
            });
    }

    const fetchDataSupport = async () => {
        consumeFetch(urlSupport, {
            headers: {
                Authorization: `${token}`
            }
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                const newRows = data.map((element, index) => {
                    let subtitle = "There´s no messages yet."
                    if (element.messages[0] != null) {
                        subtitle = element.messages[0].from === idUserGlobal ? `You: ${element.messages[0].text}` : element.messages[0].text;
                    }
                    let obj = {
                        avatar: 'https://avatars.githubusercontent.com/u/80540635?v=4',
                        alt: 'kursat_avatar',
                        title: element.user1_id === idUserGlobal ? element.user2_username : 'Assisttu Support',
                        subtitle: subtitle,
                        date: element.messages[0] === null ? new Date() : new Date(element.messages[0].timestamp),
                        unread: 0,
                        user1_id: element.user1_id,
                        user2_id: element.user2_id,
                    };

                    return obj;
                });
                if (newRows.length > 0 && taskId == null) {
                    setShowBadge(true);
                    setChatState(newRows[0].custom_state_id);
                    setUser1Id(newRows[0].user1_id);
                    setUser2Id(newRows[0].user2_id);
                    setTaskId(newRows[0].user2_id);
                    setChatKind('Support');
                }
                setDataChatsSupport(newRows);
            })
            .catch(error => {
                console.error(error)
                setError(true);
            });
    }

    useEffect(() => {
        if (userRole == 'Admin' || props.isAdmin) {
            fetchDataAdmin();
            socket.on("Admin Message list", ({ content, from, to }) => {
                fetchDataAdmin();
            });

        } else if (userRole == 'Support') {
            fetchDataSupport();
            socket.on("Support Message list", ({ content, from, to }) => {
                fetchDataSupport();
            });
        } else if (props.isSupport) {
            fetchDataSupport();

            socket.on("Support Message list", ({ content, from, to }) => {
                fetchDataSupport();
            });
        } else {
            fetchData();
            fetchDataAdmin();

            socket.on("private message list", ({ content, from, to }) => {
                fetchData();
            });

            socket.on("Admin Message list", ({ content, from, to }) => {
                fetchDataAdmin();
            });
        }

        return () => {
            socket.off("private message list");
            socket.off("Admin Message list");
            socket.off("Support Message list");

        }
    }, []);

    const handleClick = (event) => {
        setShowBadge(true);
        setTaskId(event.custom_task_id);
        setReceiverId(event.custom_receiver_id);
        setChatState(event.custom_state_id);
        setChatKind('Client');
    };

    const handleClickAdmin = (event) => {
        setChatState(event.custom_state_id);
        setShowBadge(true);
        setUser1Id(event.user1_id);
        setUser2Id(event.user2_id);
        setTaskId(event.user2_id);
        setChatKind('Admin');
    };

    const handleClickSupport = (event) => {
        setShowBadge(true);
        setChatState(event.custom_state_id);
        setUser1Id(event.user1_id);
        setUser2Id(event.user2_id);
        setTaskId(event.user2_id);
        setChatKind('Support');
    };

    return (
        <div className="ChatWindow">
            {(dataChats) &&
                <div className='ChatDisplay'>
                    <MessageListWindow taskId={taskId} receiverId={receiverId} chatState={chatState} user1Id={user1Id} user2Id={user2Id} chatKind={chatKind}/>


                    <div className='contactDisplay'>
                        {((userRole == 'Client' || userRole == 'Assistant') && (props.isSupport !== true && props.isAdmin !== true)) &&
                            <>
                                <p className='support-spreader'>Assisttu Messages</p>
                                <ChatList
                                    className='chat-list'
                                    onClick={handleClick}
                                    dataSource={dataChats}
                                />
                            </>
                        }
                        {((userRole == 'Client' || userRole == 'Admin') && props.isSupport !== true) &&
                            <>
                                <p className='support-spreader'>PM Messages</p>
                                <ChatList
                                    className='chat-list'
                                    onClick={handleClickAdmin}
                                    dataSource={dataChatsAdmin}
                                />
                            </>
                        }
                        {(userRole == 'Support' || props.isSupport == true) &&
                            <>
                                <p className='support-spreader'>Support Messages</p>
                                <ChatList
                                    className='chat-list'
                                    onClick={handleClickSupport}
                                    dataSource={dataChatsSupport}
                                />
                            </>
                        }
                    </div>
                </div>}
            
        </div>
    );
}

export { Chat };
