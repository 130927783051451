import React from 'react'
import { NewTask } from './NewTask';
import { CounterTask } from './Counter';
import './HeadCounter.css';


export const HeadTask = (props) => {

    const { asistente, bgColor } = props;
    return (
        <div className='head-counter'>
            <NewTask/>
            <CounterTask/>
        </div>
    )
}
