import React from 'react'
import { Box } from '@mui/system';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { ProfileButton } from './ProfileButton';
import { AiFillWechat } from 'react-icons/ai';
import './PmBox.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export const PmBox = (props) => {



    const { asistente, bgColor } = props;
    return (
        <Link to='/user/PM'>
            <div class='pm-box'>
                <ProfileButton
                    profilePicture={'https://assisttu-user-avatars.s3.amazonaws.com/UserAvatar-80326dee-7ae6-40c6-b359-1713123a6eac-11-2-2023%2C%209%3A54%3A16%20PM'}
                />
                <p className='pm-box-text'>PM - Jose Admin</p>
                <AiFillWechat className='pm-box-icon' />
            </div>
        </Link>
    )
}
