import React, { useState, useEffect, useContext } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../../context/GlobalContext";
import { Counter } from "../../../../components/Shared/Counter";
import "./Counter.css";


import ApiMiddleware from "../../../../components/Shared/ApiMiddleware";

const MySwal = withReactContent(Swal);



function CounterTask() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [rows, setRows] = useState();
    const [activeRowCount, setActiveRowCount] = useState('0');
    const [inProgressRowCount, setInProgressRowCount] = useState('0');
    const [doneRowCount, setDoneRowCount] = useState('0');


    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)

                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }




    const fetchData = async () => {
        const params = new URLSearchParams();
        let endpoint = `${API_BASE_URL}/tasks/tasksByClient`;
        params.append('email', emailGlobal);
        const url = `${endpoint}?${params}`;
        try {
            const response = await consumeFetch(url, {
                headers: {
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            const data = await response.json();
            const newRows = data.data.map((item, index) => {
                const assistantEmail = item.assistantId ? item.assistantId.email : "Unassigned";
                var dateLimit = item.date_limit ? new Date(item.date_limit) : new Date();
                var userTimezoneOffset = dateLimit.getTimezoneOffset() * 60000;
                dateLimit = new Date(dateLimit.getTime() + userTimezoneOffset);
                const year = dateLimit.getFullYear();
                const month = dateLimit.getMonth() + 1;
                const day = dateLimit.getDate();

                // Formatear la fecha en el formato deseado "MM-DD-YYYY"
                const fechaFormateadaStr = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
                return {
                    id: index,
                    taskTypeName: item.taskType_Id ? item.taskType_Id.task_type_name : "",
                    statusName: item.status_Id.status_name,
                    assistantEmail: assistantEmail,
                    task_id: item.id,
                    dateLimit: fechaFormateadaStr,
                    ...item
                }
            });
            const updatedRows = await Promise.all(newRows.map(async (element) => {
                const urlGetSecrets = `${API_BASE_URL}/task_secrets/getByTaskId/${element.id}`;

                const responseSecrets = await consumeFetch(urlGetSecrets, {
                    headers: {
                        Authorization: `${token}`
                    }
                });
                const dataReturned = await responseSecrets.json();
                const valuesVault = dataReturned.data.map(item => item.secret.name_secret);
                return {
                    ...element,
                    valuesVault: valuesVault,
                };
            }));

            setRows(updatedRows);
            setActiveRowCount(updatedRows.filter(row => row.statusName === 'Unassigned').length);
            setInProgressRowCount(updatedRows.filter(row => row.statusName === 'In Progress').length);
            setDoneRowCount(updatedRows.filter(row => row.statusName === 'Done').length);
        } catch (error) {
            console.error(error);
            // Manejar el error
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
    }, [rows]);


    return (
        <React.Fragment>


                <div className="counter-container-home counter-active">
                    <Counter count={inProgressRowCount} counterClassName='counter-home' />
                    <p className="text-counter-home">In Progress</p>
                </div>

                <div className="counter-container-home counter-in-progress">
                    <Counter count={activeRowCount} counterClassName='counter-home' />
                    <p className="text-counter-home">Unassigned </p>
                </div>

                <div className="counter-container-home counter-done">
                    <Counter count={doneRowCount} counterClassName='counter-home' />
                    <p className="text-counter-home">Done</p>
                </div>


        </React.Fragment >
    );
}

export { CounterTask };
