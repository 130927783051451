import React, { useContext, useState, useEffect, useRef } from "react";
import { OutlinedInput, Button, IconButton, FormHelperText, FormControl, Box, InputLabel, MenuItem, Select } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useFormControl } from '@mui/material/FormControl';
import { format } from 'date-fns';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { CustomizedSnackbar } from '../Snackbar';
import { GlobalContext } from '../../../context/GlobalContext'
import { BoxPassword } from "../../../pages/assistantContent/tasks/BoxPassword";
import { Button as Btn } from "../Button";
import './PopupInfoTask.css'
import ApiMiddleware from "../ApiMiddleware";
import { SliderProgress } from '../../../components/Shared/ProgressBar'
const MySwal = withReactContent(Swal);

function PopupInfoTask(props) {
    const { selectedTask, assistants, setProgresstoTask, classHeader } = props;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setSelectedTaskId, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName, userRole, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [secretsName, setSecretsName] = useState();
    const [progress, setProgress] = useState(0);
    const [max, setMax] = useState(0);
    const [min, setMin] = useState(0);
    const [timeUsedHours, setTimeUsedHours] = useState();
    const [timeUsedMinutes, setTimeUsedMinutes] = useState();
    const [selectAssistant, setSelectAssistant] = useState(selectedTask.assistant_id);
    const [assistantActual, setAssistantActual] = useState(selectedTask.assistant_id);
    const [buttonChangeAssis, setButtonChangeAssis] = useState(false);
    const [recurrences, setRecurrences] = useState();
    const [principalTask, setPrincipalTask] = useState();
    const SnackbarRef = useRef();
    const isMobile = window.innerWidth <= 600;

    useEffect(() => {
        if (selectAssistant != assistantActual) {
            setButtonChangeAssis(true);
        } else {
            setButtonChangeAssis(false);
        }
    }, [selectAssistant, assistantActual])

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect(() => {
        //Consumo de recurrents tasks
        async function getRecurrents() {
            const response = await consumeFetch(`${API_BASE_URL}/tasks/recurrentsByParent/${selectedTask.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setRecurrences(data);
            }

        }

        if (selectedTask.recurrent_Id != null) {
            getRecurrents();
        }
    }, [])

    useEffect(() => {



        async function getTaskPrincipal() {
            const response = await consumeFetch(`${API_BASE_URL}/tasks/getTaskById/${selectedTask.parentTask}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setPrincipalTask(data);
            }

        }
        if (selectedTask.parentTask != null) {
            getTaskPrincipal();
        }
    }, []);

    const handleCreateChat = (task, assistant, status) => {
        if (assistant !== "" && assistant !== undefined && assistant !== null && assistant !== "Unassigned" && status !== "Done") {
            let data = {
                task_id: task
            }
            consumeFetch(`${API_BASE_URL}/messages/createChat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify(data)
            })
                .then((res) => {
                    setActiveBlur(true);
                    if (res.ok) {
                        MySwal.fire({
                            icon: 'Chat Created!',
                            title: 'Task chat has been created. You can see it on messages view.',
                            text: 'success',
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }
                        )
                    } else if (res.status === 400) {
                        MySwal.fire({
                            icon: 'info',
                            title: 'Chat Already Exists',
                            text: 'The chat already exists, see it on messages list.',
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        })
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'We can´t create the chat. Contact support.',
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        })
                    }
                }).catch((err) => {
                    console.log(err);
                });
        } else if (status === "Done") {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This task is already done, so we can´t create a chat.',
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            })
        } else {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This task doesn´t have an assistant asigned, so we can´t create a chat.',
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            })
        }
    }

    useEffect(() => {
        const emailAssistantTask = selectedTask && selectedTask.assistantId ? selectedTask.assistantId.email : null;
        if (emailAssistantTask == null) {
            return;
        }
        if (emailAssistantTask !== emailGlobal) {
            return;
        }
        consumeFetch(`${API_BASE_URL}/task_secrets/getByTaskId/${selectedTask.id}`, {
            headers: {
                Authorization: `${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                const mappedData = data.data.map((item, index) => {
                    return {
                        secret_name: item.secret.name_secret,
                        email_user: selectedTask.clientId.email
                    };
                });
                if (mappedData.length !== 0) {
                    setSecretsName(mappedData);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [])

    function timeToHours(tiempoStr) {
        if (!tiempoStr) {
            return 1;
        }
        var partes = tiempoStr.split(':');
        var horas = parseInt(partes[0]);
        var minutos = parseInt(partes[1]);
        var totalHoras = horas + minutos / 60;
        return totalHoras;
    }

    const normalise = (value) => ((value - min) * 100) / (max - min);

    const formatDate = (date) => {
        var dateLimit = new Date(date);
        var userTimezoneOffset = dateLimit.getTimezoneOffset() * 60000;
        dateLimit = new Date(dateLimit.getTime() + userTimezoneOffset);
        const year = dateLimit.getFullYear();
        const month = dateLimit.getMonth() + 1;
        const day = dateLimit.getDate();

        return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;

    }

    const getHours = (timeUsed) => {
        return Math.floor(timeUsed);
    }

    const getMinutes = (timeUsed) => {
        return Math.round((timeUsed - Math.floor(timeUsed)) * 60)
    }

    const handleChangeHours = (e) => {
        const newValue = e.target.value;
        if (Number(newValue) >= 0) {
            setTimeUsedHours(newValue);
        }
    }

    const handleChangeMinutes = (e) => {
        const newValue = e.target.value;
        if (Number(newValue) >= 0 && Number(newValue) < 60) {
            setTimeUsedMinutes(newValue);

        }
    }

    const sendTimeUsed = async () => {
        const actualTime = Number(selectedTask.timeHoursUsed);
        const sendTime = Number(timeUsedHours) + Number(timeUsedMinutes / 60);
        if (sendTime <= actualTime) {
            Swal.fire({
                title: "Time cannot be less than the current",
                icon: "warning"
            })
        } else {
            const request = {
                idTask: selectedTask.id,
                timeUsed: sendTime,
                isRecurrence: selectedTask.parentTask != null && selectedTask.parentTask != undefined ? true : false
            };
            const response = await consumeFetch(`${API_BASE_URL}/tasks/addTimeUsed`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify(request),
            });

            if (response.ok) {
                selectedTask.timeHoursUsed = sendTime;
                setProgresstoTask && setProgresstoTask(sendTime, selectedTask.id);
                SnackbarRef.current.handleSnackbarOpen('Registered time!', 'success');
            } else {
                setTimeUsedHours(getHours(selectedTask.timeHoursUsed));
                setTimeUsedMinutes(getMinutes(selectedTask.timeHoursUsed));
                SnackbarRef.current.handleSnackbarOpen('Was not possible!', 'error');
            }
        }
    };

    useEffect(() => {
        setMax(timeToHours(selectedTask.hours));
        setTimeUsedHours(getHours(selectedTask.timeHoursUsed));
        setTimeUsedMinutes(getMinutes(selectedTask.timeHoursUsed));
        setProgresstoTask && setProgresstoTask(selectedTask.timeHoursUsed, selectedTask.id);
        setProgress(selectedTask.timeHoursUsed);
    }, []);

    useEffect(() => {
        const sumaNew = Number(timeUsedHours) + Number(timeUsedMinutes / 60);
        setProgresstoTask && setProgresstoTask(sumaNew, selectedTask.id);
    }, [timeUsedHours, timeUsedMinutes])

    const createdAt = format(new Date(selectedTask.created_at), 'MM/dd/yyyy HH:mm');

    const handleChangeSelectAssistant = (event) => {
        setSelectAssistant(event.target.value);
    };

    const changeAssistant = async () => {
        const request = {
            email: emailGlobal,
            assistantIdNew: selectAssistant,
            idTask: selectedTask.id
        };
        const response = await consumeFetch(`${API_BASE_URL}/tasks/updateAssistant`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify(request)
        });

        if (response.ok) {
            setButtonChangeAssis(false);
            setAssistantActual(selectAssistant);
            const data = await response.json();
            selectedTask.assistantId = data.assistantId;
            selectedTask.assistant_id = data.assistant_id;
            SnackbarRef.current.handleSnackbarOpen('Succesfully!', 'success');
            props.onUpdateTask({ ...selectedTask })
        } else {
            SnackbarRef.current.handleSnackbarOpen('Was not possible!', 'error');
            setAssistantActual(selectAssistant);
        }
    }


    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="full-task-modal">
                <div className="modal-task-upper">
                    <div className={`modal-task-header ${classHeader}`}>
                        <section className="modal-task-header-left">
                            <div className="modal-task-owner">
                                <label className="modal-task-label modal-task-owner-text">For: {selectedTask.clientId.name}</label>
                            </div>
                        </section>
                        <section className="modal-task-header-right">
                            <label className="modal-task-label modal-task-created">Created: {createdAt}</label>
                            <label className="modal-task-label modal-task-status">Status: {selectedTask.status_Id.status_name}</label>
                            {/* <label className="modal-task-label modal-task-type">Type: {selectedTask.taskType_Id.task_type_name}</label> */}
                        </section>
                    </div>
                    <div className="modal-task-header-title">
                        <h2>Task</h2>
                    </div>
                </div>
                <div className="modal-task-description">
                    <p className="modal-task-description-text">{selectedTask.description}</p>
                </div>
                <div className="modal-task-hours-date-limit">
                    <p>
                        {principalTask != null && principalTask != undefined && `The main task is ${principalTask.taskTitle} with ${principalTask.nRecurrences} recurrences and a deadline of ${formatDate(principalTask.date_limit)} .`}{principalTask != null && principalTask != undefined && <br />}
                        {selectedTask.hours && `${assistants && userRole.toUpperCase() === 'Admin'.toUpperCase() ? "This task have" : "You have"} a total of ${timeToHours(selectedTask.hours)} hours to complete this task.`}{selectedTask.hours && <br />}
                        {!selectedTask.hours && `This task has no time limit to be executed.`}{!selectedTask.hours && <br />}
                        {selectedTask.recurrent_Id != null && `This task is recurrent with the option "${selectedTask.recurrent_Id.description}".`}{selectedTask.recurrent_Id != null && <br />}
                        {selectedTask.dailyLimitHours && selectedTask.recurrent_Id == null && selectedTask.assistantId == null && `This task is limited to ${selectedTask.dailyLimitHours} hours per day to be executed by the assistant.`}{selectedTask.dailyLimitHours && selectedTask.recurrent_Id == null && selectedTask.assistantId == null && <br />}
                        {selectedTask.dailyLimitHours && selectedTask.recurrent_Id != null && `This task has ${selectedTask.nRecurrences} recurrences, each with ${selectedTask.dailyLimitHours} hours to be executed by the assistant.`}{selectedTask.dailyLimitHours && selectedTask.recurrent_Id != null && <br />}
                        {selectedTask.date_limit && `This task is due to be completed by `}{selectedTask.date_limit && <span className="date-limit-popup-info-task">{formatDate(selectedTask.date_limit)}.</span>}{selectedTask.date_limit && <br />}
                        {!selectedTask.date_limit && `This task does not have a deadline for completion`}{!selectedTask.date_limit && <br />}
                    </p>
                </div>

                <br />
                {selectedTask.recurrent_Id != null && recurrences &&
                    <>
                        <div className="modal-task-recurrents">
                            <h3 className="modal-task-recurrents-title">Recurrences</h3>
                        </div>
                        <div className="modal-task-table-recurrences">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recurrences.map(rec => (
                                        <tr>
                                            <td>{rec.taskTitle}</td>
                                            <td>{format(new Date(rec.dateDisplayed), 'MM/dd/yyyy')}</td>
                                            <td>{rec.status_Id.status_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }

                {assistants && userRole.toUpperCase() === 'Admin'.toUpperCase() && (
                    <section className="modal-task-assistant">
                        <div className="modal-task-assistant-title-task"><b>{selectedTask.taskTitle}</b></div>
                        <div className="modal-task-assistant-title">
                            <b>Associated assistant</b>
                        </div>
                        <div className="modal-task-assistant-list">
                            <FormControl fullWidth className="modal-task-assistant-select">
                                <InputLabel id="modal-task-assistant-select-label">E-Mail</InputLabel>
                                <Select
                                    labelId="modal-task-assistant-select-label"
                                    className="modal-task-assistant-select-item"
                                    value={selectAssistant}
                                    label="E-Mail"
                                    onChange={handleChangeSelectAssistant}
                                // displayEmpty
                                // inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {assistants.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.email}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {buttonChangeAssis &&
                                <Btn buttonClassName="button-change-assistant" onClick={changeAssistant}>
                                    Change
                                </Btn>
                            }
                        </div>
                    </section>
                )}

                {secretsName && (selectedTask.assistant_id !== null && (
                    <section className="modal-task-secrets">
                        <div className="modal-task-secrets-title">
                            Associated secrets
                        </div>
                        <div className="modal-task-secrets-items">
                            {secretsName.map((item) => (
                                <BoxPassword item={item} statusTask={selectedTask.status_Id.status_name} />
                            ))}
                        </div>
                    </section>
                )
                )}
                {selectedTask.assistant_id !== null && selectedTask.assistant_id === idUserGlobal ?
                    <div className="modal-task-progress-bar">
                        <div className="modal-task-progress-hours">
                            <div className="hour-executed">
                                <b>Executed:</b><br />
                                <div className="inputs-hours">
                                    <FormControl>
                                        <OutlinedInput placeholder="" className="modal-task-input-time" size="small" value={timeUsedHours} onChange={handleChangeHours} disabled={selectedTask.status_Id.status_name != "In Progress" ? true : false} />
                                        <MyFormHelperText text="Hours" />
                                    </FormControl>
                                    <FormControl>
                                        <OutlinedInput placeholder="" className="modal-task-input-time" size="small" value={timeUsedMinutes} onChange={handleChangeMinutes} disabled={selectedTask.status_Id.status_name != "In Progress" ? true : false} />
                                        <MyFormHelperText text="Minutes" />
                                    </FormControl>
                                    {selectedTask.status_Id.status_name === "In Progress" && (isMobile ?
                                        <Btn
                                            buttonClassName="send-time-executed-Button"
                                            onClick={sendTimeUsed}
                                            icon={<SendIcon />}
                                        ></Btn>
                                        :
                                        <Btn
                                            buttonClassName="send-time-executed-Button"
                                            onClick={sendTimeUsed}
                                        >
                                            Send
                                        </Btn>
                                    )}
                                </div>
                            </div>
                            <div className="maximum-hours">{selectedTask.hours && <div><b>Max:</b> {timeToHours(selectedTask.hours)} Hours</div>}</div>
                        </div>
                    </div>
                    : (userRole.toUpperCase() === 'Admin'.toUpperCase()) ?
                        <div className="modal-task-progress-bar">
                            <div className="modal-task-progress-hours">
                                <div className="hour-executed"><b>Executed: </b>
                                    {timeUsedHours !== 0 && `${timeUsedHours} Hours `}
                                    {timeUsedHours === 0 && `${timeUsedHours} Hours `}
                                    {timeUsedMinutes !== 0 && `${timeUsedMinutes} Minutes`}
                                </div>
                                <div className="maximum-hours-client">{selectedTask.hours && <div><b>Max:</b> {timeToHours(selectedTask.hours)} Hours</div>}</div>
                            </div>
                            <Box sx={{ width: '100%', padding: '10px' }}>
                                <SliderProgress value={normalise(progress)} />
                            </Box>
                        </div>
                        : null}

            </div>
            {selectedTask.assistant_id !== null && selectedTask.assistant_id === idUserGlobal &&
                <div className="info-button-container">
                    <Btn
                        buttonClassName="create_Chat_Button"
                        onClick={() => {
                            handleCreateChat(selectedTask.id, selectedTask.clientId.email, selectedTask.status_Id.status_name)
                            console.log(selectedTask)
                        }}
                    >
                        Create chat
                    </Btn>
                    <Btn
                        buttonClassName="create_Chat_Button"
                        onClick={() => {
                            setSelectedTaskId(selectedTask.id);
                            history.push('/user/ToDoList');
                            setActiveBlur(false);
                        }}
                    >
                        View To Do List
                    </Btn>
                </div>}
        </React.Fragment>
    )
}

function MyFormHelperText(props) {
    const { focused } = useFormControl() || {};
    const { text } = props;

    const helperText = React.useMemo(() => {
        //   if (focused) {
        //     return `Enter the ${text}`;
        //   }

        return text;
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
}

export { PopupInfoTask };