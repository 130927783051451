import React, { useState, useEffect, useRef, useContext } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { ContentHeader } from "../../../components/Shared/ContentHeader";
import { Counter } from "../../../components/Shared/Counter";
import { CardTasks } from "./CardTasks";
import { Popup } from "../../../components/Shared/Popup";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { GlobalContext } from "../../../context/GlobalContext";
import { PopupInfoTask } from "../../../components/Shared/tasks/PopupInfoTask";
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import "./OwnedTasks.css";
const MySwal = withReactContent(Swal);


function OwnedTasks() {

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const [tasks, setTasks] = useState();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [statuses, setStatuses] = useState({});
    const [activeRowCount, setActiveRowCount] = useState('0');
    const [inProgressRowCount, setInProgressRowCount] = useState('0');
    const [doneRowCount, setDoneRowCount] = useState('0');
    const [progressBar, setProgressBar] = useState(0);
    const [selectId, setSelectId] = useState();
    const SnackbarRef = useRef();
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    useEffect(() => {
        // Fetch tasks for the assistant in session
        fetchTasksByAssistant();
    }, []);

    useEffect(() => {
        // Init the statuses state
        const statusesObj = {};
        tasks && tasks.forEach((task) => {
            statusesObj[task.id] = task.status_Id.status_name;
        });
        setStatuses(statusesObj);
    }, [tasks]);

    const fetchTasksByAssistant = async () => {
        try {
            // Make the API call to fetch tasks
            const userEmail = localStorage.getItem("emailGlobal");
            const url = `${API_BASE_URL}/tasks/tasksByAssistant?email=${encodeURIComponent(userEmail)}`;
            const response = await consumeFetch(url, {
                "headers": {
                    "Content-Type": "application/json",
                    Authorization: token
                }
            });
            const data = await response.json();

            if (response.ok) {
                setTasks(data.data);
                setActiveRowCount(data.data.filter(task => task.status_Id.status_name === 'Unassigned').length);
                setInProgressRowCount(data.data.filter(task => task.status_Id.status_name === 'In Progress').length);
                setDoneRowCount(data.data.filter(task => task.status_Id.status_name === 'Done').length);
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleStatusChange = (event, taskId) => {
        event.stopPropagation();
        const { value } = event.target;
        setStatuses((prevStatuses) => ({
            ...prevStatuses,
            [taskId]: value,
        }));
    };

    const saveNewStatus = async (event, task) => {
        event.stopPropagation();
        if (statuses[task.id] === task.status_Id.status_name) {
            SnackbarRef.current.handleSnackbarOpen("There are not status changes on this task", "info");
            return;
        }
        try {
            let response = null;
            const emailAssistant = localStorage.getItem("emailGlobal");
            const statusNew = statuses[task.id];
            const idTask = task.id;
            let data_object = {
                emailAssistant,
                statusNew,
                idTask,
            };
            if(statusNew === "Done"){
                const { value: assistant_comment } = await MySwal.fire({
                    title: `Do you want to mark this task as Done?`,
                    input: "textarea",
                    inputLabel: "If you want to mark this task as done, you need to input a short resume of your work",
                    inputPlaceholder: "Type your comments here...",
                    inputAttributes: {
                      "aria-label": "Type your comments here"
                    },
                    showCancelButton: true,
                    customClass: {
                        popup: 'popup-sweet',
                        title: 'title-sweet',
                        htmlContainer: 'text-sweet',
                        confirmButton: 'confirm-button-sweet',
                        denyButton: 'deny-button-sweet',
                    }
                });
                if(assistant_comment !== null && assistant_comment !== undefined){
                    if( assistant_comment.trim() !== ''){
                        data_object.assistant_comment = assistant_comment;
                        response = await consumeFetch(`${API_BASE_URL}/tasks/updateStatus`, {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(data_object)
                        });
                    }else{
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops',
                            text: 'Empty message is not allowed, please repeat the process.',
                            showConfirmButton: false,
                            timer: 1700,
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => {setActiveBlur(false)});
                        return;
                    }
                }else{
                    return;
                }
            }else{
                response = await consumeFetch(`${API_BASE_URL}/tasks/updateStatus`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data_object)
                });
            }
            if (!response.ok) {
                throw new Error("Failed to update the status");
            }
            task.status_Id.status_name = statusNew;
            setActiveRowCount(tasks.filter(task => task.status_Id.status_name === 'Active').length);
            setInProgressRowCount(tasks.filter(task => task.status_Id.status_name === 'In Progress').length);
            setDoneRowCount(tasks.filter(task => task.status_Id.status_name === 'Done').length);
            SnackbarRef.current.handleSnackbarOpen(`Status updated to ${statusNew} successfully`, "success");
        } catch (error) {
            SnackbarRef.current.handleSnackbarOpen("Try again later", "error");
        }
    };


    function openPopup(task) {
        setSelectedTask(task);
        setIsPopupOpen(true);
        setActiveBlur(true);
    }

    function closePopup() {
        setIsPopupOpen(false);
        setActiveBlur(false);
    }

    const setProgresstoTask = (progreso,id) => {
        setProgressBar(progreso);
        setSelectId(id);
    }

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="tasks-header">
                <ContentHeader text="My Tasks" />
            </div>
            <div className="tasks-info">
                <div className="tasks-counters">
                <div className="counter-container counter-in-progress">
                        <Counter count={inProgressRowCount} counterClassName='Counter' />
                        <p className="text-task">In Progress</p>
                    </div>
                    
                    <div className="counter-container counter-active">
                        <Counter count={activeRowCount} counterClassName='Counter' />
                        <p className="text-task">Unassigned</p>
                    </div>

                    <div className="counter-container counter-done">
                        <Counter count={doneRowCount} counterClassName='Counter' />
                        <p className="text-task">Done</p>
                    </div>
                </div>
                <div className="task-cards">
                    {(tasks || tasks !== undefined || tasks!= null) && tasks.length > 0 ? tasks.map((task, index) => (
                        <div className="full-asigned-task" key={index} onClick={() => openPopup(task)}>
                            <div className="full-card-task">
                                <CardTasks
                                    userId = {task.clientId.id}
                                    owner={task.clientId.name}
                                    taskTitle={task.taskTitle}
                                    task={task}
                                    isOwned = {true}
                                    statuses = {statuses}
                                    saveNewStatus = {saveNewStatus}
                                    handleStatusChange = {handleStatusChange}
                                    progressBar = {progressBar}
                                    selectId = {selectId}
                                />
                            </div>
                            
                        </div>
                    ))
                    :
                        <h2>You currently have no tasks assigned to you!</h2>
                    }
                    <Popup isOpen={isPopupOpen} onClose={closePopup} modalClass="popup-details-tasks-assistant">
                        {selectedTask && (
                            <PopupInfoTask selectedTask={selectedTask} setProgresstoTask={setProgresstoTask} classHeader="header-popup-assistant"/>
                        )}
                    </Popup>
                </div>
            </div>
        </React.Fragment>
    );
}

export { OwnedTasks };
