

import React from 'react'
import imgDefaultUser from '../../../../assets/images/programador.png';
import { Button } from "../../../../components/Shared/Button";
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { IoIosStar } from 'react-icons/io';
import './AssistantBox.css'
import { LinearProgressWithLabel } from '../../../../components/Shared/ProgressBar';
import { Box } from '@mui/system';
import { AiFillWechat } from 'react-icons/ai';

export const CuadroInfoDate = (props) => {

    const { asistente, handleClick, bgColor } = props;
    console.log(asistente)
    return (
        <div className='cuadroInfoTaskDetail sombra2' style={{ background: `rgba(${bgColor?.slice(4, -1)}, 0.2)`, borderColor: bgColor, borderWidth: '3px', borderStyle: 'solid' }}>

            <div className="task-detail-date">
                <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Description</b></span><br />
                <span style={{ fontSize: '15px', textAlign: 'left' }}>{asistente.description}</span><br />
            </div>
            <div className="task-detail-date">
                <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Time Management</b></span><br />
                <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Date limit:</b> {asistente.dateLimit}</span><br />
                <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Hours Assigned:</b> {asistente.hours}</span><br />
                <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Hours used:</b> {asistente.timeHoursUsed ? asistente.timeHoursUsed : 0}</span>


            </div>

        </div>
    )
}
