import React, { useState, useEffect, useContext} from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { GlobalContext } from '../../../context/GlobalContext'
import { Header } from "../../../components/Shared/Header";
import { Button } from "../../../components/Shared/Button";
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { GoDotFill } from 'react-icons/go';
import "./Billings.css";
import { Link } from 'react-router-dom/cjs/react-router-dom';
const MySwal = withReactContent(Swal);

function Summary() {
    const [error, setError] = useState(false);
    const [planData, setPlanData] = useState([]);
    const { planesUsuario, setPlanesUsuario, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const url = `${API_BASE_URL}/users/userplan/${emailGlobal}`;

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }
    const getHours = (timeUsed) => {
        return Math.floor(timeUsed);
    }

    const getMinutes = (timeUsed) => {
        return Math.round((timeUsed - Math.floor(timeUsed)) * 60)
    }

    const fetchData = async() => {
        consumeFetch(url,{
            headers: {
              Authorization: `${token}`
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
            if(data.length > 0){
                let newMenuData = data.map(element => element.planType);
                for(var i = newMenuData.length -1; i >=0; i--){
                    if(newMenuData.indexOf(newMenuData[i]) !== i) newMenuData.splice(i,1);
                }
                setPlanesUsuario(newMenuData);
            }
            setPlanData(data);
        }).catch(error => {
          console.error(error)
          setError(true);
        });
    }
    useEffect(() => {
        fetchData();
    }, [url, token]);

    const handleClick = (planName, planType) => {
        setActiveBlur(true);
        MySwal.fire({
            icon:'warning',
            title: `Are you Sure?`,
            text: `Do you want to cancel the ${planName} - ${planType} plan ?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes, I want to cancel',
            denyButtonText: `No, I want to continue`,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                consumeFetch(`${API_BASE_URL}/plans/cancelPlan`,{
                    method: 'PUT',
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `${token}`
                    },
                    body: JSON.stringify({
                        email: emailGlobal,
                        planName,
                        planType
                    })
                  }
                ).then(response => response.json()).then(data => {
                    if(data.status === "success"){
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Your plan was cancelled.',
                            showConfirmButton: false,
                            timer: 1700,
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                          }).finally(() => {
                            setActiveBlur(false);
                            for(var i = data.userPlans.length -1; i >=0; i--){
                                if(data.userPlans.indexOf(data.userPlans[i]) !== i) data.userPlans.splice(i,1);
                            }
                            setPlanesUsuario(data.userPlans);
                          });
                        fetchData();
                    }else{
                        throw new Error("Error on api call");
                    }
                }).catch(error => {
                  console.error(error);
                  setActiveBlur(true);
                  MySwal.fire({
                    icon: 'error',
                    title: 'Oops ...',
                    text: 'There was a unexpected error, please contact support.',
                    showConfirmButton: false,
                    timer: 1700,
                    backdrop: true,
                    customClass: {
                        popup: 'popup-sweet',
                        title: 'title-sweet',
                        htmlContainer: 'text-sweet',
                        confirmButton: 'confirm-button-sweet',
                        denyButton: 'deny-button-sweet',
                    }
                  }).finally(() => {setActiveBlur(false)});
                  setError(true);
                });
            } else if (result.isDenied) {
            }
        }).finally(() => {setActiveBlur(false)});
    };

    return (
        <React.Fragment>
            {(planData && !error)  && (
                <>
                    <Header headerClassName="billing-subheader" level={2} children="Overview" />
                    <div className="info-summary-container">
                        <div className="info-summary">
                            <div className='info-summary-text-container'>
                            <span className="info-summary-text">{planData.length > 0 ? "You have been a member since:" : "You don’t have a membership yet."}</span>
                                {planData.length > 0 ?
                                    planData.map(element =>
                                        <div className="plan-information-container">
                                            <div className="info-summary-text">
                                                <GoDotFill />
                                                <span>{element.planName} - {element.planType}</span>
                                            </div>
                                            <div className='info-summary-button-container'>
                                                <Link to="/user/Plans">
                                                <Button buttonClassName="button-cancel-plan" children="Modify Plan"/>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                :
                                    <div className='info-summary-button-container'>
                                        <Link to="/user/Plans">
                                            <Button buttonClassName="button-cancel-plan" children="Get a Plan"/>
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )}
        </React.Fragment>
    );
}

export { Summary };

