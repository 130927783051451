import React from 'react';
import { useEffect, useState, useContext, useRef } from 'react';
import { useHistory } from "react-router-dom";
import {
    Select, MenuItem,
    InputLabel, FormControl,
    TextField, Button
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { daysBetweenDates, sumDays, isWorkingDay, changeTimeZone } from "../../../../utils/utils";
import { CustomizedSnackbar } from '../../../../components/Shared/Snackbar';
import ApiMiddleware from '../../../../components/Shared/ApiMiddleware';
import { GlobalContext } from '../../../../context/GlobalContext';
import './EditRecurrences.css';

function EditRecurrences(props) {
    const { infoTaskArray, minDateInitial, maxDateInitial, mindate, maxDate, description, handleClose, selectSecrets } = props
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, } = useContext(GlobalContext);
    const history = useHistory();

    const [infoTask, setInfoTask] = useState({});
    const [recurrenceNew, setRecurrenceNew] = useState('');
    const [dateInitialNew, setDateInitialNew] = useState(null);
    const [dateLimitNew, setDateLimitNew] = useState(null);
    const [dailyLimitHoursNew, setDailyLimitHoursNew] = useState(null);
    const [recurrences, setRecurrences] = useState();
    const [hoursRecurrencesPast, setHoursRecurrencesPast] = useState();
    const [valueHours, setValueHours] = useState(0);

    const SnackbarRef = useRef();

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }
    useEffect(() => {

        const infoTaskTmp = {};

        for (const elemento of infoTaskArray) {
            infoTaskTmp[elemento.field] = {
                field: elemento.field,
                headerName: elemento.headerName,
                value: elemento.value,
                values: elemento.values
            };
        }

        setInfoTask(infoTaskTmp);

        //Consumo de recurrents tasks
        async function getRecurrents() {
            const response = await consumeFetch(`${API_BASE_URL}/tasks/recurrentsByParent/${infoTaskTmp.id.value}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setRecurrences(data);
            }

        }

        getRecurrents();
    }, [])

    useEffect(() => {

        if (recurrences) {
            const fechaActual = changeTimeZone(new Date());

            const elementsPast = recurrences.filter((elemento) => {
                const fechaElemento = changeTimeZone(new Date(elemento.dateDisplayed));
                return fechaElemento <= fechaActual;
            })

            const cantidadRecPast = elementsPast.length;

            const hoursEach = infoTask.dailyLimitHours ? infoTask.dailyLimitHours.value : 0;

            setHoursRecurrencesPast(cantidadRecPast * hoursEach);
        }
    }, [recurrences])

    useEffect(() => {
        async function processHoursIsRecurring() {
            try {
                if (dailyLimitHoursNew !== null && dailyLimitHoursNew !== undefined
                    && recurrenceNew !== null && recurrenceNew !== undefined
                    && dateLimitNew !== null && dateLimitNew !== undefined
                    && dateInitialNew !== null && dateInitialNew !== undefined) {

                    //Se obtiene la clave de la recurrencia escogida
                    const keyRecurrence = infoTask.recurrence.values.find(item => item.description === recurrenceNew)?.key;
                    // const keyRecurrence = 0;
                    const daysBeetween = daysBetweenDates(dateLimitNew, dateInitialNew);

                    const nRecurrencias = Math.trunc(Number(daysBeetween) / Number(keyRecurrence)) + 1;

                    var countTaskRecurrent = 0;
                    for (let index = 0; index < Number(nRecurrencias); index++) {
                        var dateToEvaluate = sumDays(dateInitialNew, Number(index) * Number(keyRecurrence));
                        if (await isWorkingDay(dateToEvaluate)) {
                            countTaskRecurrent = countTaskRecurrent + 1;
                        }

                    }

                    const hoursFinal = Number(countTaskRecurrent) * Number(dailyLimitHoursNew);

                    setValueHours(hoursFinal);


                }

            } catch (error) {
                console.log("Error = " + error);
                setValueHours(0);
            }

        }
        processHoursIsRecurring();
    }, [dailyLimitHoursNew, recurrenceNew, dateLimitNew, dateInitialNew]);


    const handleChange = (e, option) => {

        switch (option) {
            case "dailyLimitHours":
                if (Number(e.target.value) < 1 || Number(e.target.value) > 8) {
                    return;
                } else {
                    setDailyLimitHoursNew(e.target.value);
                }
                break;
            case "recurrence":
                setRecurrenceNew(e.target.value);
                break;
            case "dateInitial":
                setDateInitialNew(new Date(e.toISOString()));
                break;
            case "dateLimit":
                setDateLimitNew(new Date(e.toISOString()));
                break;
            default:
                break;

        }


    }




    var dateLimit = '';
    try {
        dateLimit = infoTask.dateLimit ? format(changeTimeZone(infoTask.dateLimit.value), 'MM/dd/yyyy') : '';
    } catch (error) {
        console.error("Error formateando dateLimit", error);
    }

    const handleEditRecurrence = async () => {
        try {
            const convertedArray = selectSecrets.map((value) => ({ name_secret: value }));
            // Enviar consumo backend, mostrar mensaje en popup
    
            let data = {
                description: description,
                recurrence: recurrenceNew,
                dateInitial: dateInitialNew,
                dateLimit: dateLimitNew,
                dailyLimitHours: dailyLimitHoursNew,
                valueHours: valueHours,
                valuesVault: convertedArray,
                email: emailGlobal
            }
    
            const response = await consumeFetch(`${API_BASE_URL}/tasks/updateRecurrences/${infoTask.id.value}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify(data)
            })
    
            if (response.ok) {
                const data = await response.json();
                SnackbarRef.current.handleSnackbarOpen('Item saved successfully', 'success');
            } else {
                const data = await response.json();
                SnackbarRef.current.handleSnackbarOpen(data.message, 'error');
            }
            setTimeout(() => {
                handleClose();
            }, 1000);
            
        } catch (error) {
            SnackbarRef.current.handleSnackbarOpen('Error: try again later', 'error');
        }
    }

    return <>

        <CustomizedSnackbar
            open={SnackbarRef.open}
            severity={SnackbarRef.snackbarType}
            message={SnackbarRef.snackbarMessage}
            handleClose={SnackbarRef.handleClose}
            ref={SnackbarRef}
        />
        <div className="section-edit-recurrences">
            <div className="info-actual-recurrences">
                <h3>Actual Information</h3>
                <p>
                    The recurrent option is "{infoTask.recurrence && infoTask.recurrence.value}".<br />
                    The deadline for this task is {dateLimit}.<br />
                    The hourly limit for each recurrence is {infoTask.dailyLimitHours && infoTask.dailyLimitHours.value} hours<br />
                    The current task hours limit is {infoTask.hours && infoTask.hours.value} hours<br />
                    The times of past recurrences to current date is {hoursRecurrencesPast} hours, these cannot be modified.
                </p>
            </div>
            <div className="inputs-edit-recurrences">
                {infoTask.recurrence &&
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label label-recurrence">{infoTask.recurrence.headerName}</InputLabel>
                        <Select
                            // key={j}
                            value={recurrenceNew}
                            onChange={(e) => handleChange(e, infoTask.recurrence.field)}
                            label={infoTask.recurrence.headerName}
                        >
                            {infoTask.recurrence.values.map((option, k) => (
                                <MenuItem key={k} value={option.description}>{option.description}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
                {infoTask.dateInitial &&
                    <div className="date-initial-form">
                        <FormControl fullWidth >
                            <DatePicker
                                // key={j}
                                label={infoTask.dateInitial.headerName}
                                value={dateInitialNew}
                                onChange={(e) => handleChange(e, infoTask.dateInitial.field)}
                                minDate={dayjs(minDateInitial)}
                                maxDate={dayjs(maxDateInitial)}
                            />
                        </FormControl>
                    </div>
                }
                {infoTask.dateLimit &&
                    <div className="date-form">
                        <FormControl fullWidth >
                            <DatePicker
                                // key={j}
                                label={infoTask.dateLimit.headerName}
                                value={dateLimitNew}
                                onChange={(e) => handleChange(e, infoTask.dateLimit.field)}
                                minDate={dayjs(mindate)}
                                maxDate={dayjs(maxDate)}
                            />
                        </FormControl>
                    </div>
                }
                {infoTask.dailyLimitHours &&
                    <FormControl fullWidth >
                        <TextField
                            label={infoTask.dailyLimitHours.headerName}
                            type="number"
                            // key={j}
                            value={dailyLimitHoursNew}
                            onChange={(e) => handleChange(e, infoTask.dailyLimitHours.field)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ min: 0, max: 8 }}
                        />
                    </FormControl>
                }
            </div>
            <div className="result-edi-recurrences">
                <h3>Result</h3>
                <p>
                    Current hours of past non-modifiable recurrences equal {hoursRecurrencesPast} <br />
                    Hours of new recurrences equal to {valueHours} <br />
                    Total task hours are {valueHours + hoursRecurrencesPast} <br />
                </p>
            </div>
            <div className='buttons-edit-recurrences'>

                <Button
                    variant="contained"
                    className="form-button"
                    sx={{ margin: "2rem" }}
                    onClick={handleEditRecurrence}
                >
                    Accept
                </Button>
                <Button
                    variant="contained"
                    sx={{ margin: "2rem" }}
                    className="form-button"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </div>
        </div>
    </>
}


export { EditRecurrences };