
import {
    Dialog, Button, TextField,
    InputAdornment, Select, MenuItem,
    InputLabel, FormControl
} from "@mui/material";
import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { Button as Btn } from '../../../components/Shared/Button';
import '../../../components/Shared/SharedStyles.css';
import './Incomings.css';

const ModalDialogIncoming = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false)
    const [Model, setModel] = useState([]);
    const [Title, setTitle] = useState('');
    const [typeAction, setTypeAction] = useState('');
    const [emptyFields, setEmptyFields] = useState([]);
    const [mindate, setMinDate] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const ITEM_HEIGHT = 30;
    const ITEM_PADDING_TOP = 4;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        //Se envia a minima fecha por escoger el valor de hoy
        setMinDate(new Date());
        setSelectedPhoto(null);
        return () => {
            setSelectedPhoto(null);
        }
    }, [])


    const SnackbarRef = useRef();

    const rows = 1;
    const cols = Math.ceil(Model.length / rows);

    const fields = [];
    for (let i = 0; i < rows; i++) {
        fields.push(Model.slice(i * cols, i * cols + cols));
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const saveButtonClick = (e) => {
        e.preventDefault();

        try {

            let toInsertModel = {};
            for (let i = 0; i < Model.length; i++) {
                if (!Model[i].values && Model[i].value === '' && Model[i].field !== "foto") {
                    if (!emptyFields.includes(Model[i].field)) {
                        setEmptyFields(prevEmptyFields => [...prevEmptyFields, Model[i].field]);
                    }
                    SnackbarRef.current.handleSnackbarOpen(`${Model[i].headerName} field cannot be empty`, 'error');
                    return
                }
                toInsertModel[Model[i].field] = getTypeValue(Model[i].value, Model[i].type);
            }

            if (typeAction === 'create') props.onAddItem({ ...toInsertModel }, selectedPhoto)
            else props.onUpdateItem({ ...toInsertModel }, selectedPhoto)

            setTimeout(() => {
                handleClose();
            }, 2000);

        } catch (error) {
            SnackbarRef.current.handleSnackbarOpen('Error: try again later', 'error');
        }
    };


    const handleChange = (e, index) => {
        const currentField = fields.flat()[index];
        if (e.target.value === '') {
            if (!emptyFields.includes(currentField.field)) {
                setEmptyFields(prevEmptyFields => [...prevEmptyFields, currentField.field]);
            }
        } else {
            setEmptyFields(prevEmptyFields => prevEmptyFields.filter(field => field !== currentField.field));
        }


        const newFormData = [...Model];
        newFormData[index].value = e.target.value;
        setModel(newFormData);
    };

    const typeParsers = {
        int: (value) => parseInt(value, 10),
        string: (value) => String(value),
        date: (value) => new Date(value),
    };
    const getTypeValue = (value, type) => {
        type = type === 'number' ? 'int' : 'string';
        const parser = typeParsers[type];
        if (!parser) {
            throw new Error(`Unsupported type: ${type}`);
        }
        return parser(value);
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        setSelectedPhoto(file);
    };

    const handleRemovePhoto = () => {
        setSelectedPhoto(null);
    };

    useImperativeHandle(ref, () => ({
        handleOpen: handleOpen,
        setModel: setModel,
        setTitle: setTitle,
        setTypeAction: setTypeAction,
    }));

    return (
        <Dialog open={open} onClose={handleClose} >

            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />

            <form className="container-form">
                <div className = "title-form" style={{ marginBottom: '1rem' }}>
                    {Title}
                </div>
                {
                    fields.map((row, i) => (
                        <div key={i} className="type-field-incoming">
                            {row.map((item, j) => (
                                <>
                                    {item.field === 'from' ? (
                                        <FormControl fullWidth>
                                            <TextField
                                                key={j}
                                                label={item.headerName}
                                                value={item.value}
                                                type={item.type}
                                                onChange={(e) => handleChange(e, i * cols + j)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                            />
                                            
                                            {emptyFields.includes(item.field) && (
                                                <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                            )}
                                        </FormControl>
                                    ) : ((item.values && item.field === 'to') ? (
                                        <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label label-incomingType">{item.headerName}</InputLabel>
                                            <Select
                                                key={j}
                                                value={item.value}
                                                onChange={(e) => handleChange(e, i * cols + j)}
                                                label={item.headerName}
                                            >
                                                {item.values.map((option, k) => (
                                                    <MenuItem key={k} value={option}>{option}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : ((item.field === 'weight') ? (
                                        <FormControl fullWidth >
                                            <TextField
                                                label={item.headerName}
                                                type="number"
                                                key={j}
                                                value={item.value}
                                                onChange={(e) => handleChange(e, i * cols + j)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ min: 0 }}
                                            />
                                        </FormControl>
                                    ) : ((item.field === "type_name") ? (
                                        <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label label-incomingType">{item.headerName}</InputLabel>
                                            <Select
                                                key={j}
                                                value={item.value}
                                                onChange={(e) => handleChange(e, i * cols + j)}
                                                label={item.headerName}
                                            >
                                                {item.values.map((option, k) => (
                                                    <MenuItem key={k} value={option}>{option}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : ((item.field === "date") ? (
                                        <FormControl fullWidth >
                                            <DatePicker
                                                key={j}
                                                label="Date Arrived"
                                                value={item.value === '' ? null : item.value}
                                                onChange={(e) => item.value = e}
                                                minDate={dayjs(mindate)}
                                            />
                                        </FormControl>
                                    ): ((item.field === "time") ? (
                                        <FormControl fullWidth >
                                            <TimePicker
                                                label="Time Arrived"
                                                value={item.value === '' ? null : item.value}
                                                onChange={(e) => item.value = e}
                                            />
                                        </FormControl>
                                    ): null)))))}
                                </>
                            ))}
                        </div>
                    ))
                }
                {
                    fields.map((row, i) => (
                        <div key={i} className="photo-field-incoming">
                            {row.map((item, j) => (
                                <>
                                    {(item.field === 'foto') ? (
                                        <>
                                            {(!selectedPhoto && item.value === "") && <div key={j} className="incoming-photo-container">
                                                <Btn children="Upload Photo" buttonClassName="button-charge-img-incoming"/>
                                                <input id="img-input-incoming" type="file" accept="image/*" onChange={handlePhotoChange} />
                                            </div>}
                                            {selectedPhoto  && (
                                                <div key={j}>
                                                    <h4>Selected Photo: {selectedPhoto.name}</h4>
                                                    <Btn children="Remove" onClick={handleRemovePhoto} buttonClassName="button-charge-img-profile" />
                                                </div>
                                            )}
                                            {(!selectedPhoto && item.value !== "")  && (
                                                <div key={j} className="incoming-photo-container">
                                                    <Btn children="Update Photo" buttonClassName="button-charge-img-incoming"/>
                                                    <input id="img-input-incoming" type="file" accept="image/*" onChange={handlePhotoChange} />
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                </>
                            ))}
                        </div>
                    ))
                }
                <div>
                    <Button
                        variant="contained"
                        className="form-button"
                        sx={{ margin: "2rem" }}
                        onClick={saveButtonClick}
                    >
                        Accept
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ margin: "2rem" }}
                        className="form-button"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </Dialog >
    );
});


export { ModalDialogIncoming };
