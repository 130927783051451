import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { GlobalContext } from "../../../context/GlobalContext";
import { ContentHeader } from '../../../components/Shared/ContentHeader';
import { CalendarBox } from '../../../components/Shared/Calendar';
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { getStartEvent } from '../../../utils/utils';
const MySwal = withReactContent(Swal);


function CalendarAssistant() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName } = useContext(GlobalContext)
    const history = useHistory();
    const [tasksEvents, setTasksEvents] = useState();

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)

                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const fetchAssignedTasks = async () => {
        const url = `${API_BASE_URL}/tasks/tasksByAssistant?email=${encodeURIComponent(emailGlobal)}&recurrences=true`;
        try {
            const response = await consumeFetch(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            const data = await response.json();

            const eventsCalendar = await data.data.map((item, index) => {
                var date = new Date(item.date_limit);
                var userTimezoneOffset = date.getTimezoneOffset() * 60000;

                const formattedDate = new Date(date.getTime() + userTimezoneOffset);
                formattedDate.setHours(23, 59, 59);
                return {
                    id: index,
                    title: item.taskTitle,
                    start: getStartEvent(item),
                    end: formattedDate,
                    description: item.description,
                }
            });

            setTasksEvents(eventsCalendar);
        } catch (error) {
            console.log(error)
            throw new Error("Error obteniendo data para eventos de calendar", error);
        }
    };

    useEffect(() => {
        fetchAssignedTasks();
    }, []);

    return <React.Fragment>
        <div className="calendar">
            <div className="calendar-header">
                <ContentHeader text="Calendar Tasks Assigned" />
            </div>
            <div className="calendar-body">
                {tasksEvents ? <CalendarBox events={tasksEvents} />
                    :
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress style={{ height: "50px", width: "100px" }} color="inherit" />
                    </Backdrop>
                }
            </div>
        </div>
    </React.Fragment>
}

export { CalendarAssistant };