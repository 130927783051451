import React, { useState, useRef } from "react";
import { Header } from "../../../components/Shared/Header";
import { Button } from "../../../components/Shared/Button";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';

import "./InitialStep.css";
import logo from '../../../assets/images/logos/logo-assisttu-green-2.png';


function InitialStep(props) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { onNext, register, errors, watch, getValues } = props;
    const SnackbarRef = useRef();

    const validateUser = async () => {
        if (!getValues("email")) {
            SnackbarRef.current.handleSnackbarOpen('Please, fill in the e-mail address field!', 'warning');
            return;
        }
        const response = await fetch(`${API_BASE_URL}/users/exist/${getValues("email")}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.status == '404') {
            onNext();
            return;
        }
        if (response.ok) {
            SnackbarRef.current.handleSnackbarOpen('This email already exists in Assisttu', 'warning');
        } else {
            SnackbarRef.current.handleSnackbarOpen('An error has occurred verifying email, please contact support!', 'error');
        }
    }

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="signup-frame-initial-step">
                <div className="hero-banner">
                    <img className='logo-hero'
                        src={logo}
                        alt='Assisttu Logo' ></img>
                </div>
                <div className="frame-initial-step">
                    <div className="signup-stepper">
                        <span className="subtitle">Start here with your information</span>
                    </div>
                    <div className="content-wrap">
                        <div className="form-register-first-step">
                            <div className="form-group-input">
                                <div>
                                    <input
                                        className={`register-input ${errors.email && 'error'}`}
                                        placeholder="Email"
                                        type="text"
                                        {...register(
                                            "email",
                                            {
                                                required: "Email is required",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email address"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.email && <span className="error-message">{errors.email.message}</span>}
                                </div>
                                <div>
                                    <input
                                        className={`register-input ${errors.password && 'error'}`}
                                        placeholder="Password"
                                        type="password"
                                        {...register(
                                            "password",
                                            {
                                                required: "Password is required",
                                                pattern: {
                                                    value: /^(?=.*[0-9!@#$%^&*])[\S]{6,}$/,
                                                    message: "Your password must be at least 6 characters long and include a number or special character."
                                                }
                                            }
                                        )}
                                    />
                                    {errors.password && <span className="error-message">{errors.password.message}</span>}
                                </div>
                                <div>
                                    <input
                                        className={`register-input ${errors.verifyPassword && 'error'}`}
                                        placeholder="Verify Password"
                                        type="password"
                                        {...register("verifyPassword", {
                                            required: "Password is required",
                                            validate: (value) => value === watch('password') || "The passwords do not match"
                                        })}
                                    />
                                    {errors.verifyPassword && <span className="error-message">{errors.verifyPassword.message}</span>}
                                </div>
                            </div>
                            <Button
                                buttonClassName="button-signup-first-step"
                                onClick={validateUser}
                                children="Create account"
                            />

                        </div>
                        <div className="login-ref">
                            <span className="login-text">Already have an account?&nbsp;<a className="login-links" href="/"> Login here</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export { InitialStep };
