// PrivateRoute.js
import React, { useEffect, useContext, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';
import Cookies from 'js-cookie';
import socket from "../../utils/socket.js";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import ApiMiddleware from "../Shared/ApiMiddleware";
const MySwal = withReactContent(Swal);

function PrivateRoute({ component: Component, ...rest }) {
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, userName, setUserName } = useContext(GlobalContext)
    const history = useHistory();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const url = `${API_BASE_URL}/users/${emailGlobal}`;
    const [isLoading, setIsLoading] = useState(true);

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    async function fetchToken() {
        if (!token) {
            try {
                const refreshTokenCookie = Cookies.get('refreshToken');
                const response = await consumeFetch(`${API_BASE_URL}/users/refresh/getRefresh`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `${refreshTokenCookie}`
                    },
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message);
                }
                const newToken = data.token;

                socket.auth = { userID: idUserGlobal, username: userName, email: emailGlobal };
                socket.connect();

                setToken(newToken);
                return newToken;
            } catch (error) {
                // Type error here
                console.log(error);
            }
        }
    }

    useEffect(() => {
        async function getUser() {
            try {
                const fetchedToken = await fetchToken();
                if (!fetchedToken) {
                    setIsLoading(false);
                    return;
                }
                const response = await consumeFetch(url, {
                    headers: {
                        "Authorization": token ? `${token}` : `${fetchedToken}`
                    },
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message);
                }
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array to run the effect only once


    return isLoading ? (
        // Render a loading state or spinner while waiting for the token and user data
        // For example:
        <div>Loading...</div>
    ) : (
        <Route
            {...rest}
            render={(props) =>
                token ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
}

export { PrivateRoute };
