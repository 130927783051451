import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from '../../../../context/GlobalContext';
import ApiMiddleware from "../../../../components/Shared/ApiMiddleware";
import './taskList.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { CuadroTask } from './CuadroTask';
import { Switch } from "@mui/material";
import { CuadroInfo } from "./CuadroInfo";
import { CuadroInfoTask } from "./CuadroInfoTask";
import { CuadroInfoDescription } from "./CuadroInfoDescription";
import { CuadroInfoDate } from "./CuadroInfoDate";

const MySwal = withReactContent(Swal);

function TaskList() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setToken, token, setEmailGlobal, setIdUserGlobal, setUserName, emailGlobal } = useContext(GlobalContext)
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [rows, setRows] = useState();
    const [adminRows, setAdminRows] = useState([]);
    const [salesRows, setSalesRows] = useState([]);
    const [cusSupRows, setCusSupRows] = useState([]);
    const bgColorAr = ['rgb(70, 139, 255)', 'rgb(183, 133, 255)', 'rgb(237, 192, 81)', 'rgb(234, 128, 148)']



    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const compare = (a, b) => {
        if (a.task_title < b.task_title) {
            return -1;
        }
        if (a.task_title > b.task_title) {
            return 1;
        }
        return 0;
    }

    const fetchData = async () => {
        const params = new URLSearchParams();
        let endpoint = `${API_BASE_URL}/tasks/tasksByClient`;
        params.append('email', emailGlobal);
        const url = `${endpoint}?${params}`;
        try {
            const response = await consumeFetch(url, {
                headers: {
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            const data = await response.json();
            const newRows = data.data.map((item, index) => {
                const assistantEmail = item.assistantId ? item.assistantId.email : "Unassigned";
                var dateLimit = item.date_limit ? new Date(item.date_limit) : new Date();
                var userTimezoneOffset = dateLimit.getTimezoneOffset() * 60000;
                dateLimit = new Date(dateLimit.getTime() + userTimezoneOffset);
                const year = dateLimit.getFullYear();
                const month = dateLimit.getMonth() + 1;
                const day = dateLimit.getDate();

                // Formatear la fecha en el formato deseado "MM-DD-YYYY"
                const fechaFormateadaStr = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
                return {
                    id: index,
                    // taskTypeName: item.taskType_Id ? item.taskType_Id.task_type_name : "",
                    statusName: item.status_Id.status_name,
                    assistantEmail: assistantEmail,
                    task_id: item.id,
                    dateLimit: fechaFormateadaStr,
                    ...item
                }
            });
            const updatedRows = await Promise.all(newRows.map(async (element) => {
                const urlGetSecrets = `${API_BASE_URL}/task_secrets/getByTaskId/${element.id}`;

                const responseSecrets = await consumeFetch(urlGetSecrets, {
                    headers: {
                        Authorization: `${token}`
                    }
                });
                const dataReturned = await responseSecrets.json();
                const valuesVault = dataReturned.data.map(item => item.secret.name_secret);
                return {
                    ...element,
                    valuesVault: valuesVault,
                };
            }));
            console.log(updatedRows)
            setAdminRows(updatedRows.filter(row => row.statusName == "In Progress"));
            // setAdminRows(updatedRows.filter(row => row.taskTypeName == "Administrative"));
            // setSalesRows(updatedRows.filter(row => row.taskTypeName == "Sales"));
            // setCusSupRows(updatedRows.filter(row => row.taskTypeName == "Customer Support"));
        } catch (error) {
            console.error(error);
            // Manejar el error
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (id) => {
        setShow(!show)
        const element = document.getElementById(id);
        if (element) {
            element.classList.toggle('hide');
        }
    }


    return (
        <React.Fragment>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {adminRows.length > 0 &&
                    <>
                        <ListItem className="text-in-progress">
                            <h4 style={{ marginBlockStart: "3px", marginBlockEnd: "3px" }}>In Progress</h4>
                        </ListItem>
                        {adminRows.length > 0 && adminRows.map((option, i) => {
                            const labelId = `admin-task-${i}`;
                            const bgColorMain = (i % 4);
                            return (
                                <>
                                    <ListItem>
                                        <CuadroTask asistente={option} bgColor={bgColorAr[bgColorMain]}></CuadroTask>
                                        <IconButton edge="end" aria-label="delete" style={{ color: '#004752' }}>
                                            <Switch onChange={() => handleChange(labelId)} />
                                        </IconButton>
                                    </ListItem>
                                    <div id={labelId} style={{ paddingLeft: '20px' }} className="hide">
                                        

                                        <ListItem>
                                            <CuadroInfoDate asistente={option} bgColor={bgColorMain>2 ? bgColorAr[bgColorMain-2] : bgColorAr[bgColorMain+2]}  ></CuadroInfoDate>

                                        </ListItem>
                                    </div>
                                </>
                            );
                        })}

                        <Divider style={{ borderColor: '#000', borderBottomWidth: 'medium' }} />
                    </>
                }
                
                
            </List>

        </React.Fragment>
    );
}

export { TaskList };
