import React, {useEffect, useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import '../vault.css';

function Table(props) {
  const { data, handleEdit, handleDelete} = props;
  const [dataVault, setDataVault] = useState();

  useEffect(() => {
    setDataVault(data);
  },[data]);
  

  return (
    <React.Fragment>
      { dataVault || dataVault !== undefined || dataVault!= null ?
      <div className="tabla-complete-vault">
          <table className="vault-table-info">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Password</th>
              </tr>
            </thead>
            <tbody>
              {dataVault.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td><p className='name-item-vault'>{item.name}</p></td>
                  <td>********</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className="vault-table-actions">
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataVault.map((item) => (
              <tr key={item.id}>
                <td className="buttons-vault-actions">
                  <button className="button-vault-edit" onClick={() => handleEdit(item.id)}><EditIcon/></button>
                  <button className="button-vault-delete" onClick={() => handleDelete(item.id)}><DeleteIcon/></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      :
        <div>Don´t have passwords</div>
      }
    </React.Fragment>
  );
}

export {Table};
