import React from 'react'
import imgDefaultUser from '../../../../assets/images/programador.png';
import { Button } from "../../../../components/Shared/Button";
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { IoIosStar } from 'react-icons/io';
import './AssistantBox.css'
import { LinearProgressWithLabel } from '../../../../components/Shared/ProgressBar';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { IoChatboxEllipsesOutline } from "react-icons/io5";

import { FiEdit } from "react-icons/fi";

export const CuadroTask = (props) => {

    const { asistente, handleClick, bgColor } = props;
    console.log(asistente.task_id)
    return (
        <div className='cuadroInfoTask sombra2' style={{ background: `rgba(${bgColor?.slice(4, -1)}, 0.2)`, borderColor: bgColor, borderWidth: '3px', borderStyle: 'solid' }}>
            <div className='name-container'>
                <div className="task-box">
                    <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Task:</b> {asistente.taskTitle}</span><br />
                    <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Assistant:</b> {asistente.assistantId.name}</span><br />
                    <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Status:</b> {asistente.statusName}</span><br />
                    <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Due Date:</b> {asistente.dateLimit}</span><br />



                </div>
                <div className="buttons-left">
                    <Link to={{
                    pathname: '/user/MessagesWindow',
                    state: {
                        edit: asistente.task_id,
                    }}}>
                        <IoChatboxEllipsesOutline className='task-icon' />
                    </Link>
                    <Link 
                    to={{
                        pathname: '/user/Tasks',
                        state: {
                            edit: asistente.task_id,
                        }}}>
                        <FiEdit className='task-icon' />
                    </Link>
                </div>
            </div>
        </div>
    )
}
