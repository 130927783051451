
import {
    Dialog, Button, TextField,
    InputAdornment, FormControl, FormControlLabel, Checkbox
} from "@mui/material";
import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from 'react-phone-number-input'
import { GlobalContext } from '../../../context/GlobalContext'
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import DeleteIcon from '@mui/icons-material/Delete';
import '../../../components/Shared/SharedStyles.css';
import './Contract.css';
const MySwal = withReactContent(Swal);

const ModalFormSuspendPayments = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false)
    const [Model, setModel] = useState([]);
    const [Title, setTitle] = useState('');
    const [typeAction, setTypeAction] = useState('');

    const { planesUsuario, setPlanesUsuario, setToken, token, emailGlobal, setEmailGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext);
    const {
        control
    } = useForm();

    const ITEM_HEIGHT = 30;
    const ITEM_PADDING_TOP = 4;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const SnackbarRef = useRef();

    const rows = 1;
    const cols = Math.ceil(Model.length / rows);

    const fields = [];
    for (let i = 0; i < rows; i++) {
        fields.push(Model.slice(i * cols, i * cols + cols));
        console.log(fields)
    }

    useEffect(() => {
        console.log(planesUsuario)
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
            }
        }

    }
    const saveButtonClick = (planName) => {
        setActiveBlur(true);
        handleClose();
        MySwal.fire({
            icon: 'warning',
            title: `Are you Sure?`,
            text: `Do you want to cancel the ${planName} plan ?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes, I want to unsuscribe',
            denyButtonText: `No, I want to continue`,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                consumeFetch(`${API_BASE_URL}/plans/cancelPlan`, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${token}`
                    },
                    body: JSON.stringify({
                        email: emailGlobal,
                        planName
                    })
                }
                ).then(response => response.json()).then(data => {
                    if (data.status === "success") {
                        setActiveBlur(true);
                        MySwal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Your plan was cancelled.',
                            showConfirmButton: false,
                            timer: 1700,
                            backdrop: true,
                            customClass: {
                                popup: 'popup-sweet',
                                title: 'title-sweet',
                                htmlContainer: 'text-sweet',
                                confirmButton: 'confirm-button-sweet',
                                denyButton: 'deny-button-sweet',
                            }
                        }).finally(() => {
                            setActiveBlur(false);
                            setPlanesUsuario(data.userPlans);
                        });
                    } else {
                        throw new Error("Error on api call");
                    }
                }).catch(error => {
                    console.error(error);
                    setActiveBlur(true);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops ...',
                        text: 'There was a unexpected error, please contact support.',
                        showConfirmButton: false,
                        timer: 1700,
                        backdrop: true,
                        customClass: {
                            popup: 'popup-sweet',
                            title: 'title-sweet',
                            htmlContainer: 'text-sweet',
                            confirmButton: 'confirm-button-sweet',
                            denyButton: 'deny-button-sweet',
                        }
                    }).finally(() => { setActiveBlur(false) });
                });
            } else if (result.isDenied) {
            }
        }).finally(() => { setActiveBlur(false) });
    };


    useImperativeHandle(ref, () => ({
        handleOpen: handleOpen,
        setModel: setModel,
        setTitle: setTitle,
        setTypeAction: setTypeAction,
    }));

    return (
        <Dialog open={open} onClose={handleClose} >

            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />

            <form className="container-form">
                <div className="title-form" style={{ marginBottom: '1rem' }}>
                    {Title}
                </div>
                {
                    fields.map((row, i) => (
                        <>
                            {row?.map((row, i) => (
                                <div key={i} className="other-fields-formCancel">
                                     ●Plan: {row.field}
                                     
                                    <Button
                                        variant="outlined"
                                        sx={{ margin: "2rem" }}
                                        className="form-button"
                                        onClick={()=>saveButtonClick(row.field)}
                                    >
                                        Unsuscribe Plan
                                    </Button>
                                </div>
                            ))}
                            </>
                    ))
                }

                <div>

                    <Button
                        variant="contained"
                        sx={{ margin: "2rem" }}
                        className="form-button"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </Dialog >
    );
});


export { ModalFormSuspendPayments };
