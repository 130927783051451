import React from 'react';
import rightArrow from '../../../assets/images/register-right-arrow.png'

function StepperRegister(props) {

    const { steps , activeStep } = props;

    return <React.Fragment>
            {
                steps.map((item,index) => (
                    <div key={index} className={`stepper-option ${ index<=activeStep && "active" }`}>
                        {item.label}
                        { index==activeStep && <img className="" src={rightArrow} alt="rigth row"/> }
                        
                    </div>
                ))
            }
    </React.Fragment>
}

export {StepperRegister};