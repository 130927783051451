import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { GlobalContext } from "../../../context/GlobalContext";
import { ContentHeader } from '../../../components/Shared/ContentHeader';
import { CalendarBox } from '../../../components/Shared/Calendar';
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { getStartEvent, changeTimeZone } from '../../../utils/utils';
const MySwal = withReactContent(Swal);


function CalendarClient() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, emailGlobal, setEmailGlobal, idUserGlobal , setIdUserGlobal, setUserName } = useContext(GlobalContext)
    const history = useHistory();
    const [tasksEvents, setTasksEvents] = useState();
    const [events, setEvents] = useState();

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)

                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const fetchTasks = async () => {
        const params = new URLSearchParams();
        let endpoint = `${API_BASE_URL}/tasks/tasksByClient`;
        params.append('email', emailGlobal);
        params.append('recurrences', true);
        const url = `${endpoint}?${params}`;
        try {
            const response = await consumeFetch(url, {
                headers: {
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                console.log('Error en solicitud de tasks by client');
                
            }
            const data = await response.json();

            const dataEvents = data.data;

            dataEvents.sort((a, b) => {
                const partOne = a.parentTask || '';
                const partTwo = b.parentTask || '';
                return partOne.localeCompare(partTwo)
            })
            var cont = 1;
            var uidParent = '';
            const eventsCalendar = await dataEvents.map((item, index) => {
                var date = new Date(item.date_limit);
                const dateTZ = changeTimeZone(date);
                dateTZ.setHours(23, 59, 59);
                if ( item.parentTask === null || item.parentTask === undefined ) {
                    cont = cont + 1;
                } else { 
                    if ( item.parentTask !== uidParent ) {
                        uidParent = item.parentTask;
                        cont = cont + 1;
                    }
                }
                return {
                    id: index,
                    title: item.taskTitle,
                    start: getStartEvent(item),
                    end: dateTZ,
                    description: item.description,
                    varColor: cont,
                }
            });

            setTasksEvents(eventsCalendar);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchEvents = async () => {
        let url = `${API_BASE_URL}/user_calendar/${idUserGlobal}`;
        try {
            const response = await consumeFetch(url, {
                headers: {
                    Authorization: `${token}`
                }
            });
            if (!response.ok) {
                console.log('Error en solicitud de events by client');
                
            }
            const data = await response.json();

            const dataEvents = data;

            const eventsCalendar = await dataEvents.map((item, index) => {
                return {
                    id: index,
                    title: `Event - ${item.title}`,
                    start: new Date(item.startDate),
                    end: new Date(item.endDate),
                    description: item.description,
                    // varColor: cont,
                }
            });

            setEvents(eventsCalendar);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchTasks();
        fetchEvents();
    }, []);

    return <React.Fragment>
        <div className="calendar">
            <div className="calendar-header">
                <ContentHeader text="Calendar" />
            </div>
            <div className="calendar-body">
                {tasksEvents !== undefined && events !== undefined ? <CalendarBox events={tasksEvents} eventsByAssistant={events} />
                    :
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress style={{ height: "50px", width: "100px" }} color="inherit" />
                    </Backdrop>
                }
            </div>
        </div>
    </React.Fragment>
}

export { CalendarClient };