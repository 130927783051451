import * as muiMaterial from '@mui/material';
import * as muiDataGrid from '@mui/x-data-grid';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useContext, useRef, useState, useEffect } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { Delete, Create, AddCircle, Margin } from '@mui/icons-material';
import { Button } from "../../../components/Shared/Button";
import { useHistory } from "react-router-dom";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { ModalDialogIncoming } from './ModalFormIncoming';
import ApiMiddleware from '../../../components/Shared/ApiMiddleware';
import dayjs from 'dayjs';
import './Incomings.css';

const pageSizeOptions = [5, 10, 20];
const MySwal = withReactContent(Swal);

function DataGridIncomings(props) {
    const { setToken, token, emailGlobal, setEmailGlobal, idUserGlobal, setIdUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const { pRows, pColumns, pTitle, decrementActiveRowCount } = props;

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const SnackbarRef = useRef();
    const ModalDialogRef = useRef();
    const [Rows, setRows] = useState(pRows);
    const [Columns] = useState(pColumns);
    const [selectedId, setSelectedId] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [allValuesClient, setAllValuesClient] = useState([]);
    const [allValuesIncomingType, setAllValuesIncomingType] = useState([]);

    useEffect(() => {
        async function getAllValuesClientandIncomingType() {
            let dataReturned = await fetchData("GET", `${API_BASE_URL}/incomingType/`, null);
            setAllValuesIncomingType(dataReturned.data.map(item => item.incomingTypeName));
            dataReturned = await fetchData("POST", `${API_BASE_URL}/users/getUserByPlan`, {plan: "Standard Plan"});
            setAllValuesClient(dataReturned.data.map(item => item.email));
        }

        getAllValuesClientandIncomingType();
    }, []);

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const handleRowSelection = (selection) => {
        if (selection.length > 1) {
            const selectionSet = new Set(selectedId);
            const result = selection.filter((s) => !selectionSet.has(s));
            setSelectedId(result);
            getModelObject(result)
        } else {
            setSelectedId(selection);
            getModelObject(selection)
        }
    }

    const getModelObject = (pId) => {
        setSelectedModel(Rows.filter(x => x.id === pId[0])[0]);
    }

    const openModal = (pAction) => {
        ModalDialogRef.current.setTitle(pAction + pTitle);
        ModalDialogRef.current.handleOpen();
    };

    const createButtonClick = async () => {
        const modifiedColumns = Columns.map(column => {
            if (column.field === 'id' || column.field === 'incoming_id' || column.field === 'incoming_id_detail') {
                // Omitir la propiedad 'description'
                return null;
            }
            if (column.field === 'type_name') {
                column.values = allValuesIncomingType;
                return column;
            }
            if (column.field === 'to') {
                column.values = allValuesClient;
                return column;
            }
            // Conservar las demás propiedades
            return { ...column };
        }).filter(Boolean); // Filtrar los elementos nulos
        const initialModel = modifiedColumns.map(column => {
            if (column.field === 'type_name' || column.field === 'to') {
                // Incluir el campo 'values' en el modelo
                return { ...column, value: '', values: column.values };
            }
            // Conservar las demás propiedades
            return { ...column, value: '' };
        });
        console.log(initialModel);
        ModalDialogRef.current.setModel(initialModel);
        ModalDialogRef.current.setTypeAction('create');
        openModal("Create ");
    };

    const editButtonClick = () => {
        if (notSelectedRow()) return;
        const modifiedColumns = Columns.map(column => {
            if (column.field === 'id' || column.field === 'incoming_id_detail') {
                // Omitir la propiedad 'description'
                return null;
            }
            if (column.field === 'type_name') {
                column.values = allValuesIncomingType;
                return column;
            }
            if (column.field === 'to') {
                column.values = allValuesClient;
                return column;
            }
            // Conservar las demás propiedades
            return { ...column };
        }).filter(Boolean); // Filtrar los elementos nulos
        const modelColumns = modifiedColumns.map(column => {
            if (column.field === 'date') {
                let data_splitted = selectedModel["date"].split(" ")[0];
                let date = new Date();
                date.setDate(data_splitted.split("/")[0]);
                date.setMonth(Number(data_splitted.split("/")[1])-1);
                date.setFullYear(data_splitted.split("/")[2]);
                return { ...column, value: dayjs(date) };
            }
            if (column.field === 'time') {
                let data_splitted = selectedModel["date"].split(" ")[1];
                let date = new Date();
                date.setHours(data_splitted.split(":")[0]);
                date.setMinutes(data_splitted.split(":")[1]);
                return { ...column, value: dayjs(date) };
            }
            return { ...column, value: selectedModel[column.field] };
        });
        SnackbarRef.current.handleSnackbarOpen('Edit Row', 'info');
        ModalDialogRef.current.setTypeAction('edit');
        ModalDialogRef.current.setModel(modelColumns);
        
        openModal("Edit ");
    };

    const deleteButtonClick = async () => {
        if (notSelectedRow()) return;
        setActiveBlur(true);
        /* eslint-disable no-restricted-globals */
        MySwal.fire({
            title: 'Are you sure you want to delete this Incoming?',
            width: 430,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes, Delete.',
            denyButtonText: `Cancel`,
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const jsonObject = JSON.parse(JSON.stringify(selectedModel));
                const dataReturned = await fetchData("DELETE", `${API_BASE_URL}/incomings/${jsonObject.id}`, null);
                if (!dataReturned) {
                    SnackbarRef.current.handleSnackbarOpen('Delete incoming was not possible, please contact technical support.', 'error');
                    return;
                }
                let tempRows = [...Rows];
                tempRows.splice(tempRows.indexOf(selectedModel), 1);
                setRows(tempRows);
                decrementActiveRowCount();
                /* eslint-enable no-restricted-globals */
                SnackbarRef.current.handleSnackbarOpen('Incoming Deleted', 'success');
            } else if (result.isDenied) {
                return
            }
        }).finally(() => {setActiveBlur(false)});

        // if (!confirm("Are you sure you want to delete this item?")) return;

        // SnackbarRef.current.handleSnackbarOpen('Delete Row', 'error');

    };

    const fetchData = async (type, endpoint, bodyToSend, isFormData=false) => {
        try {
            let headers = isFormData ? {
                Authorization: `${token}`
            } : {
                "Content-Type": "application/json",
                Authorization: `${token}`
            };
            let dataToSend = isFormData ? bodyToSend : JSON.stringify(bodyToSend);
            const response = await consumeFetch(endpoint, {
                method: type,
                headers,
                ...(bodyToSend && { body: dataToSend}) // Incluir el cuerpo solo si jsonObject tiene un valor asignado
            });
            if (response.ok) {
                const data = await response.json();
                return data; // Devolver el objeto retornado del servicio REST
            } else {
                const data = await response.json();
                if (data.message === "Hour Limit exceed the limit of the user plan.") {
                    SnackbarRef.current.handleSnackbarOpen('Hour Limit exceed the limit of the user plan.', 'error');
                } else if (data.message === "User does not have a plan") {
                    SnackbarRef.current.handleSnackbarOpen("User does not have a plan, so we can't create a task", 'error');
                } else {
                    SnackbarRef.current.handleSnackbarOpen(data.message, 'error');
                }
                return null;
            }
        } catch (error) {
            throw new Error('Error en la solicitud');
        }
    };

    const handleAddItem = async (pModel, selectedPhoto) => {
        let date = new Date(pModel.date);
        pModel.date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        date = new Date(pModel.time);
        pModel.time = `${date.getHours() < 10 ? '0'+date.getHours(): date.getHours()}:${date.getMinutes() < 10 ? '0'+date.getMinutes(): date.getMinutes()}`;
        try {
            const formData = new FormData();
            if (selectedPhoto !== null && selectedPhoto !== undefined) {
                formData.append("upload_foto", selectedPhoto);
            }else{
                formData.append("upload_foto", "");
            }
            console.log(pModel);
            formData.append("title", '');
            formData.append("description", '');
            formData.append("sender", pModel.from);
            formData.append("type", pModel.type_name);
            formData.append("targetClientEmail", pModel.to);
            formData.append("assistantId", idUserGlobal);
            formData.append("weight",  pModel.weight);
            formData.append("dateArrived",  pModel.date);
            formData.append("timeArrived",  pModel.time);
    
            const dataReturned = await fetchData("POST", `${API_BASE_URL}/incomings/`, formData, true);
            if (dataReturned !== null) {
                // Formatear la fecha en el formato deseado
                const fechaFormateadaStr = `${dataReturned.data.dateArrived} ${dataReturned.data.timeArrived}`;
                const mappedModel = {
                    ...pModel,
                    id: dataReturned.data.id,
                    incoming_id: dataReturned.data.id,
                    from: dataReturned.data.sender,
                    to: dataReturned.data.client.email,
                    type_name: dataReturned.data.type.incoming_type_name,
                    weight: dataReturned.data.weightIncoming,
                    date: fechaFormateadaStr,
                };

                SnackbarRef.current.handleSnackbarOpen('Incoming saved successfully', 'success');

                let tempRows = [...Rows];
                tempRows.push(mappedModel);
                setRows(tempRows);
            }
        } catch (error) {
            console.error(error);
        }

    };

    const handleUpdateItem = async (pModel, selectedPhoto) => {
        let date = new Date(pModel.date);
        pModel.date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        date = new Date(pModel.time);
        pModel.time = `${date.getHours() < 10 ? '0'+date.getHours(): date.getHours()}:${date.getMinutes() < 10 ? '0'+date.getMinutes(): date.getMinutes()}`;
        try{
            const formData = new FormData();
            if (selectedPhoto !== null && selectedPhoto !== undefined) {
                formData.append("upload_foto", selectedPhoto);
            }else{
                formData.append("upload_foto", undefined);
            }
            formData.append("sender", pModel.from);
            formData.append("type", pModel.type_name);
            formData.append("targetClientEmail", pModel.to);
            formData.append("assistantId", idUserGlobal);
            formData.append("weight",  pModel.weight);
            formData.append("dateArrived",  pModel.date);
            formData.append("timeArrived",  pModel.time);
            const dataReturned = await fetchData("PUT", `${API_BASE_URL}/incomings/updateIncoming/${pModel.incoming_id}`, formData, true);
            if (dataReturned !== null) {
                // Formatear la fecha en el formato deseado
                const fechaFormateadaStr = `${dataReturned.data.dateArrived} ${dataReturned.data.timeArrived}`;
                const mappedModel = {
                    ...pModel,
                    id: dataReturned.data.id,
                    incoming_id: dataReturned.data.id,
                    from: dataReturned.data.sender,
                    to: dataReturned.data.client.email,
                    type_name: dataReturned.data.type.incoming_type_name,
                    weight: dataReturned.data.weightIncoming,
                    date: fechaFormateadaStr,
                };

                SnackbarRef.current.handleSnackbarOpen('Incoming updated successfully', 'success');
                let tempRows = [...Rows];
                tempRows[tempRows.indexOf(selectedModel)] = mappedModel;
                setSelectedModel(mappedModel);
                setRows(tempRows);
            }
        }catch(error){
            console.error(error);
        }
    };

    const notSelectedRow = () => {
        if (selectedId?.length === 0 || selectedId === undefined) {
            SnackbarRef.current.handleSnackbarOpen('Error: You must select a record to continue.', 'error');
            return true;
        }
        return false;
    }

    return (
        <muiMaterial.Box
            sx={{
                width: '100%',
                height: '10',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 2,
            }} >

            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="incoming-option-buttons">
                <Button buttonClassName="button-incoming-view new-incoming" icon={<AddCircle />} childrenClassName="" onClick={createButtonClick} children="New incoming" />
                <div className="right-incoming-option-buttons">
                    <Button buttonClassName="button-incoming-view new-incoming" icon={<Delete />} childrenClassName="" onClick={deleteButtonClick} children="Remove" />
                    <Button buttonClassName="button-incoming-view new-incoming" icon={<Create />} childrenClassName="" onClick={editButtonClick} children="Edit" />
                </div>
            </div>

            <ModalDialogIncoming
                open={ModalDialogRef.open}
                handleClose={ModalDialogRef.handleClose}
                ref={ModalDialogRef}
                onAddItem={handleAddItem}
                onUpdateItem={handleUpdateItem}
            />
            <div className='gridIncomings'>
                <muiDataGrid.DataGrid
                    rows={Rows}
                    columns={Columns.map((column, index) => ({
                        ...column,
                        align: index === column.length - 1 ? 'center' : 'right',
                        headerAlign: 'center',
                    })).filter((column) => column.show === true)}
                    pageSize={10} rowsPerPageOptions={pageSizeOptions}
                    sx={{
                        background: 'white',
                        overflowX: 'auto',
                    }}

                    autoHeight
                    autoWidth
                    checkboxSelection
                    selectionModel={selectedId}
                    hideFooterSelectedRowCount
                    onSelectionModelChange={(selection) => { handleRowSelection(selection) }}
                    initialState={{ pinnedColumns: { right: ['actions'] } }}
                />
            </div>

        </muiMaterial.Box>
    );
}


export { DataGridIncomings };
