import React, { useContext, useRef, useState, useEffect } from "react";
import { Header } from "../../../components/Shared/Header";
import "./RegisterSteps.css";
import { Button } from "../../../components/Shared/Button";
import rightArrow from '../../../assets/images/register-right-arrow.png'
import leftArrow from '../../../assets/images/register-left-arrow.png'
import { BtnSelect } from "../../../components/Shared/BtnSelect";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';

import { AiOutlineSetting, AiOutlineDollar, AiOutlineQrcode, AiOutlineBgColors, AiOutlineCustomerService, AiOutlineGroup, AiOutlineComment, AiOutlineTeam, AiOutlinePlusSquare, AiOutlineBarChart } from 'react-icons/ai';
import { GiPerspectiveDiceSixFacesRandom } from 'react-icons/gi';
import { MdDeveloperMode } from 'react-icons/md';

function InterestsStep(props) {
    const { onBack, onNext, register, setValue, listInterests } = props;
    const SnackbarRef = useRef();

    const [selectedInterests, setSelectedInterests] = useState([]);

    const interestIcons = {
        "Administrative":<AiOutlineSetting style={{height:'44px',width:'44px'}}/>,
        "HR Outsourcing":<AiOutlineGroup style={{height:'44px',width:'44px'}}/>,
        "Sales":<AiOutlineDollar style={{height:'44px',width:'44px'}}/>,
        "Customer Support":<AiOutlineCustomerService style={{height:'44px',width:'44px'}}/>,
        "Graphic Design":<AiOutlineBgColors style={{height:'44px',width:'44px'}}/>,
        "Finance Work":<AiOutlineBarChart style={{height:'44px',width:'44px'}}/>,
        "Marketing":<AiOutlineQrcode style={{height:'44px',width:'44px'}}/>,
        "Web or App Development":<MdDeveloperMode style={{height:'44px',width:'44px'}}/>,
        "Virtual Assistance": <AiOutlineComment style={{height:'44px',width:'44px'}}/>,
        "Personal Errands": <AiOutlineTeam style={{height:'44px',width:'44px'}}/>,
        "Other": <AiOutlinePlusSquare style={{height:'44px',width:'44px'}}/>,
        "Default": <GiPerspectiveDiceSixFacesRandom style={{height:'44px',width:'44px'}}/>
    }

    useEffect(() => {
        if(!listInterests){
            SnackbarRef.current.handleSnackbarOpen('An error has occurred charging Interests, please contact support!!', 'error');
        }

    },[listInterests])

    const handleInterestClick = (interest) => {
        if (selectedInterests.includes(interest)) {
            setSelectedInterests(selectedInterests.filter(i => i !== interest));
        } else {
            setSelectedInterests([...selectedInterests, interest]);
        }
        setValue("userInterests", selectedInterests);
    };

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="form-frame">
                <div className="frame-signup">
                    <div className="section-header">
                        <Header level={2} headerClassName="subtitle" children="How can we assist you?" />
                        
                    </div>
                    <div className="section-wrap-interest" {...register("userInterests")}>
                        {listInterests && 
                            listInterests.map((item, index) => {
                                const icon = interestIcons[item.taskTypeName] !== undefined ? interestIcons[item.taskTypeName] : interestIcons["Default"];
                                
                                return <BtnSelect
                                    key={index}
                                    divClassName="select-interest"
                                    buttonClassName={"likes-button" + (selectedInterests.includes(item.taskTypeName) ? " selected" : "")}
                                    children={item.taskTypeName}
                                    childrenClassName="likes-button-children"
                                    icon={icon}
                                    iconClassName="likes-button-icon"
                                    onClick={() => handleInterestClick(item.taskTypeName)}>

                                </BtnSelect>
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="buttons-form interests">
                <Button buttonClassName="button-signup previous"
                    onClick={() => { onBack(); }}
                    children="Go back"
                    image={leftArrow}
                    imgClassName="left-arrow-register"
                    imageAlt="Back Arrow"
                    imageSide="left"
                    three={true}
                />
                <Button type="submit"
                    buttonClassName="button-signup next"
                    onClick={() => { onNext(); }}
                    children="Next"
                    image={rightArrow}
                    imgClassName="right-arrow-register"
                    imageAlt="Next Arrow"
                    imageSide="right"
                    three={true}
                />
            </div>
        </React.Fragment>
    );
};

export { InterestsStep };
