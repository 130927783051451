import React from 'react';
import path from './186514.mp4';
import imagenLogin from '../../../../assets/images/logos/logo-assisttu-petroleum.png';
import { Button } from '../../../../components/Shared/Button';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function HomePage() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
      {/* Video on the left */}
      <div style={{ flex: 1}}>
        <video src={path} autoPlay loop muted style={{ width: '100%', height: '100%', marginTop: '100px', marginLeft: '100px',
       objectFit: 'cover', // Ensure the video covers the entire container
       boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)', // Add a shadow effect
       borderRadius: '8px', // Apply a border-radius for a rounded look
     }} />
      </div>

      {/* Logo and button on the right */}
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '40px' }}>
        <a href = 'https://assisttu.com/' target="_blank">
        <img src={imagenLogin} alt="Logo" style={{ width: '300px', height: 'auto' }} />
        </a>
        <p  style={{ width: '300px', height: 'auto', textAlign: 'center', marginTop: '-60px' }} >Assisttu offers on-demand access to highly
skilled virtual assistants</p>
        <div className="need-help-container">
          <Link to="/user/Plans">
          <Button buttonClassName="button-need-help" children="View Plans" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export { HomePage };