// Obtiene la fecha inicial para mostrar en el calendario 
//a partir del item de evento recibido
function getStartEvent(item) {
    var dateStart = null;
    if (!item.dateDisplayed) {
        dateStart = new Date(item.created_at);
    } else {

        dateStart = new Date(item.dateDisplayed);
    }
    const dateTZ = changeTimeZone(dateStart);
    dateTZ.setHours(0, 0, 0);
    dateStart = dateTZ;

    return dateStart;
}

// Función para calcular la diferencia en días entre dos fechas
function daysBetweenDates(dateFirst, dateSecond) {
    const dayMilliseconds = 24 * 60 * 60 * 1000; // Número de milisegundos en un día

    // Convertir las fechas a objetos Date
    const date1 = new Date(dateFirst);
    const date2 = new Date(dateSecond);

    // Calcular la diferencia en milisegundos
    const differenceMilliseconds = Math.abs(date1 - date2);

    // Calcular la diferencia en días
    const differenceDays = Math.floor(differenceMilliseconds / dayMilliseconds);

    return differenceDays;
}

// Función para sumar días a una fecha
function sumDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}

// Funcion que retorna si es dia de trabajo
async function isWorkingDay(date) {
    // Verificar si es sábado o domingo (días no laborables)
    if (date.getDay() === 0 || date.getDay() === 6) {
        return false;
    }
    // Verificar si la fecha coincide con alguna festividad
    const fest = await esFestividadEnEEUU(date);

    // Si no es sábado, domingo ni festividad, es un día laboral
    if (fest) {
        return false;
    } else {
        return true;
    }
}



async function esFestividadEnEEUU(fecha) {
    try {
        const response = await fetch('https://date.nager.at/api/v3/nextpublicholidays/US');

        const responseFest = await response.json();
        // Extraer las fechas de festividades de la respuesta
        const festividades = responseFest.map(holiday => holiday.date);

        if (festividades.includes(fecha.toISOString().split('T')[0])) {
            return true; // Es una festividad
        }

        return false; // No es una festividad
    } catch (error) {
        console.error('Error al consumir la API de festividades:', error);
        return true; // En caso de error, retornar true por defecto
    }
}

function changeTimeZone(date, timeZone) {
    if (!timeZone) {
        timeZone = process.env.REACT_APP_TIMEZONE_APP || "America/Bogota";
    }
    if (typeof date === 'string') {
        return new Date(
            new Date(date).toLocaleString('en-US', {
                timeZone,
            }),
        );
    }

    return new Date(
        date.toLocaleString('en-US', {
            timeZone,
        }),
    );
}

async function processHoursRecurrentTasks(dailyLimitHours, dateInitial, dateLimit, recurrence){
    const daysBeetween = daysBetweenDates(dateLimit, dateInitial);

    const nRecurrencias = Math.trunc(Number(daysBeetween) / Number(recurrence)) + 1;

    var countTaskRecurrent = 0;
    for (let index = 0; index < Number(nRecurrencias); index++) {

        var dateToEvaluate = sumDays(dateInitial, Number(index) * Number(recurrence));
        if (await isWorkingDay(dateToEvaluate)) {
            countTaskRecurrent = countTaskRecurrent + 1;
        }

    }

    const hoursFinal = Number(countTaskRecurrent) * Number(dailyLimitHours);

    return {
        hoursFinal,
        countTaskRecurrent
    };
}

export {
    getStartEvent,
    daysBetweenDates,
    sumDays,
    isWorkingDay,
    esFestividadEnEEUU,
    changeTimeZone,
    processHoursRecurrentTasks
};