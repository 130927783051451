import React, { useContext} from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import Cookies from 'js-cookie';
import socket from "../../../utils/socket";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


import logo from '../../../assets/images/logos/logo-assisttu-petroleum-2.png';
import './NavbarLogo.css';

import ApiMiddleware from '../../Shared/ApiMiddleware';
const MySwal = withReactContent(Swal);

function NavbarLogo() {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, setEmailGlobal, setIdUserGlobal, setUserName } = useContext(GlobalContext)
    const history = useHistory();

    
    const consumeFetch = async (url,options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }
        
    }
    
    
    const handleLogoClick = async () => {
        
        const data = await consumeFetch(`${API_BASE_URL}/tasks/unnasignedTasks`,{
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            }
        });
        if (data.ok) {
        } else {
            throw new Error("Failed to fetch unassigned tasks");
        }
      };

    return (
        <div className='navbar-logo-container'>
            <img
                className='NavbarLogo'
                src={logo}
                alt='Assisttu Logo'
                onClick={handleLogoClick} 
            />
        </div>
    );
}

export { NavbarLogo };
