import React, { useState } from "react";
import { Header } from "../../../components/Shared/Header";
import { Button } from "../../../components/Shared/Button";
import { InputOTP } from "./OPTStep/InputOTP";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReplayIcon from '@mui/icons-material/Replay';
import rightArrow from '../../../assets/images/register-right-arrow.png'
import leftArrow from '../../../assets/images/register-left-arrow.png'
import "./OTPStep.css";
const MySwal = withReactContent(Swal);

function OTPStep(props) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { onBack, register, getValues, setValue } = props;
    const [ inactiveButtonFinish, setInactiveButtonFinish ] = useState(true);

    const resendCode = async() => {
        let data = {
            email: getValues("email"),
            verifyEmail: true
        };
        const response = await fetch(`${API_BASE_URL}/users/sendOTPEmail`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        const responseData = await response.json();
        if (!response.ok) {
            throw new Error(responseData.message);
        }
        MySwal.fire({
            icon: 'success',
            title: 'Code Sent!',
            text: 'We have sent you a new code',
            showConfirmButton: false,
            timer: "2000",
            backdrop: true,
            customClass: {
                popup: 'popup-sweet',
                title: 'title-sweet',
                htmlContainer: 'text-sweet',
                confirmButton: 'confirm-button-sweet',
                denyButton: 'deny-button-sweet',
            }
        });
    }

    function sendInactiveBtnFinish(value) {
        setInactiveButtonFinish(value);
    }



    return (
        <React.Fragment>
            <div className="form-frame">
                <div className="frame-signup">
                    <div className="section-header otp">
                        <Header level={2} headerClassName="subtitle" children="Verify your Email to create an account" />
                        <span>We have sent you a verification email with one OTP code, please enter it below</span>
                    </div>
                    <div className="section-wrap otp-wrap">
                        <div className="input-otp">
                            <InputOTP setValue={setValue} setInactive={sendInactiveBtnFinish}/>
                        </div>
                        <section className="resend-code">
                            <span>If you can't see the verification email, please request a new one.</span>
                            <br />
                            <section className="container-resend-code">
                                <Button buttonClassName="button-signup resend-code-button" onClick={resendCode} children="Resend Code" icon={<ReplayIcon/>} rightIcon={true} iconClassName="icon-resend-code-button"/>
                            </section>
                        </section>
                    </div>
                </div>
            </div>
            <div className="buttons-form interests">
                <Button buttonClassName="button-signup previous"
                    onClick={() => { onBack(); }}
                    children="Go back"
                    image={leftArrow}
                    imgClassName="left-arrow-register"
                    imageAlt="Back Arrow"
                    imageSide="left"
                    three={true}
                />
                <Button type="submit"
                    buttonClassName={`button-signup next finish-button ${inactiveButtonFinish && "inactive" }`}
                    children="Finish"
                    image={rightArrow}
                    imgClassName="right-arrow-register"
                    imageAlt="Next Arrow"
                    imageSide="right"
                    three={true}
                    inactive={inactiveButtonFinish}
                />
            </div>
        </React.Fragment>
    );
};

export { OTPStep };
