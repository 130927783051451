import { useState, useEffect } from 'react';
import './App.css';

import { Route, Switch, BrowserRouter } from "react-router-dom";
import { MainPage } from '../components/MainPage';

import { PageProvider } from '../context/GlobalContext';
import { RegisterSteps } from './register/registerSteps';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PrivateRoute } from '../components/Shared/PrivateRoute';
import { Login } from './login';
import { ApproveTask } from './clientContent/approveTask';
import { ForgotPassword } from './register/registerSteps/ForgotPassword';

const stripePromise = loadStripe("pk_test_51MyDthFIhpi8BJ84jYBRridwjCbIdxvezYc3iIlCuPKAXWB22PJi0JUVVgEaU5s8SyMEivbJ3X3d6ivcol1edlFd00WJ84uZCu");


function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
                <PageProvider>
                    <Switch>
                        <Route exact path='/' component={Login} />
                        <Route exact path='/approveTask' component={ApproveTask} />
                        <Route exact path='/register' component={RegisterSteps} />
                        <Route exact path='/forgotPassword' component={ForgotPassword} />
                        <Elements stripe={stripePromise}>
                            <PrivateRoute exact path='/user/:component' component={MainPage} />
                        </Elements>
                    </Switch>
                </PageProvider>
            </BrowserRouter>
        </LocalizationProvider>
    );
}

export default App;
