import React, { useState, useRef, useEffect } from 'react';

function InputOTP(props) {
    const { setValue, setInactive } = props;
    const [otp, setOTP] = useState(['', '', '', '', '', '']);
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];


    useEffect(()=>{
        const otpText = otp.join('');
        setInactive(true);
        if (otpText.length === 6) {
            setInactive(false)
            setValue("otp",otpText);
        }
    },otp)

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (!isNaN(value)) {
          const newOTP = [...otp];
          newOTP[index] = value;
          setOTP(newOTP);
    
          if (index < 5 && value !== '') {
            inputRefs[index + 1].current.focus();
          }
          if (index > 0 && value === '') {
            inputRefs[index - 1].current.focus();
          }
        }
      };
    
      const handleKeyDown = (e, index) => {
        if (e.key === 'ArrowRight' && index < 5) {
          inputRefs[index + 1].current.focus();
        }
        if (e.key === 'ArrowLeft' && index > 0) {
          inputRefs[index - 1].current.focus();
        }
      };


    return <React.Fragment>
        <section className="otp-container">
            <span>OTP Code: </span>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={inputRefs[index]}
                    className="otp-input"
                />
            ))}
        </section>
    </React.Fragment>
}

export { InputOTP };