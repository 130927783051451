import React, { useContext, useState, useEffect, useRef } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { GlobalContext } from '../../../context/GlobalContext'
import ApiMiddleware from "../../../components/Shared/ApiMiddleware";
import { Button } from "../../../components/Shared/Button";
import { CustomizedSnackbar } from '../../../components/Shared/Snackbar';
import { Radio, RadioGroup, Select, MenuItem, InputLabel, FormControl, FormControlLabel, TextField } from "@mui/material";
import { changeTimeZone, processHoursRecurrentTasks } from "../../../utils/utils";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import './PopupRepeatTask.css'

const MySwal = withReactContent(Swal);

function PopupRepeatTask(props) {
    const { dateVencPlan, closePopup, fetchDataTask } = props;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1";
    const { setActivePage, setToken, token, setEmailGlobal, setIdUserGlobal, idUserGlobal, setUserName, setActiveBlur } = useContext(GlobalContext)
    const history = useHistory();
    const [templatesList, setTemplatesList] = useState();
    const [templateSelected, setTemplateSelected] = useState("noData");
    const [templateData, setTemplateData] = useState(null);
    const [isRecurrent, setIsRecurrent] = useState();
    const [typeCreation, setTypeCreation] = useState();
    const [showDateForm, setShowDateForm] = useState(false);
    const [dateInitial, setDateInitial] = useState();
    const [dateLimit, setDateLimit] = useState();
    const [mindate, setMinDate] = useState(null);
    const [minDateInitial, setMinDateInitial] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [maxDateInitial, setMaxDateInitial] = useState(null);
    const [valuesPlans, setValuesPlans] = useState(null);
    const [valueHours, setValueHours] = useState(0);
    const [numberRecurrences, setNumberRecurrences] = useState(0);
    const [isEmptyDateInitial, setIsEmptyDateInitial] = useState(false);
    const [isEmptyDateLimit, setIsEmptyDateLimit] = useState(false);


    const SnackbarRef = useRef();

    const consumeFetch = async (url, options) => {
        try {
            const originalFetch = fetch;
            const fetchWithMiddleware = ApiMiddleware(originalFetch);
            const { response, token } = await fetchWithMiddleware(url, options);
            // Se detecta token nuevo
            if (token) {
                setToken(token)
            }
            return await response;
        } catch (error) {
            if (error.message === "RefreshToken Vencido") {
                console.log(error.message, error)
                setToken("");
                setEmailGlobal("");
                setIdUserGlobal("");
                setUserName("");
                history.push('/');
            }
        }

    }

    const fetchData = async () => {
        consumeFetch(`${API_BASE_URL}/taskTemplate/${idUserGlobal}`, {
            headers: {
                Authorization: `${token}`
            }
        }).then(response => response.json())
            .then(data => {
                const mappedData = data.map((item, index) => {
                    return {
                        template_id: item.id,
                        taskTitle: item.taskTitle,
                        date: formatDate(item.created_at),
                        isRecurrent: item.isRecurrent,
                        recurrenceKey: item.recurrenceKey,
                        dailyLimitHours: item.dailyLimitHours,
                        description: item.description,
                        recurrent_Id: item.recurrent_Id,
                    };
                });
                if (mappedData.length !== 0) {
                    console.log(mappedData);
                    setTemplatesList(mappedData);
                }
            })
            .catch((err) => {
                console.error(err);
            });
        const currentDate = changeTimeZone(new Date());
        const currentDateCero = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
        const currentDateFive = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 17, 0, 0);
        if (currentDate >= currentDateFive) {
            // Si es después de las 5 PM, establece minDateInitial en el día siguiente
            const nextDay = new Date(currentDateCero);
            nextDay.setDate(currentDateCero.getDate() + 1);
            setMinDateInitial(nextDay);
            setMinDate(nextDay);
        } else {
            // Si es antes de las 5 PM, establece minDateInitial en la fecha actual
            setMinDateInitial(currentDateCero);
            setMinDate(currentDateCero);
        }
        //Limitacion de max dates segun la fecha de vencimiento del plan
        // setMaxDate(new Date(dateVencPlan));
        // setMaxDateInitial(new Date(dateVencPlan));
        setMinDate(currentDate);
        consumeFetch(`${API_BASE_URL}/plans/plansByUser/${idUserGlobal}`, {
            headers: {
                Authorization: `${token}`
            }
        }).then(response => response.json())
            .then(data => {
                let valuesPlans = data.map((item, index) => {
                    if (item.plan.planType === "Virtual Assistant") {
                        if (item.plan.planName.indexOf("Task Package") !== -1) {
                            return "per task";
                        } else {
                            return "per hours";
                        }
                    }
                });
                for (var i = valuesPlans.length - 1; i >= 0; i--) {
                    if (valuesPlans.indexOf(valuesPlans[i]) !== i) valuesPlans.splice(i, 1);
                }
                setValuesPlans(valuesPlans);
            })
            .catch((err) => {
                console.error(err);
            });
    }
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // si dateInitial no tiene fecha invalida se le asigna a minDateLimit, mas un dia de inicial
        if (dateInitial && dateInitial.toString() !== 'Invalid Date') {
            var dateInitialAux = new Date(dateInitial);
            dateInitialAux.setDate(dateInitialAux.getDate() + 1);
            setMinDate(dateInitialAux);
        }
        //si  dateLimit no tiene fecha invalid, se le asigna a maxDateInitial, menos un dia de limit
        if (dateLimit && dateLimit.toString() !== 'Invalid Date') {
            var dateLimitAux = new Date(dateLimit);
            dateLimitAux.setDate(dateLimitAux.getDate() - 1);
            setMaxDateInitial(dateLimitAux);

        }

        async function processHoursIsRecurring() {
            try {
                if (templateData.dailyLimitHours !== null && templateData.dailyLimitHours !== undefined
                    && templateData.recurrenceKey !== null && templateData.recurrenceKey !== undefined
                    && dateLimit !== null && dateLimit !== undefined
                    && dateInitial !== null && dateInitial !== undefined) {

                    if (isInvalidDateLimit(dateLimit)) { return; };
                    if (isInvalidDateInitial(dateInitial)) { return; };

                    let data_process = await processHoursRecurrentTasks(templateData.dailyLimitHours, dateInitial, dateLimit, templateData.recurrenceKey);

                    setValueHours(data_process.hoursFinal);
                    setNumberRecurrences(data_process.countTaskRecurrent);
                }
            } catch (error) {
                console.log("Error = " + error);
                setValueHours(0);
                setNumberRecurrences(0);
            }
        }
        if (isRecurrent) {
            processHoursIsRecurring();
        }
    }, [dateInitial, dateLimit])



    const formatDate = (date) => {
        var dateLimit = new Date(date);
        var userTimezoneOffset = dateLimit.getTimezoneOffset() * 60000;
        dateLimit = new Date(dateLimit.getTime() + userTimezoneOffset);
        const year = dateLimit.getFullYear();
        const month = dateLimit.getMonth() + 1;
        const day = dateLimit.getDate();

        return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
    }

    const handleChange = (event) => {
        setTemplateSelected(event.target.value);
    };

    const handleSelectTemplate = async () => {
        if (templateSelected !== "noData") {
            setIsRecurrent(templateSelected.split(",")[1]);
            let template_data = templatesList.find(({ template_id }) => template_id === templateSelected.split(",")[0]);
            setTemplateData(template_data);
            setDateInitial(null);
            setDateLimit(null);
            setNumberRecurrences(0);
            setValueHours(0);
            setShowDateForm(true);
        } else {
            MySwal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: 'You have to select a template to create a repeated task.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            });
        }
    }

    const handleReturnClick = (event) => {
        setShowDateForm(false);
    };

    const handleCreateRepeatedTask = () => {

        // Validate fields
        if ((isRecurrent === "true" && dateInitial && dateInitial.toString() === 'Invalid Date') || (dateLimit && dateLimit.toString() === 'Invalid Date')) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid Date!`, 'error');
            return;
        }

        if (isRecurrent === "true" && isEmpty(dateInitial)) { setIsEmptyDateInitial(true); return; } else { setIsEmptyDateInitial(false); };
        if (isEmpty(dateLimit)) { setIsEmptyDateLimit(true); return; } else { setIsEmptyDateLimit(false); };

        if (isRecurrent === "true" && isInvalidDateInitial(dateInitial)) { return; };
        if (isInvalidDateLimit(dateLimit)) { return; };

        consumeFetch(`${API_BASE_URL}/taskTemplate/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`
            },
            body: JSON.stringify({
                template_id: templateSelected.split(",")[0],
                type_creation: valuesPlans.length > 1 ? typeCreation : valuesPlans[0],
                dateLimit: dateLimit,
                isRecurrent: (isRecurrent === "true"),
                dateInitial: isRecurrent === "true" ? dateInitial : undefined,
                hours: isRecurrent === "true" ? `${valueHours}:00` : undefined
            })
        }).then(response => response.json()).then(data => {
            setActiveBlur(true);
            MySwal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Task saved.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).then(() => {
                closePopup();
                fetchDataTask();
            }).finally(() => { setActiveBlur(false) });
        }).catch((err) => {
            console.error(err);
            setActiveBlur(true);
            MySwal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: 'There was a unexpected error, please contact support.',
                showConfirmButton: false,
                timer: 1700,
                backdrop: true,
                customClass: {
                    popup: 'popup-sweet',
                    title: 'title-sweet',
                    htmlContainer: 'text-sweet',
                    confirmButton: 'confirm-button-sweet',
                    denyButton: 'deny-button-sweet',
                }
            }).finally(() => { setActiveBlur(false) });
        });
    }

    const handleChangeDateInitial = (e) => {
        const value = e;
        setDateInitial(value);
        (isEmpty(value)) ? setIsEmptyDateInitial(true) : setIsEmptyDateInitial(false);
    }

    const handleChangeDateLimit = (e) => {
        const value = e;
        setDateLimit(value);
        (isEmpty(value)) ? setIsEmptyDateLimit(true) : setIsEmptyDateLimit(false);
    }

    const isEmpty = (field) => {
        if (!field || field === '') {
            return true;
        }
        return false;
    }

    const isInvalidDateInitial = (date) => {
        if (date && minDateInitial && date < minDateInitial) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid initial date!`, 'error');
            return true;
        }
        if (date && maxDateInitial && date > maxDateInitial) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid initial date!`, 'error');
            return true;
        }
        return false;
    }

    const isInvalidDateLimit = (date) => {
        if (date && mindate && date < mindate) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid date limit!`, 'error');
            return true;
        }
        if (date && maxDate && date > maxDate) {
            SnackbarRef.current.handleSnackbarOpen(`Invalid date limit!`, 'error');
            return true;
        }
        return false;
    }

    return (
        <React.Fragment>
            <CustomizedSnackbar
                open={SnackbarRef.open}
                severity={SnackbarRef.snackbarType}
                message={SnackbarRef.snackbarMessage}
                handleClose={SnackbarRef.handleClose}
                ref={SnackbarRef}
            />
            <div className="full-task-modal">
                {(!showDateForm) ?
                    <>
                        <div className="modal-repeat-title">
                            <h2 className="modal-repeat-title-text">Task Templates</h2>
                        </div>
                        {templatesList &&
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={templateSelected}
                                    onChange={handleChange}
                                >
                                    {templatesList.map((item, index) => {
                                        return <FormControlLabel key={index} value={`${item.template_id},${item.isRecurrent}`} control={<Radio />} label={`${item.taskTitle} - ${item.date}`} />
                                    })}
                                </RadioGroup>
                            </FormControl>
                        }
                        <div className="modal-repeat-button-container">
                            <Button
                                buttonClassName="modal-repeat-button"
                                onClick={() => handleSelectTemplate()}
                                children="Select Template"
                            />
                        </div>
                    </>
                    :
                    <>
                        <div className="buttonReturn">
                            <Button buttonClassName="Return_Button-repeat" onClick={handleReturnClick} icon={<AiOutlineArrowLeft />} />
                        </div>
                        <div className='cuadroInfoTask sombra2' style={{ background: `rgba(70, 139, 255, 0.2)`, borderColor: `rgb(70, 139, 255)`, borderWidth: '3px', borderStyle: 'solid' }}>
                            <div className='name-container'>
                                <div className="task-box-repeat">
                                    <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Task:</b> {templateData.taskTitle}</span><br />
                                    <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Description:</b> {templateData.description}</span><br />
                                    <span style={{ fontSize: '15px', textAlign: 'left' }}><b>{templateData.recurrent_Id != null && `Recurrence:`}</b> {templateData.recurrent_Id != null && templateData.recurrent_Id.description}</span>{templateData.recurrent_Id != null && <br />}
                                    <span style={{ fontSize: '15px', textAlign: 'left' }}><b>Daily Limit Hour:</b> {templateData.dailyLimitHours && (templateData.isRecurrent === false ? `This task will be limited to ${templateData.dailyLimitHours} hours per day to be executed by the assistant.`:`This task will be recurrent and each recurrence with ${templateData.dailyLimitHours} hours to be executed by the assistant.`)}</span><br />
                                </div>
                            </div>
                        </div>
                        <div className="modal-repeat-title">
                            <h4 className="modal-repeat-title-text">{isRecurrent === "true" ? "Choose Date Interval" : "Choose Date Limit"}</h4>
                        </div>
                        <div className={`type-field-task-repeat ${!(isRecurrent === "true") && 'one-field'}`}>
                            {isRecurrent === "true" &&
                                <div className="date-initial-form">
                                    <FormControl fullWidth >
                                        <DatePicker
                                            label="Initial Date"
                                            value={dateInitial}
                                            onChange={handleChangeDateInitial}
                                            minDate={dayjs(minDateInitial)}
                                            maxDate={dayjs(maxDateInitial)}
                                        />
                                    </FormControl>
                                    {isEmptyDateInitial && (
                                        <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                    )}
                                </div>
                            }
                            <div className="date-form">
                                <FormControl fullWidth >
                                    <DatePicker
                                        label="Date Limit"
                                        value={dateLimit}
                                        onChange={handleChangeDateLimit}
                                        minDate={dayjs(mindate)}
                                        maxDate={dayjs(maxDate)}
                                    />
                                </FormControl>
                                {isEmptyDateLimit && (
                                    <span style={{ color: "#FF5C34", fontSize: "12px" }}>Value is required!</span>
                                )}
                            </div>
                            {(valuesPlans !== null && valuesPlans.length > 1) &&
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label label-taskType">Type Creation</InputLabel>
                                    <Select
                                        value={typeCreation}
                                        onChange={(newValue) => setTypeCreation(newValue)}
                                        label="Type Creation"
                                    >
                                        {valuesPlans.map((option, k) => (
                                            <MenuItem key={k} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            {isRecurrent === "true" &&
                                <>
                                    <FormControl fullWidth >
                                        <TextField
                                            label="Hour Limit"
                                            type="number"
                                            value={valueHours}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth >
                                        <TextField
                                            label="Number of Recurrences"
                                            type="number"
                                            value={numberRecurrences}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </FormControl>
                                </>
                            }
                        </div>
                        <div className="modal-rate-button-container">
                            <Button
                                buttonClassName="modal-rate-button"
                                onClick={() => handleCreateRepeatedTask()}
                                children="Create Task"
                            />
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    )
}

export { PopupRepeatTask };